import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as Background} from 'assets/images/backgrounds/dc.svg';
import {ReactComponent as Sunrise1} from 'assets/images/backgrounds/dc-sunrise-1.svg';
import {ReactComponent as Sunrise2} from 'assets/images/backgrounds/dc-sunrise-2.svg';
import {ReactComponent as Sunrise3} from 'assets/images/backgrounds/dc-sunrise-3.svg';
import {ReactComponent as Sunrise4} from 'assets/images/backgrounds/dc-sunrise-4.svg';
import {ReactComponent as Sunrise5} from 'assets/images/backgrounds/dc-sunrise-5.svg';
import {ReactComponent as ProcessedBackground} from 'assets/images/backgrounds/dc2.svg';
import {ReactComponent as ProcessedSunrise1} from 'assets/images/backgrounds/dc2-sunrise-1.svg';
import {ReactComponent as ProcessedSunrise2} from 'assets/images/backgrounds/dc2-sunrise-2.svg';
import {ReactComponent as ProcessedSunrise3} from 'assets/images/backgrounds/dc2-sunrise-3.svg';
import {ReactComponent as ProcessedSunrise4} from 'assets/images/backgrounds/dc2-sunrise-4.svg';
import {ReactComponent as ProcessedSunrise5} from 'assets/images/backgrounds/dc2-sunrise-5.svg';
import './dc-background.scss';

const DcBackground = ({background = null, scenarioID = 'scenario1'}) => {
	let BackgroundComponent;

	switch (background) {
	case 'sunrise-1': 
		if (scenarioID === 'scenario2') {
			BackgroundComponent = ProcessedSunrise1;
		} else {
			BackgroundComponent = Sunrise1;
		}
		break;

	case  'sunrise-2':
		if (scenarioID === 'scenario2') {
			BackgroundComponent = ProcessedSunrise2;
		} else { 
			BackgroundComponent = Sunrise2;
		}
		break;

	case  'sunrise-3':
		if (scenarioID === 'scenario2') {
			BackgroundComponent = ProcessedSunrise3;
		} else { 
			BackgroundComponent = Sunrise3;
		}
		break;

	case  'sunrise-4':
		if (scenarioID === 'scenario2') {
			BackgroundComponent = ProcessedSunrise4;
		} else { 
			BackgroundComponent = Sunrise4;
		}
		break;

	case  'sunrise-5':
		if (scenarioID === 'scenario2') {
			BackgroundComponent = ProcessedSunrise5;
		} else { 
			BackgroundComponent = Sunrise5;
		}
		break;

	default: 
		if (scenarioID === 'scenario2') {
			BackgroundComponent = ProcessedBackground;
		} else {
			BackgroundComponent = Background;
		}
	}

	return (
		<div className='DcBackground'>
			<BackgroundComponent className={'DcBackground-background ' + scenarioID}/>
		</div>
	);
};

DcBackground.propTypes = {
	background: PropTypes.string,
	scenarioID: PropTypes.string,
};

export default DcBackground;