let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gameUrl = 'http://localhost:3000';
let apiURL = 'http://localhost:8080/api/';
let usersDbName = 'users';
let gamesDbName = 'games';
let playersDbName = 'players';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-hygiejne-test.web.app';
	apiURL = 'https://cgl-hygiejne-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-hygiejne-demo.web.app';
		apiURL = 'https://cgl-hygiejne-demo.web.app/api/';
		gamesDbName = 'games-demo';
		playersDbName = 'players-demo';
	}
}
if (env === 'production') {
	gameUrl = 'dchygienetraining.dk';
	apiURL = 'https://dchygienetraining.dk/api/';
}



const appConfig = {
	env,
	usersDbName,
	gamesDbName,
	playersDbName,
	gameUrl,
	apiURL,	
	name: 'DC Hygiene training',
	faclitatorSectionUri: 'facilitator',
	cookiesAcceptedCookieName: 'hygiejne_cookies_accepted',
	defaultLanguage: 'da',
	maxStarsPerModule: 3,
	inactivityLimitSeconds: 30,
	moduleDisabledDays: 7
};

export default appConfig;