import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getText} from 'helpers/language-helper';
import {renderMarkdown} from 'helpers/text-helper';
import {getCookie, setCookie, deleteCookie} from 'helpers/cookie-helper';
import {cookiesUiTexts} from 'data/ui-texts/cookies-ui-texts';
import Button from 'components/ui/button/button';
import './cookie-consent.scss';

const CookieConsent = ({languageId, page, handleLogout}) => {
	/* Show popup */
	const [showPopup, setShowPopup] = useState(false);

	/* Component did mount */
	useEffect(() => {
		/* Check if cookies are accepted */
		const cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
		if (!cookieConsent || cookieConsent.length === 0) {
			setShowPopup(true);
		}	
	}, []);

	/* Accept cookies */
	const handleAcceptCookies = () => {
		setCookie(appConfig.cookiesAcceptedCookieName, 'ok');
		setShowPopup(false);
	};

	/* Reject cookies */
	const handleRejectCookies = () => {
		deleteCookie(appConfig.cookiesAcceptedCookieName);
		setShowPopup(false);
		if (page !== 'login') {
			handleLogout();
		}
	};

	return (
		<div className="CookieConsent">
			<div className="CookieConsent-toggleBtn" onClick={() => {setShowPopup(!showPopup);}} />

			{showPopup && <div className="CookieConsent-popup">
				<div 
					className="CookieConsent-content" 
					onClick={(e) => {e.stopPropagation();}}
				>
					<div className="CookieConsent-contentWrap">
						<div className="CookieConsent-title">
							<span>{getText(cookiesUiTexts.title, languageId)}</span>
						</div>
						{<div className="CookieConsent-text">
							{renderMarkdown(getText(cookiesUiTexts.text, languageId))}
						</div>}
						<div className="CookieConsent-buttons">
							<Button 
								classes={['cookies-popup', 'reject']}
								text={getText(cookiesUiTexts.rejectBtn, languageId)}
								onClick={() => {handleRejectCookies();}}
							/>
							<Button 
								classes={['cookies-popup', 'accept']}
								text={getText(cookiesUiTexts.okBtn, languageId)}
								onClick={() => {handleAcceptCookies();}}
							/>
						</div>
					</div>
				</div>
			</div>}
		</div>
	);
};

CookieConsent.propTypes = {
	languageId: PropTypes.string.isRequired,
	page: PropTypes.string.isRequired,
	handleLogout: PropTypes.func
};

export default CookieConsent;
