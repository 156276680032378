import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import apiHelper from 'helpers/api-helper';
import {facilitatorUiTexts} from 'data/ui-texts/facilitator-ui-texts';
import {errorUiTexts} from 'data/ui-texts/error-ui-texts';
import Select from 'components/ui/select/select';
import Button from 'components/ui/button/button';
import './admin-move-players-popup.scss';

const AdminMovePlayersPopup = ({languageId, gamesData, playersData, setShowMovePlayersPopup}) => {
	/* Loading, feedback */
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [successMsg, setSuccessMsg] = useState(null);

	/* Selectable games */
	const selectableGames = gamesData.map((game) => {
		return {
			id: game.id, 
			title: {da: game.name, en: game.name}, 
			scenarioId: (game.scenarioId ? game.scenarioId : 'scenario1')
		};
	});

	/* Move players from game id */
	const [fromGameId, setFromGameId] = useState(selectableGames.length > 0 ? selectableGames[0].id : null);

	/**
	 * Select which game to move players from
	 * @param {string} newFromGameId 
	 * @returns 
	 */
	const selectFromGameId = (newFromGameId) => {
		if (newFromGameId === fromGameId) return;

		const newPlayersInGame = getPlayersInSelectedGame(newFromGameId);
		setErrorMsg(null);
		setFromGameId(newFromGameId);
		setPlayersInGame(newPlayersInGame);
	};

	/**
	 * Get players in selected game
	 * @param {string} gameId 
	 */
	const getPlayersInSelectedGame = (gameId) => {
		const newPlayersInGame = playersData.filter((player) => {
			return player.gameId === gameId;
		}).map((player) => {return {id: player.id, toGameId: null};});
		return newPlayersInGame;
	};
	
	/* Players in selected game */
	const [playersInGame, setPlayersInGame] = useState(getPlayersInSelectedGame(fromGameId));

	/**
	 * Select which game to move a player to
	 * @param {string} playerId 
	 * @param {string} toGameId 
	 */
	const selectToGameId = (playerId, toGameId) => {
		const newPlayersInGame = JSON.parse(JSON.stringify(playersInGame));
		const playerIndex = newPlayersInGame.findIndex((player) => {return player.id === playerId;});
		setErrorMsg(null);
		if (playerIndex >= 0) {
			newPlayersInGame[playerIndex].toGameId = toGameId;
			setPlayersInGame(newPlayersInGame);
		}
	};

	/* Get available move to games (only the ones with the same scenario as the selected game to move from) */
	const fromGameData = selectableGames.find((game) => {return game.id === fromGameId;});
	const availableToGames = selectableGames.filter((game) => {
		const scenarioId = (game.scenarioId ? game.scenarioId : 'scenario1');
		return (
			game.id !== fromGameId && 
			scenarioId &&
			fromGameData.scenarioId && 
			scenarioId === fromGameData.scenarioId
		);
	});
	availableToGames.unshift({id: 'none', title: {da: '-', en: '-'}});
	
	/**
	 * Move players
	 */
	const handleMovePlayers = () => {
		if (isLoading) return;

		setIsLoading(true);
		setErrorMsg(null);
		setSuccessMsg(null);

		/* No players */
		if (!playersInGame && playersInGame.length === 0) {
			setIsLoading(false);
			return;
		}

		const playersToMove = playersInGame.filter((player) => {
			return player.toGameId !== null && player.toGameId !== 'none';
		});
		if (playersToMove.length === 0) {
			/* No players will be moved */
			setErrorMsg(getText(facilitatorUiTexts.movePlayersPopup.noPlayersSelected, languageId));
			setIsLoading(false);
			return;
		}

		/* All okay, move players */
		apiHelper('admin/move-players', {
			fromGameId,
			playersToMove
		}).then(
			(response) => {
				// Success
				if (response.status === 'success') {
					setSuccessMsg(getText(facilitatorUiTexts.movePlayersPopup.successMsg, languageId));
					if (response.notMovedPlayers && response.notMovedPlayers.length > 0) {
						/* Some players could not be moved */
						setErrorMsg(
							getText(facilitatorUiTexts.movePlayersPopup.playersNotMoved) 
							+ ' ' + response.notMovedPlayers.join(', ')
						);
					}
				} else {
					console.error(response);
					setErrorMsg(getText(errorUiTexts.unknownError, languageId));
				}
				setIsLoading(false);
			},
			(rejection) => {
				// Error
				console.error(rejection);
				setErrorMsg(getText(errorUiTexts.unknownError, languageId));
				setIsLoading(false);
			}
		);
	};

	return (
		<div className="AdminMovePlayersPopup" onClick={() => {setShowMovePlayersPopup(false);}}>
			<div 
				className="AdminMovePlayersPopup-content" 
				onClick={(e) => {e.stopPropagation();}}
			>
				<div className="AdminMovePlayersPopup-contentWrap">
					{/* Title */}
					<div className="AdminMovePlayersPopup-title">
						<span>{getText(facilitatorUiTexts.movePlayersPopup.title, languageId)}</span>
					</div>

					{/* Select game to move from */}
					{!successMsg && <div className='AdminMovePlayersPopup-selectWrapper'>
						<div className='AdminMovePlayersPopup-selectLabel'>
							{getText(facilitatorUiTexts.movePlayersPopup.chooseGame, languageId)}:
						</div>
						{selectableGames.length > 0 && <div className='AdminMovePlayersPopup-select'>
							<Select 
								type="facilitator-player-popup"
								isDisabled={false}
								languageId={languageId}
								defaultId={''}
								selectedId={fromGameId}
								options={selectableGames}
								onSelect={selectFromGameId}
							/>
						</div>}
					</div>}

					{/* Players in selected game */}
					{!successMsg && <div className="AdminMovePlayersPopup-players">
						<div className="AdminMovePlayersPopup-player header">
							<div className="AdminMovePlayersPopup-playerId">
								<span>{getText(facilitatorUiTexts.movePlayersPopup.employeeId, languageId)}</span>
							</div>
							<div className="AdminMovePlayersPopup-toGameSelect">
								<span>{getText(facilitatorUiTexts.movePlayersPopup.moveTo, languageId)}</span>
							</div>
						</div>
						{playersInGame.map((player) => {
							return (
								<div key={player.id} className="AdminMovePlayersPopup-player">
									<div className="AdminMovePlayersPopup-playerId"><span>{player.id}</span></div>
									<div className="AdminMovePlayersPopup-toGameSelect">
										{availableToGames.length > 1 && 
											<Select 
												type="facilitator-player-popup"
												isDisabled={false}
												languageId={languageId}
												defaultId={'none'}
												selectedId={player.toGameId}
												options={availableToGames}
												onSelect={(toGameId) => {selectToGameId(player.id, toGameId);}}
											/>}
									</div>
								</div>
							);
						})}
					</div>}

					{/* Feedback */}
					<div className={'AdminMovePlayersPopup-feedback' 
						+ (errorMsg ? ' error' : '') + (successMsg ? ' success' : '')}
					>
						{errorMsg && <span>{errorMsg}</span>}
						{successMsg && <span>{successMsg}</span>}
					</div>
						
					{/* Move button */}
					{(!successMsg && playersInGame && playersInGame.length > 0) && 
						<div className="AdminMovePlayersPopup-moveBtn">
							<Button 
								isLoading={isLoading}
								classes={['facilitator']}
								text={getText(facilitatorUiTexts.movePlayersPopup.moveBtn, languageId)}
								onClick={() => {handleMovePlayers();}}
							/>
						</div>
					}
				</div>
			</div>
		</div>
	);
};

AdminMovePlayersPopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	gamesData: PropTypes.array.isRequired,
	playersData: PropTypes.array.isRequired,
	setShowMovePlayersPopup: PropTypes.func.isRequired,
};

export default AdminMovePlayersPopup;
