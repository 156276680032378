// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const facilitatorUiTexts = {
	admin: {
		da: 'Admin',
		en: 'Admin',
		de: 'Administrator'
	},
	loggedInAs: {
		da: 'Logget ind som',
		en: 'Logged in as',
		de: 'Angemeldet als'
	},
	noGames: {
		da: 'Du er ikke registreret som facilitator af nogen spil.',
		en: 'You are not registered as a facilitator of any games.',
		de: 'Sie sind nicht als Moderator eines Spiels registriert.'
	},

	chooseGame: {
		da: 'Vælg spil',
		en: 'Choose game',
		de: 'Spiel auswählen'
	},
	gameName: {
		da: 'Spilnavn',
		en: 'Game name',
		de: 'Spielname'
	},
	gameUrl: {
		da: 'Spil URL',
		en: 'Game URL',
		de: 'Spiel-URL'
	},
	url: {
		da: 'URL',
		en: 'URL',
		de: 'URL'
	},
	created: {
		da: 'Oprettet',
		en: 'Created',
		de: 'Erstellt'
	},
	scenario: {
		da: 'Scenarie',
		en: 'Scenario',
		de: 'Szenario'
	},
	choose: {
		da: 'Vælg',
		en: 'Choose',
		de: 'Wählen'
	},
	backToChooseGames: {
		da: 'Vælg andet spil',
		en: 'Choose another game',
		de: 'Wählen Sie ein anderes Spiel'
	},

	showInactivePlayers: {
		da: 'Vis inaktive spillere',
		en: 'Show inactive players',
		de: 'Inaktive Spieler anzeigen'
	},
	employeeNumber: {
		da: 'Medarbejder-nr.',
		en: 'Employee no.',
		de: 'Mitarbeiter-Nr.'
	},
	lastLogin: {
		da: 'Sidste login',
		en: 'Last login',
		de: 'Letzte Anmeldung'
	},
	status: {
		da: 'Status',
		en: 'Status',
		de: 'Status'
	},
	active: {
		da: 'Aktiv',
		en: 'Active',
		de: 'Aktiv'
	},
	inactive: {
		da: 'Inaktiv',
		en: 'Inactive',
		de: 'Inaktiv'
	},
	deactivateBtn: {
		da: 'Deaktiver',
		en: 'Deactivate',
		de: 'Deaktivieren'
	},
	reactivateBtn: {
		da: 'Reaktiver',
		en: 'Reactivate',
		de: 'Reaktivieren'
	},
	lastCompletedModule: {
		da: 'Senest afsluttede modul',
		en: 'Last completed module',
		de: 'Letztes abgeschlossenes Modul'
	},
	moduleGroup: {
		da: 'Modulgruppe',
		en: 'Module group',
		de: 'Modulgruppe'
	},
	module: {
		da: 'Modul',
		en: 'Module',
		de: 'Modul'
	},
	completion: {
		da: 'Progress',
		en: 'Progress',
		de: 'Fortschritt'
	},
	timeSpent: {
		da: 'Tid brugt',
		en: 'Time spent',
		de: 'Zeitaufwand'
	},
	minutes: {
		da: 'minutter',
		en: 'minutes',
		de: 'Minuten'
	},
	avgMistakes: {
		da: 'Antal fejl (gns)',
		en: 'Number of errors (avg)',
		de: 'Anzahl der Fehler (Durchschnitt)'
	},
	task: {
		da: 'Opgave',
		en: 'Task',
		de: 'Aufgabe'
	},
	type: {
		da: 'Type',
		en: 'Type',
		de: 'Typ'
	},
	mistakes: {
		da: 'Fejl (gns)',
		en: 'Mistakes (avg)',
		de: 'Fehler (Durchschnitt)'
	},
	attempts: {
		da: 'Forsøg',
		en: 'Attempts',
		de: 'Versuche'
	},
	players: {
		da: 'Spillere',
		en: 'Players',
		de: 'Spieler'
	},
	downloadData: {
		da: 'Download data',
		en: 'Download data',
		de: 'Daten herunterladen'
	},
	renamePlayer: {
		da: 'Omdøb',
		en: 'Rename'
	},

	movePlayersPopup: {
		title: {
			da: 'Flyt medarbejdere',
			en: 'Move employees'
		},
		chooseGame: {
			da: 'Vælg hvilket spil du vil flytte medarbejdere fra',
			en: 'Choose which game you want to move employees from'
		},
		employeeId: {
			da: 'Medarbejder-id',
			en: 'Employee id'
		},
		moveTo: {
			da: 'Flyt til',
			en: 'Move to'
		},
		moveBtn: {
			da: 'Flyt valgte',
			en: 'Move selected'
		},
		noPlayersSelected: {
			da: 'Ingen medarbejdere markeret til flytning.',
			en: 'No employees marked for moving.'
		},
		successMsg: {
			da: 'De valgte medarbejdere blev flyttet.',
			en: 'The selected employees were moved.'
		},
		playersNotMoved: {
			da: 'Følgende medarbejdere kunne ikke flyttes:',
			en: 'The following employees could not be moved:'
		}
	},

	renamePlayerPopup: {
		title: {
			da: 'Omdøb medarbejder-id',
			en: 'Rename employee id'
		},
		currentEmployeeId: {
			da: 'Nuværende medarbejder-id',
			en: 'Current employee id'
		},
		newEmployeeId: {
			da: 'Nyt medarbejder-id',
			en: 'New employee id'
		},
		okBtn: {
			da: 'Omdøb',
			en: 'Rename'
		},
		emptyUserId: {
			da: 'Husk at udfylde et nyt medarbejder-id',
			en: 'Please fill out a new employee id'
		},
		invalidUserId: {
			da: 'Det indtastede nummer er forkert. Husk det skal være 8 cifre.',
			en: 'Invalid number. Remember it must be 8 digits.',	
		},
		userDoesNotExist: {
			da: 'Der eksisterer ikke nogen spiller med medarbejder-id ',
			en: 'THere exist no player with the id ',
		},
		idAlreadyInUse: {
			da: 'Der eksisterer allerede en spiller med det nye medarbejder-id',
			en: 'A player with the new employee id already exists.'
		},
		success: {
			da: 'Medarbejder-id %oldId% blev ændret til %newId%',
			en: 'Employee id %oldId% was changed to %newId%'
		}

	},

	deletePlayer: {
		title: {
			da: `Slet spiller`,
			en: 'Delete player',
			de: 'Spieler löschen'
		},
		text: {
			da: `Du er igang med at slette %playerId%. 
				<br /><br />Dette kan ikke gøres om. Vil du fortsætte?`,
			en: `You are about to delete %playerId%.
				<br /><br/>This cannot be undone. Do you want to continue?`,
			de: `Sie sind dabei, %playerId% zu löschen.
				<br /><br />Das kann nicht rückgängig gemacht werden. Möchtest du fortfahren?`
		},
		confirmBtn: {
			da: 'Fortsæt',
			en: 'Continue',
			de: 'Bestätigen'
		},
		cancelBtn: {
			da: 'Annuller',
			en: 'Cancel',
			de: 'Stornieren'
		}
	},

	resetPlayerModule: {
		title: {
			da: `Nulstil modulgruppe`,
			en: 'Reset module group',
			de: 'Modulgruppe zurücksetzen'
		},
		text: {
			da: `Vil du nulstille brugerens data for %moduleGroup%? 
				<br /><br />Dette kan ikke gøres om.`,
			en: `Do you want to reset the user's data for %moduleGroup%?
				<br /><br/>This cannot be reversed.`,
			de: `Möchten Sie die Benutzerdaten für %moduleGroup% zurücksetzen?
				<br /><br />Dies kann nicht rückgängig gemacht werden.`
		},
		confirmBtn: {
			da: 'Fortsæt',
			en: 'Continue',
			de: 'Bestätigen'
		},
		cancelBtn: {
			da: 'Annuller',
			en: 'Cancel',
			de: 'Stornieren'
		}
	},

	resetPlayerPopup: {
		text: {
			da: `Vil du nulstille brugeren?
				<br /><br />Det vil sige at alle moduler skal tages forfra.`,
			en: `Do you want to reset this user's progress?
				<br /><br />This will reguire for them to replay all modules.`,
			de: `Möchten Sie den Fortschritt dieses Benutzers zurücksetzen?
				<br /><br />Dies erfordert, dass sie alle Module erneut abspielen.`
		},
		noBtn: {
			da: 'Nej, aktiver',
			en: 'No, keep active',
			de: 'Nein, bleiben Sie aktiv'
		},
		yesBtn: {
			da: 'Ja, nulstil og aktiver',
			en: 'Yes, reset and activate',
			de: 'Ja, zurücksetzen und aktivieren'
		},
		cancelBtn: {
			da: 'Annuller',
			en: 'Cancel',
			de: 'Stornieren'
		}
	},
};


module.exports = {
	facilitatorUiTexts
};