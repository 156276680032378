import {useState, useEffect} from 'react';
import firebaseInit from 'firebase-init';
import {getBrowserLanguage} from 'helpers/language-helper';
import LoginController from 'components/login/login-controller';
import ImageLoader from './ui/image-loader/image-loader';
import './app.scss';

function App() {
	/* Language id */
	const browserLanguageId = getBrowserLanguage();

	/*  Font size */
	const [fontSize, setFontSize] = useState(null);
	
	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */

		/* Initialize firebase */
		firebaseInit();

		/* Check fromat */
		checkFormat();

		window.addEventListener('resize', checkFormat);
		if (window.screen && window.screen.orientation) {
			window.screen.orientation.addEventListener('change', checkFormat);
		}
	});


	const scrollToTop = () => {
		const AppDiv = document.getElementById('App');
		if (AppDiv) AppDiv.scrollTop = 0;
	};

	const checkFormat = () => {
		const AppDiv = document.getElementById('App');

		if (AppDiv) {
			const width = AppDiv.offsetWidth;
			const height = AppDiv.offsetHeight;
			const ratio = width / height;
			if (ratio > (4. / 3.)) {
				setFontSize(18 * (height / 768.));
			} else {
				setFontSize(0);
			}
		}	
	};


	const style = (fontSize ? {fontSize : fontSize} : null);
	

	return (
		<div id="App" className="App" style={style}>
			<LoginController languageId={browserLanguageId} scrollToTop={scrollToTop} />
			<ImageLoader type="basic" />
		</div>
	);
}

export default App;
