import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import {checkIfValidEmail, checkIfValidUrl, checkIfValidName} from 'helpers/admin-helper';
import Select from 'components/ui/select/select';
import Button from 'components/ui/button/button';
import './admin-add-game-popup.scss';

const AdminAddGamePopup = ({languageId, gamesData, createGame, togglePopup}) => {
	/* Loading, feedback */
	const [isLoading, setIsLoading] = useState(false);
	const [gameNameInputWarning, seteGameNameInputWarning] = useState(null);
	const [emailInputWarning, setEmailInputWarning] = useState(null);
	const [urlWarning, setUrlWarning] = useState(null);
	const [errorMsg, setErrorMsg] = useState(null);

	/* Selected scenario */
	const [scenarioId, setScenarioId] = useState('scenario1');
	/* Input game name, gameUrl and facilitator e-mail */
	const [gameName, setGameName] = useState('');
	const [gameUrl, setUrl] = useState('');
	const [facilitatorEmail, setFacilitatorEmail] = useState('');

	/**
	 * Update input
	 * @param {object} e 
	 */
	const updateInput = (e) => {
		seteGameNameInputWarning(null);
		setEmailInputWarning(null);
		setErrorMsg(null);
		
		/* Update game name (and game url) */
		if (e.target.name === 'gameName') {
			setGameName(e.target.value);
			let gameUrl = '';
			if (e.target.value.length > 0) {
				/* Convert to lowercase, replace space with '-', remove all invalid characters */
				gameUrl = e.target.value.toLowerCase().replace(/[ ]+/g, '-').replace(/[^a-z0-9-]+/g, '');
				setUrl(gameUrl);
			}
		}
		/* Update gameUrl */
		if (e.target.name === 'gameUrl') setUrl(e.target.value.toLowerCase());

		/* Update email */
		if (e.target.name === 'email') setFacilitatorEmail(e.target.value);		
	};

	/* Check gameUrl */
	useEffect(() => {
		setUrlWarning(null);
		if (gameUrl.length > 0) {
			const {isValidUrl, urlFeedback} = checkIfValidUrl(gameUrl, gamesData);
			if (!isValidUrl && urlFeedback && adminUiTexts.addGamePopup.hasOwnProperty(urlFeedback)) {
				setUrlWarning( getText(adminUiTexts.addGamePopup[urlFeedback], languageId));
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gameUrl]);

	/**
	 * Handle add game
	 * @returns 
	 */
	const handleAddGame = () => {
		if (isLoading) return;
		setIsLoading(true);
		setErrorMsg(null);

		/* Check input */
		const {isValidName, nameFeedback, invalidChars} = checkIfValidName(gameName);
		const {isValidUrl, urlFeedback} = checkIfValidUrl(gameUrl, gamesData);
		const {isValidEmail, emailFeedback} = checkIfValidEmail(facilitatorEmail);


		if (!isValidName || !isValidUrl || (!isValidEmail && emailFeedback !== 'emptyField')) {
			/* One or more input fields are not valid */
			if (!isValidName && adminUiTexts.addGamePopup.hasOwnProperty(nameFeedback)) {
				seteGameNameInputWarning(
					getText(adminUiTexts.addGamePopup[nameFeedback], languageId) + ' ' + invalidChars
				);
			}
			if (!isValidUrl && adminUiTexts.addGamePopup.hasOwnProperty(urlFeedback)) {
				setUrlWarning(getText(adminUiTexts.addGamePopup[urlFeedback], languageId));
			}
			if (!isValidEmail && adminUiTexts.addGamePopup.hasOwnProperty(emailFeedback)) {
				setEmailInputWarning(getText(adminUiTexts.addGamePopup[emailFeedback], languageId));
			}
			setIsLoading(false);
		} else {
			/* All input fields ok, create game */
			createGame(scenarioId, gameName, gameUrl, facilitatorEmail).then((response) => {
				if (response.status === 'error') {
					if (response.status.error === 'couldNotInviteFacilitator') {
						/* Success creating game, error adding facilitator, close popup */
						setIsLoading(false);	
						togglePopup(false);	
					} else {
					/* Error creating game */
						setIsLoading(false);
						setErrorMsg(response.error);
					}
				} else {
					/* Success creating game, close popup */
					setIsLoading(false);
					togglePopup(false);	
				}		
			});
		}
	};

	/**
	 * Checks if the create game button should be enabled or not.
	 * @returns bool. True if input filled out correctly.
	 */
	const getButtonDisabled = () => {
		if (emailInputWarning !== null) return true;
		if (gameNameInputWarning !== null) return true; 
		if (urlWarning !== null) return true;
		if (!gameUrl || gameUrl.length === 0) return true;
		if (!gameName  || gameName.length === 0) return true;

		return false;
	};

	return (
		<div className="AdminAddGamePopup" onClick={(e) => {e.stopPropagation();}}>
			<div className="AdminAddGamePopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="AdminAddGamePopup-header">
					<div className="AdminAddGamePopup-title">
						{getText(adminUiTexts.addGamePopup.title, languageId)}
					</div>
					<div 
						className="AdminAddGamePopup-closeBtn" 
						onClick={() => {togglePopup();}}
					/>				
				</div>

				{/* Popup body */}
				<div className='AdminAddGamePopup-body'>
					{/* Select scenario */}
					<div className='AdminAddGamePopup-input'>
						<Select 
							isDisabled={false}
							languageId={languageId}
							type="scenario"
							defaultId={scenarioId}
							selectedId={scenarioId}
							options={scenariosData}
							onSelect={setScenarioId}
						/>
					</div>

					{/* Input gameName name */}
					<div className='AdminAddGamePopup-input'>
						<div className={'AdminAddGamePopup-inputField' + (gameNameInputWarning ? ' invalid' : '')}>
							<input 
								name="gameName"
								type="text"
								placeholder={getText(adminUiTexts.addGamePopup.gameNamePlaceholder, languageId)}
								value={gameName ? gameName : ''}
								onChange={(e) => {updateInput(e);}}
							/>
						</div>
						<div className='AdminAddGamePopup-warning'><span>{gameNameInputWarning}</span></div>
					</div>
					{/* Input game gameUrl */}
					<div className='AdminAddGamePopup-input'>
						<div className='AdminAddGamePopup-inputField gameUrl'>
							<span>{window.location.host + '/'}</span>
							<input 
								name="gameUrl"
								type="text"
								placeholder={getText(adminUiTexts.addGamePopup.gameUrlPlaceholder, languageId)}
								value={gameUrl ? gameUrl : ''}
								onChange={(e) => {updateInput(e);}}
							/>
						</div>
						<div className='AdminAddGamePopup-warning'><span>{urlWarning}</span></div>
					</div>
					{/* Input facilitator e-mail */}
					<div className='AdminAddGamePopup-input'>
						<div className={'AdminAddGamePopup-inputField' + (emailInputWarning ? ' invalid' : '')}>
							<input 
								name="email"
								type="text"
								placeholder={getText(adminUiTexts.addGamePopup.facilitatorPlaceholder, languageId)}
								value={facilitatorEmail ? facilitatorEmail : ''}
								onChange={(e) => {updateInput(e);}}
							/>
						</div>
						<div className='AdminAddGamePopup-warning'><span>{emailInputWarning}</span></div>
					</div>
					<div className="AdminAddGamePopup-errorMsg"><span>{errorMsg}</span></div>

					<div className='AdminAddGamePopup-createGameBtn'>
						<Button
							text={getText(adminUiTexts.addGamePopup.okBtn, languageId)}
							classes={['facilitator']}
							isLoading={isLoading}
							isDisabled={getButtonDisabled()}
							onClick={() => {handleAddGame();}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

AdminAddGamePopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	gamesData: PropTypes.array.isRequired,
	createGame: PropTypes.func.isRequired,
	togglePopup: PropTypes.func.isRequired,
};

export default AdminAddGamePopup;
