import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyB2LNGKOoF_V_kJJfb4Im-48vA2TLznQ3I',
		authDomain: 'cgl-hygiejne-test.firebaseapp.com',
		projectId: 'cgl-hygiejne-test',
		storageBucket: 'cgl-hygiejne-test.appspot.com',
		messagingSenderId: '174294546674',
		appId: '1:174294546674:web:21331d3e34fd3b6565b86b'						
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyCdnKZzgH8MaxlPUQb6fpcLyOjqg-9IBBk',
		authDomain: 'cgl-hygiejne-production.firebaseapp.com',
		projectId: 'cgl-hygiejne-production',
		storageBucket: 'cgl-hygiejne-production.appspot.com',
		messagingSenderId: '1034917595595',
		appId: '1:1034917595595:web:0a1fb8f908229dde698e4e'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;