const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-a-instructions-welcome',
		background: 'dressing-room',
		text: {
			da: `Velkommen til gentræning 1A. Det er det første af to moduler, du skal igennem i dag.`,
			en: `Welcome to retraining 1A. This is the first of two modules you will go through today.`,
			pl: `Witamy w module przypominającym 1A. To pierwszy z dwóch modułów, przez które dzisiaj przejdziesz.`,
			de: `Willkommen zur jährlichen Wiederholungsschulung 1A. Dies ist das erste von zwei Modulen, das du heute durchlaufen wirst.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-a-instructions-points',
		background: 'dressing-room',
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In the game you get points for solving tasks. The fewer mistakes you make, the more points you get.`,
			pl: `W grze otrzymujesz punkty za rozwiązywanie zadań. Im mniej błędów popełnisz, tym więcej punktów otrzymasz.`,
			de: `Im Spiel bekommst du Punkte für das Lösen von Aufgaben. Je weniger Fehler du machst, desto mehr Punkte erhältst du.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-a-instructions-stars',
		background: 'dressing-room',
		text: {
			da: `Når du får point kan du også få stjerner. Se hvor mange point og stjerner du har i toppen af skærmen.`,
			en: `When you get points, you can also get stars. See how many points and stars you have at the top of the screen.`,
			pl: `Kiedy zdobywasz punkty, otrzymujesz również gwiazdki. Na górze ekranu możesz zobaczyć, ile masz punktów i gwiazdek.`,
			de: `Wenn du Punkte erhältst, bekommst du auch Sterne. Oben auf dem Bildschirm siehst du, wie viele Punkte und Sterne du gesammelt hast.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-a-instructions-required-stars',
		background: 'dressing-room',
		text: {
			da: `Husk du skal have tre stjerner for at gennemføre modulet.`,
			en: `Remember, you must have three stars to move on to the next module.`,
			pl: `Pamiętaj, aby przejść do następnego modułu, musisz mieć trzy gwiazdki.`,
			de: `Denke daran, dass du drei Sterne haben musst, um zum nächsten Modul zu gelangen.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-a-instructions-dressing-mistakes',
		background: 'dressing-room',
		text: {
			da: `Vi starter med påklædning. Tryk på de ting som er forkerte på næste billede.`,
			en: `We start with clothes. Press on the things that are wrong in the next picture.`,
			pl: `Zaczynamy od ubrań. Na następnym obrazku naciśnij rzeczy, które są niepoprawne/niewłaściwe.`,
			de: `Wir beginnen mit Kleidung. Klicke auf die Dinge, die im nächsten Bild falsch sind.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'repetition-1-a-spot-errors-dressing-mistakes',
		layout: 'repetition-1-a-dressing-mistakes',
		background: 'dressing-room',
		text: {
			da: `Tryk på de 4 fejl.<br /><br />Tryk OK når du tror, du har fundet dem alle.`,
			en: `Press the 4 errors.<br /><br />Press OK when you think you have found them all.`,
			pl: `Zaznacz 4 błędy.<br /><br />Naciśnij OK, gdy uważasz, że znalaziono wszystkie.`,
			de: `Klicke die 4 Fehler an.<br /><br />Bestätige mit OK, wenn du denkst, dass du sie alle entdeckt hast.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'watch',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hair',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'dirt',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'buttons',
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-a-mc-lunch',
		background: 'dressing-room',
		layout: 'repetition-1-a-lunch',
		subtype: 'images',
		text: {
			da: `Du er på vej til frokost i kantinen. Hvilke madvarer må du IKKE have med i madpakken?`,
			en: `You are on your way to lunch in the canteen. Which food items must NOT be included in the lunchbox?`,
			pl: `Jesteś w drodze na lunch do stołówki. Jakich produktów spożywczych NIE MOŻESZ wkładać do pudełka śniadaniowego?`,
			de: `Du bist auf dem Weg zum Mittagessen in der Kantine. Welche Lebensmittel darfst du NICHT in der Lunchbox mitbringen?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-a-nutella',
		background: 'dressing-room',
		text: {
			da: `Hvorfor må du ikke have nutella med?`,
			en: `Why can't you bring nutella?`,
			pl: `Dlaczego nie możesz przynieść nutelli?`,
			de: `Warum darfst du kein Nutella mitbringen?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Der er nødder i Nutella. Det kan gøre forbrugere syge hvis de er allergiske`,
					en: `There are nuts in nutella. It can make consumers sick if they are allergic`,
					pl: `W nutelli są orzechy. Może powodować choroby u konsumentów, jeśli są uczuleni`,
					de: `Nutella enthält Nüsse. Es kann Verbraucher krank machen, wenn sie allergisch sind`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det er usundt`,
					en: `It is unhealthy`,
					pl: `To jest niezdrowe`,
					de: `Es ist ungesund`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Man må kun spise kantinens nutella`,
					en: `You may only eat the canteen's nutella`,
					pl: `Możesz jeść tylko nutellę ze stołówki`,
					de: `Du darfst nur das Nutella der Kantine essen`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Fordi nutella smager dårligt på kød`,
					en: `Because nutella tastes bad on meat`,
					pl: `Ponieważ nutella źle smakuje z mięsem`,
					de: `Weil Nutella auf Fleisch schlecht schmeckt`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-a-instructions-hands',
		background: 'dressing-room',
		text: {
			da: `En af de vigtigste regler er at have god håndhygiejne. Det handler både om at vaske hænder tit, men også om at gøre det ordentligt.`,
			en: `One of the most important rules is to have good hand hygiene. It is both about washing your hands often, but also about doing it properly.`,
			pl: `Jedną z najważniejszych zasad jest przestrzeganie zasad higieny rąk. Chodzi zarówno o częste mycie rąk, jak i o robienie tego w odpowiedni sposób.`,
			de: `Eine der wichtigsten Regeln ist eine gute Handhygiene. Es geht sowohl darum, sich häufig die Hände zu waschen, als auch darum, es richtig zu tun.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-a-mc-hands',
		background: 'dressing-room',
		layout: 'repetition-1-a-hands',
		subtype: 'images',
		text: {
			da: `Der er især to områder som er nemme at glemme under håndvask. Hvilke er det?`,
			en: `There are two areas in particular that are easy to forget during hand washing. Which ones are they?`,
			pl: `Są szczególnie dwa obszary, o których łatwo zapomnieć podczas mycia rąk. Które to są?`,
			de: `Es gibt vor allem zwei Bereiche, die beim Händewaschen leicht vergessen werden. Welche sind das?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-a-washing-hands',
		background: 'dressing-room',
		text: {
			da: `Hvor tit skal du huske at vaske hænder?`,
			en: `How often should you remember to wash your hands?`,
			pl: `Jak często należy pamiętać o myciu rąk?`,
			de: `Wie oft solltest du daran denken, deine Hände zu waschen?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Hver anden time`,
					en: `Every other hour`,
					pl: `Co drugą godzinę`,
					de: `Alle zwei Stunden`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Når de er blevet forurenet`,
					en: `When they have been contaminated`,
					pl: `Kiedy zostały zanieczyszczone`,
					de: `Wenn sie kontaminiert sind`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Nemlig! For eksempel hvis du bruger stiger, rører ved dit ansigt eller samler noget op fra gulvet.`,
								en: `Exactly! For example if you use ladders, touch your face or pick something up from the floor.`,
								pl: `Dokładnie! Na przykład, jeśli korzystasz z drabiny, dotkniesz swojej twarzy lub podniesiesz coś z podłogi.`,
								de: `Genau! Wenn du zum Beispiel Leitern benutzt hast, du dir ins Gesicht gefasst hast oder etwas vom Boden aufgehoben hast.`
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Efter pauser`,
					en: `After breaks`,
					pl: `Po przerwach`,
					de: `Nach Pausen`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Når jeg passerer en hygiejnesluse`,
					en: `When I pass a hygiene lock`,
					pl: `Kiedy mijam śluzę higieniczną`,
					de: `Wenn ich an einer Hygieneschleuse vorbeikomme`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Hver time`,
					en: `Every hour`,
					pl: `Co godzinę`,
					de: `Jede Stunde`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Hvert kvarter`,
					en: `Every 15 minutes`,
					pl: `Co 15 minut`,
					de: `Alle 15 Minuten`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-a-instructions-lockers',
		background: 'dressing-room',
		text: {
			da: `Inden vi går ind i produktionen, så skal vi lige forbi omklædningsrummet.`,
			en: `Before we enter the production, we have to stop by the dressing room.`,
			pl: `Zanim wejdziemy na produkcję, musimy zatrzymać się w szatni.`,
			de: `Bevor wir die Produktion betreten, müssen wir bei der Umkleidekabine vorbeischauen.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-a-instructions-locker-types',
		background: 'dressing-room2',
		text: {
			da: `Du har et skab til privat tøj og et sted til arbejdstøj.`,
			en: `You have a closet for personal clothes and a place for work clothes.`,
			pl: `Masz szafkę na ubrania osobiste i miejsce na ubrania robocze.`,
			de: `Du hast einen Schrank für persönliche Kleidung und einen Platz für Arbeitskleidung.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-a-mc-private-clothes',
		background: 'dressing-room2', // UPDATE BACKGROUND
		layout: 'repetition-1-a-private-clothes',
		subtype: 'images',
		text: {
			da: `Hvilket af følgende hører IKKE til i skabet til privat tøj?`,
			en: `Which of the following does NOT belong in the closet for private clothing?`,
			pl: `Które z poniższych NIE pasuje do szafki na odzież prywatną?`,
			de: `Was gehört NICHT in den Schrank für Privatkleidung?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // normal shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // thermo jacket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // flip flops
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // white work shoes
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-a-mc-work-clothes',
		background: 'dressing-room2', // UPDATE BACKGROUND
		layout: 'repetition-1-a-work-clothes',
		subtype: 'images',
		text: {
			da: `Hvad hører IKKE til der hvor du skal lægge eller hænge dit arbejdstøj?`,
			en: `What does NOT belong where you put or hang your work clothes?`,
			pl: `Co NIE pasuje do miejsca, w którym kładziesz lub wieszasz ubrania robocze?`,
			de: `Was gehört NICHT dorthin, wo du deine Arbeitskleidung hinlegst oder aufhängst?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // safety glasses
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // white work clothes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // white work shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,// cap and neck wrap
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-a-mc-neither-clothes',
		background: 'dressing-room2', // UPDATE BACKGROUND
		layout: 'repetition-1-a-neither-clothes',
		subtype: 'images',
		text: {
			da: `Hvad må IKKE opbevares i omklædningsrummet?`,
			en: `What should NOT be stored in the changing room?`,
			pl: `Czego NIE należy przechowywać w szatni?`,
			de: `Was darf NICHT in der Umkleidekabine aufbewahrt werden?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // knife
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // blue apron
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // thermo socks
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // towel
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-a-instructions-storage',
		background: 'dressing-room2',
		text: {
			da: `Det er vigtigt at opbevare ting de rigtige steder. På den måde undgår du at værktøj, tøj og skabe bliver forurenet.`,
			en: `It is important to store things in the right places. This way, you avoid contamination of tools, clothes and closets.`,
			pl: `Ważne jest, aby przechowywać rzeczy we właściwych miejscach. W ten sposób unikniesz zanieczyszczenia narzędzi, ubrań i szafek.`,
			de: `Es ist wichtig, die Dinge an den richtigen Stellen zu lagern. Auf diese Weise vermeidest du eine Kontamination von Arbeitsgeräten, Werkzeugen, Kleidung und Schränken.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-a-instructions-finish',
		background: 'dressing-room2',
		text: {
			da: `Godt gået! 
			<br /><br />Du er nu igennem første del af gentræningen. Hvis du har fået tre stjerner, kan du fortsætte til næste modul. Hvis ikke, så må du tage modulet en gang til.
			<br /><br />Hvis du er i tvivl om noget, så spørg kursuslederen eller din mester.`,
			en: `Well done!
			<br /><br />You are now through the first part of the retraning. If you have received three stars, you can continue to the next module. If not, then you must take the module again.
			<br /><br />If you are in doubt about something, ask the course leader or your manager.`,
			pl: `Dobrze zrobione!
			<br /><br />Zakończono właśnie pierwszą część szkolenia przypominającego. Jeśli otrzymałeś/aś trzy gwiazdki, możesz przejść do następnego modułu. Jeśli nie, musisz ponownie przejść moduł.
			<br /><br />Jeśli masz jakieś wątpliwości, zapytaj prowadzącego kurs lub swojego kierownika.`,
			de: `Gut gemacht!
			<br /><br />Du hast jetzt den ersten Teil der Umschulung hinter dich gebracht. Wenn du drei Sterne erhalten hast, kannst du mit dem nächsten Modul fortfahren. Wenn nicht, musst du das Modul wiederholen.
			<br /><br />Frage im Zweifelsfall den Kursleiter oder deinen Vorgesetzten.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'repetition-1-a-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};