const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-instructions-welcome',
		text: {
			da: `Velkommen til gentræning 2B. 
				<br /><br />Nu skal vi ind i selve produktionen. Husk du skal have tre stjerner for at gennemføre.`,
			en: `Welcome to retraining 2B. 
				<br /><br />Now we have to get into the actual production. Remember you need three stars to complete the module.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-instructions-hygiene-important',
		text: {
			da: `Hygiejne er ekstremt vigtigt i produktionen. Og fordi du er tæt på produktet, er du den første der kan lægge mærke til problemer.`,
			en: `Hygiene is extremely important in the production. And because you are close to the product, you are the first to notice problems.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-mc-golden-rule',
		text: {
			da: `Hvad er den gyldne regel, hvis du spotter noget, som måske er et problem?`,
			en: `What is the golden rule if you spot something that might be a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Stands produktionen`,
					en: `Stop the production`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Skriv en note`,
					en: `Write a note`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Smid alt kød i nærheden af problemet ud`,
					en: `Throw out any meat near the problem`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Spørg mester`,
					en: `Ask a manager`
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Lige præcis! Hvis du er i tvivl, så skal du bare spørge.`,
								en: `Exactly! If in doubt, just ask.`
							}
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-order-broken-lamp',
		text: {
			da: `En lampe går i stykker i produktionen. Der er risiko for, der er kommet plastik i produkterne. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge.`,
			en: `A lamp breaks in the production. There is a risk that plastic has gotten into the products. What should you do? Put the actions below in the correct order.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Stop produktionen`,
					en: `Stop the production`
				}, 
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Tilkald mester`,
					en: `Call for a manager`
				}, 
				correctOrderNumbers: [2]
			}),

			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Afvent frigivelse fra mester`,
					en: `Awaite release from the manager`
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Fortsæt arbejdet`,
					en: `Continue working`
				}, 
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-mc-allergens',
		layout: 'repetition-2-1b-allergens',
		text: {
			da: `Forurening kan være et problem for folk med allergi. Klik på to allergener.`,
			en: `Contamination can be a problem for people with allergies. Click on two allergens.`
		},
		subtype: 'images',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, //Peanuts
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Hvedemel
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, //Jordbær
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // pineapple
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // apple
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-mc-contamination-problem-why',
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: `Our products can become contaminated in various ways. Why is contamintion a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Det kan være farligt`,
					en: `It can be dangerous`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det kan ødelægge smagen`,
					en: `It can spoil the taste`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `This can result in dissatisfied customers`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Det kan tiltrække skadedyr`,
					en: `It can attract pests`
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-instructions-contamination',
		text: {
			da: `Det er vigtigt at holde øje med, at vores produkter ikke er forurenet. Hvis de er forurenet - det kan være med fremmedlegemer, kemikalier eller bakterier - så skal det ikke ud til forbrugerne.`,
			en: `It is important to make sure that our products are not contaminated. If it is contaminated - it could be with foreign bodies, chemicals or bacteria - then it should not go out to consumers.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-instructions-contamination-examples',
		text: {
			da: `Lad os kigge på nogle eksempler.`,
			en: `Let's look at some examples.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-spot-errors-production-hall',
		layout: 'repetition-2-1b-production-hall',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK når du mener, du har dem alle.`,
			en: `Press the 4 errors. Press OK when you think you have them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'drop', // Dråbe hænger ned fra rør over produktionsbånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'lamp', // En lampe lyser ikke og har hul
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'screw', // Skrue eller møtrik på båndet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hammer', // Hammer på gulvet
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-mc-dripping',
		text: {
			da: `Hvis der fx drypper kondensvand ned fra et rør, så er det problematisk. Hvorfor?`,
			en: `If, for example, condensation water drips down from pipes, then it is problematic. Why?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Det kan forurene de efterfølgende produkter`,
					en: `It can contaminate the following products`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Produkterne må gerne blive på båndet`,
					en: `The products may remain on the belt`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Der er risiko for at forbrugerne kan blive syge`,
					en: `There is a risk that consumers may become ill`
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-mc-can-cleaning-agent',
		text: {
			da: `Du ser en dunk med rengøringsmiddel stå på båndet. Hvad gør du?`,
			en: `You see a bottle of cleaning agent on the production line. What do you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Lader det stå`,
					en: `Let it be`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fjerner det og tilkalder så mester`,
					en: `Removes it and then summons manager`
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Godt! Vi skal sikre os at vores produkter ikke bliver forurenet.`,
								en: `Good! We must ensure that our products are not contaminated.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Tilkalder mester`,
					en: `Call for a manager`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Altid godt at spørge, men husk også at fjerne flasken.`,
								en: `Always good to ask, but also remember to remove the bottle.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Fjerner det`,
					en: `Remove it`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-instructions-ask-manager',
		text: {
			da: `Hvis du er i tvivl om hvorvidt noget er blevet kemisk forurenet, så spørg din mester.`,
			en: `If you are in doubt as to whether something has been chemically contaminated, ask your master.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-mc-unwanted-visitors',
		text: {
			da: `Af og til kommer der gæster i produktionen. Hvilke gæster skal du holde særligt øje med?`,
			en: `From time to time there are uninvited guests in the production. What should you look out for in particular?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Mus og andre skadedyr`,
					en: `Mice and other pests`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fødevareinspektører`,
					en: `Food inspectors`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Kunder`,
					en: `Customers`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Eksterne håndværkere`,
					en: `External craftsmen`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-instructions-vermin',
		text: {
			da: `Skadedyr kan forurene produkterne, så sørg for altid at holde, døre, vinduer og porte lukkede - på den måde har dyrene sværere ved at komme ind.`,
			en: `Pests can contaminate the products, so make sure you always keep doors, windows and gates closed - that way it is harder for the animals to get in.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-instructions-spot-vermin',
		text: {
			da: `Det gælder også om at holde øje med de spor, skadedyr laver. Lad os se, om du kan spotte dem.`,
			en: `It also applies to keeping an eye on the traces that pests make. Let's see if you can spot them.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-mc-vermin-signs',
		layout: 'repetition-2-b-vermin-signs',
		text: {
			da: `Hvor er der spor efter skadedyr. Du skal kigge godt efter.`,
			en: `Where are there traces of pests. You have to look carefully.`
		},
		subtype: 'images',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Hjørne af produktionen med små muselorte
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Hjørne af produktionen med et par kasser
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Hjørne af produktionen med en ledning der er gnavet lidt i. 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Hjørne af produktionen hvor der ligger noget kød på en reol eller noget. 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Kødet skal helt sikkert ikke ligge her, men der er ingen tegn på skadedyr. `,
								en: `The meat is definitely not supposed to be here, but there are no signs of pests.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-instructions-last-question',
		text: {
			da: `Så er der bare et spørgsmål tilbage.`,
			en: `Now there is only one question left.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-mc-extra-attention',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne i produktionen?`,
			en: `Why should we pay extra attention to hygiene in the production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `På grund af lovkrav`,
					en: `Due to legal requirements`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`,
					en: `Because poor hygiene can make people sick`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all the above reasons`
				},
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2b-instructions-finish',
		text: {
			da: `Godt gået! 
				<br /><br />Du er nu igennem gentræningen. Hvis du er i tvivl om noget, så spørg kursuslederen eller din mester. 
				<br /><br />God arbejdslyst derude!`,
			en: `Well done!
				<br /><br />You are now through retraining. If you are in doubt about something, ask the course leader or your manager.
				<br /><br />Have a nice day`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'repetition-2-2b-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};