const scenariosData = [
	{
		id: 'scenario1',
		title: {
			da: 'Fresh',
			en: 'Fresh',
			pl: 'Fresh',
			de: 'Fresh'
		},
		languageIds: ['da', 'en', 'de', 'pl'],
		modulesData: require('data/scenarios/scenario1/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario1/modules-data').moduleGroups,
	},
	{
		id: 'scenario2',
		title: {
			da: 'Processed',
			en: 'Processed',
			pl: 'Processed',
			de: 'Processed'
		},
		languageIds: ['da', 'en'],
		modulesData: require('data/scenarios/scenario2/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario2/modules-data').moduleGroups,
	}
];

export {
	scenariosData
};