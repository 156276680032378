import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import dayjs from 'dayjs';
import {getText} from 'helpers/language-helper';
import {
	getPlayerModuleData,
	checkIfModuleIsDisabled,
	checkIfModuleIsUnlocked, 
	checkIfPlayerHasRequiredNumberOfStars
} from 'helpers/module-helper';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import {playerUiTexts} from 'data/ui-texts/player-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import Button from 'components/ui/button/button';
import DcBackground from 'components/ui/dc-background/dc-background';
import Logo from 'components/ui/logo/logo';
import Popup from 'components/ui/popup/popup';
import './modules-overview.scss';

const ModulesOverview = (props) => {
	const {
		languageId, 
		userData, 
		gameData, 
		handleGoToGamePage, 
		handleGoToPage, 
		resetPlayerGameData, 
		handleLogout
	} = props;

	/* Scenario data */
	const scenarioId = (gameData.scenarioId ? gameData.scenarioId : 'scenario1');
	const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});

	/* "Module is disabled" popup */
	const [enableDate, setEnableDate] = useState(null);
	const resetEnableDate = () => {setEnableDate(null);};


	/**
	 * Handle go to module
	 * @param {bool} isUnlocked 
	 * @param {bool} isDisabled 
	 * @param {string} moduleId 
	 */
	const handleGoToModule = (isUnlocked, isDisabled, moduleId, playerModuleData) => {
		if (isDisabled) {
			const enableDate = dayjs(playerModuleData.disabledDate).add(7, 'day').format('MM/DD-YYYY');
			setEnableDate(enableDate);
		} else {
			if (
				isUnlocked || 
				appConfig.env === 'development' || 
				appConfig.env === 'test' ||
				appConfig.env === 'demo'
			) {
				handleGoToGamePage('module', moduleId);	
			}
		}
	};

	return (
		<div className="ModulesOverview">
			{/* Background */}
			<DcBackground scenarioID={scenarioId}/>

			{/* CGL logo */}
			<div className="ModulesOverview-logoCGL">
				<Logo type="cgl"/>
			</div>

			{/* Reset btn */}
			{(appConfig.env === 'development' || appConfig.env === 'test') &&
				<div className="ModulesOverview-resetBtn">
					<Button
						text={getText(loginUiTexts.reset, languageId)}
						classes={['logout-player']}
						onClick={() => {resetPlayerGameData();}}
					/>
				</div>
			}

			{/* Modules */}
			<div className="ModulesOverview-content">
				<div className="ModulesOverview-title">
					<span>{getText(playerUiTexts.moduleOverview, languageId)}</span>
				</div>
				<div className="ModulesOverview-employeeNumber">
					<span>{getText(playerUiTexts.employeeNumber, languageId)}: </span>
					<span>{userData.id}</span>
				</div>
				<div className="ModulesOverview-modules">
					{scenarioData.modulesData.map((moduleData) => {
						/* Only show published modules */
						if (
							appConfig.env === 'production' && 
							moduleData.isPublished !== true
						) return null;

						/* Get module status */
						const isUnlocked = checkIfModuleIsUnlocked(moduleData.id, userData);
						const isCompleted = (
							isUnlocked &&
							checkIfPlayerHasRequiredNumberOfStars(userData, moduleData.id)
						);		
						
						/* Check if module is disabled (happens if they have failed it 3 times) */
						const playerModuleData = getPlayerModuleData(moduleData.id, userData);
						const isDisabled = (isUnlocked ? checkIfModuleIsDisabled(playerModuleData) : false);
						
						return (
							<div 
								key={moduleData.id}
								className={'ModulesOverview-module ' + moduleData.id 
									+ (isCompleted ? ' completed' : '')
									+ (!isUnlocked ? ' locked' : '')
									+ (isDisabled ? ' disabled' : '')
								}
								onClick={() => {
									handleGoToModule(isUnlocked, isDisabled, moduleData.id, playerModuleData);
								}}
							>
								<span>{getText(moduleData.title, languageId)}</span>
							</div>
						);
					})}
				</div>
			</div>
			
			<div className="ModulesOverview-buttons"> 
				{/* Language btn */}
				<div className="ModulesOverview-languageBtn" > 
					<Button
						classes={['switch-language']}
						onClick={() => {handleGoToPage('choose-language');}}
					/>
				</div>
				{/* Logout btn */}
				<div className="ModulesOverview-logoutBtn" > 
					<Button
						text={getText(loginUiTexts.logout, languageId)}
						classes={['logout-player']}
						onClick={() => {handleLogout();}}
					/>
				</div>
			</div>

			{/* "Talk to facilitator popup" */}
			{enableDate &&
				<Popup	
					type="module-is-disabled"
					text={
						JSON.parse(JSON.stringify(getText(playerUiTexts.moduleLockedPopup.text, languageId)))
							.replace('%date%', enableDate)
					}
					buttons={[
						{
							classes: ['yellow'],
							text: getText(playerUiTexts.moduleLockedPopup.okBtn, languageId),
							action: resetEnableDate
						}
					]}
					togglePopup={resetEnableDate}
				/>
			}
		</div>
	);
};

ModulesOverview.propTypes = {
	languageId: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	gameData: PropTypes.object.isRequired,
	handleGoToGamePage: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	resetPlayerGameData: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default ModulesOverview;
