const taskTemplates = require('data/templates/task-templates');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-instructions-welcome',
		showProgressBar: false,
		background: 'sunrise-1',
		text: {
			da: `Velkommen til hygiejnetræning A. Jeg er din manager og hjælper dig gennem spillet.`,
			en: `Welcome to hygiene training A. I'm your manager and will help you through the game.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-instructions-points-errors',
		showProgressBar: false,
		background: 'sunrise-2',
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In the game you get points for solving tasks. The fewer mistakes you make, the more points you get.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'sunrise-3',
		taskId: 'personal-hygiene-2-instructions-points-stars',
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har.`,
			en: `When you get points, you also get stars. See how many points and stars you have at the top of the screen.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'sunrise-4',
		taskId: 'personal-hygiene-2-instructions-points-to-progress',
		text: {
			da: `Du skal have tre stjerner for at gå videre til det næste modul.`,
			en: `You need three stars to move on to the next module.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'sunrise-5',
		taskId: 'personal-hygiene-2-instructions-first-task',
		text: {
			da: `Første opgave handler om den rigtige påklædning.`,
			en: `The first task is about the proper attire.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-mc-hair-and-beard',
		layout: 'personal-hygiene-hair-and-beard',
		subtype: 'images',
		text: {
			da: `Hvordan skal hårnet og skægbind sidde?<br />Tryk på de rigtige billeder.`,
			en: `How should you correctly wear hair net and beard mask?<br />Click on the correct pictures.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4				
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5		
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-mc-jewelry',
		subtype: 'images',
		layout: 'personal-hygiene-2-jewelry',
		text: {
			da: `Hvilke smykker må du IKKE have på i produktionslokaler?`,
			en: `What jewelry are you NOT allowed to wear in production areas?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Glasses
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Nose piercing
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Belly button piercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Wrist watch
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Necklace
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-instructions-hygiene-dressing-room',
		text: {
			da: `God hygiejne starter allerede i omklædningsrummet. Prøv at se, hvilke problemer du kan spotte på næste billede.`,
			en: `Good hygiene starts in the changing room. Try to see which problems you can spot in the next picture.`
		},
		background: 'dressing-room',
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-spot-errors-dressing-room',
		layout: 'personal-hygiene-2-dressing-room',
		text: {
			da: `Tryk på de 4 ting som er forkerte på billedet.<br /><br/>Tryk OK når du tror, du har dem alle.`,
			en: `Press the 4 things that are wrong in the picture.<br /><br/>Press OK when you think you have them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'safety-glasses',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'white-shoes',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hair-net',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'apron',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-instructions-clothes-dressing-room',
		text: {
			da: `I omklædningsrummet er der et skab til privat tøj og et sted til arbejdstøj. Det er vigtigt at lægge ting de rigtige steder.`,
			en: `In the changing room there is a closet for personal clothes and a place for work clothes. It is important to put things in the right places.`
		},
		background: 'dressing-room',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-mc-lockers',
		layout: 'personal-hygiene-lockers',
		subtype: 'images',
		text: {
			da: `Hvilke ting skal i skabet til privat tøj?`,
			en: `Which items should be in the closet for private clothes?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Gul refleksvest,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Hvide arbejdssko
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Beskyttelsesbriller
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Termojakke
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Blå frysejakke
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Halsedisse
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-mc-lockers',
		layout: 'personal-hygiene-lockers',
		background: 'dressing-room',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-instructions-hygiene-lock',
		background: 'dressing-room',
		text: {
			da: `Godt, så er vi vist ved at være klar til at gå ud i produktionen. Der møder du altid en hygiejnesluse. Lad os lige kigge nærmere på den.`,
			en: `Good, now we're about ready to go into the production. There you will always meet a hygiene sluice. Let's take a closer look at it.`
		},
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-order-wash-hands',
		background: 'dressing-room',
		text: {
			da: `I hygiejnesluser skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `In hygiene sluice, you must always wash your hands. What is the correct order to do things?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Vask hænder med sæbe og vand`,
					en: `Wash hands with soap and water`
				}, 
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Tør hænder med engangshåndklæde`,
					en: `Dry hands with a disposable towel`
				}, 
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Smid engangshåndklæde i skraldespand`,
					en: `Throw the disposable towel in the trash`
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Brug håndsprit`,
					en: `Use hand sanitizer`
				}, 
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-mc-nails',
		background: 'dressing-room',
		layout: 'personal-hygiene-nails',
		subtype: 'images',
		text: {
			da: `Håndhygiejne handler også om neglene.
				<br />Tryk på det billede, som er korrekt.`,
			en: `Hand hygiene also includes the nails.
				<br />Click on the correct picture.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4				
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-mc-when-wash-hands',
		shuffleOptions: false,
		showNumberOfAnswersToSelect: false,
		background: 'dressing-room',
		text: {
			da: `Efter hvilke situationer skal du huske at vaske hænder?
				<br /><br />Vælg 1 - og husk at læse alle mulighederne:`,
			en: `After which situations should you remember to wash your hands?
				<br /><br />Choose 1 - and remember to read all the options:`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Når du passerer en hygiejnesluse`,
					en: `When you pass a hygiene sluice`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Efter pauser`,
					en: `After breaks`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Efter at binde snørebånd`,
					en: `After tying shoelaces`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Når du har rørt ved din mund og næse`,
					en: `After touching your mouth and nose`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Efter brug af stiger`,
					en: `After using a ladder`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `I alle ovenstående situationer`,
					en: `In all the above situations`
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-2-instructions-finish',
		background: 'dressing-room',
		text: {
			da: `Sådan, du er nu færdig med første modul. Hvis du har fået tre stjerner, kan du fortsætte til næste modul. Hvis ikke, så må du tage modulet en gang til.`,
			en: `That's it, you have now finished the first module. If you have received three stars, you can continue to the next module. If not, then you must take the module again.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'personal-hygiene-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};