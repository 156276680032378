const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');


let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-b-instructions-welcome',
		background: 'slaughterhouse',
		text: {
			da: `Velkommen til gentræning 2B. Nu skal det handle endnu mere om det der sker ude i produktionen.`,
			en: `Welcome to retraining 2B. Now we will look even more at what happens in the production.`,
			pl: `Witamy w module przypominającym 2B. Teraz jeszcze bardziej przyjrzymy się temu, co dzieje się na produkcji.`,
			de: `Willkommen zur jährlichen Wiederholungsschulung 2B. Jetzt schauen wir uns noch genauer an, was in der Produktion passiert.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-b-mc-never-bring',
		layout: 'repetition-2-b-never-bring',
		background: 'slaughterhouse',
		text: {
			da: `Der er noget du aldrig må tage med på slagteriet - heller ikke i madpakken. Hvad er det?`,
			en: `There is something you must never take with you to the slaughterhouse - not even in the packed lunch. What is it?`,
			pl: `Jest coś, czego nigdy nie wolno zabierać ze sobą do rzeźni – nawet w przygotownym posiłku. Co to jest?`,
			de: `Es gibt etwas, das du niemals zum Schlachthof mitnehmen darfst – auch nicht im Lunchpaket. Was ist das?`
		},
		subtype: 'images',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, //Nutella
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, //Peanuts
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, //Jordbær
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, //Rejer
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-b-mc-nutella-peanuts',
		background: 'slaughterhouse',
		text: {
			da: `Hvorfor må du ikke tage nutella og peanuts med?`,
			en: `Why can't you bring nutella and peanuts?`,
			pl: `Dlaczego nie możesz przynieść nutelli i orzeszków ziemnych?`,
			de: `Warum darfst du kein Nutella und keine Erdnüsse mitbringen?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {						
					da: `Det kan være farligt for folk med allergi`,
					en: `It can be dangerous for people with allergies`,
					pl: `Może być niebezpieczny dla alergików`,
					de: `Das kann für Allergiker gefährlich werden`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det smager grimt sammen med kød`,
					en: `It tastes awful with meat`,
					pl: `Z mięsem smakuje okropnie`,
					de: `Zu Fleisch schmeckt es scheußlich`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det er usundt`,
					en: `It is unhealthy`,
					pl: `To jest niezdrowe`,
					de: `Es ist ungesund`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Det kan tiltrække skadedyr`,
					en: `It can attract pests`,
					pl: `Może przyciągać szkodniki`,
					de: `Es kann Schädlinge anlocken`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-b-mc-meat-quality',
		background: 'slaughterhouse',
		text: {
			da: `Det er vigtigt at holde øje med at kødet ikke bliver forurenet. Hvis det er forurenet, så skal det ikke ud til forbrugerne.`,
			en: `It is important to ensure that the meat does not become contaminated. If it is contaminated, it should not go out to consumers.`,
			pl: `Ważne jest, aby upewnić się, że mięso nie zostało zanieczyszczone. Jeśli jest zanieczyszczone, nie powinnno trafić do konsumentów.`,
			de: `Es ist stets darauf zu achten, dass Fleisch nicht kontaminiert wird. Wenn es doch kontaminiert wurde, sollte es nicht den Verbraucher erreichen.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-b-mc-meat-quality-examples',
		background: 'slaughterhouse',
		text: {
			da: `Lad os kigge på nogle eksempler.`,
			en: `Let's look at some examples.`,
			pl: `Spójrzmy na kilka przykładów.`,
			de: `Schauen wir uns einige Beispiele an.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-b-mc-contaminated-meat',
		layout: 'repetition-2-b-contaminated-meat',
		background: 'slaughterhouse',
		text: {
			da: `Hvilket stykke kød er forurenet?`,
			en: `Which piece of meat is contaminated?`,
			pl: `Który kawałek mięsa jest zanieczyszczony?`,
			de: `Welches Stück Fleisch ist kontaminiert?`
		},
		subtype: 'images',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-b-mc-meat-oil',
		background: 'slaughterhouse',
		text: {
			da: `Olien på kødet kommer garanteret fra en maskine. Hvad skal du gøre når du ser den slags?`,
			en: `The oil on the meat probably comes from a machine. What should you do when you see something like this?`,
			pl: `Olej na mięsie prawdopodobnie pochodzi z maszyny. Co powinieneś zrobić, gdy zobaczysz coś takiego?`,
			de: `Das Öl auf dem Fleisch stammt wahrscheinlich von einer Maschine. Was solltest du tun, wenn du so etwas siehst?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Tørre olien af kødet`,
					en: `Wipe the oil from the meat`,
					pl: 'Wytrzyj olej z mięsa',
					de: `Wische das Öl einfach vom Fleisch ab`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Tilkald mester`,
					en: `Call for the manager`,
					pl: 'Wezwij kierownika',
					de: `Rufe deinen Vorgesetzten`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Læg kødet til destruktion`,
					en: `Set the meat aside for destruction`,
					pl: 'Odłóż mięso do zniszczenia',
					de: `Lege das Fleisch zur Seite für Tierfutter`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Læg kødet til dyrefoder`,
					en: `Place the meat for animal feed`,
					pl: 'Umieść mięso w pojemniku na karmę dla zwierząt',
					de: `Lege das Fleisch zur Vernichtung beiseite`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-b-mc-contaminated-meat2',
		layout: 'repetition-2-b-contaminated-meat2',
		background: 'slaughterhouse',
		text: {
			da: `Hvilket stykke kød er nu forurenet?`,
			en: `Which piece of meat is now contaminated?`,
			pl: `Który kawałek mięsa jest teraz zanieczyszczony?`,
			de: `Welches Stück Fleisch ist jetzt kontaminiert?`
		},
		subtype: 'images',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // meat with issue
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // same meat, no issue
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // different meat, no issue
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // different meat, no issue
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-b-mc-meat-mould',
		background: 'slaughterhouse',
		text: {
			da: `Hvad skal du gøre med et stykke kød der er forurenet?`,
			en: `What should you do with a piece of meat that is contaminated?`,
			pl: `Co zrobić z zanieczyszczonym kawałkiem mięsa?`,
			de: `Was tun mit kontaminiertem Fleisch?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Smide det ud i almindelig skraldespand`,
					en: `Throw it away in the regular trashcan`,
					pl: `Wyrzuć do zwykłego kosza na śmieci`,
					de: `Wirf es in den normalen Mülleimer`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Sende det til reinspektion`,
					en: `Send it for reinspection`,
					pl: `Wyślij do ponownego sprawdzenia`,
					de: `Gib das kontaminierte Stück Fleisch zur Re-Inspektion`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Lægge det tilbage på båndet`,
					en: `Put it back on the belt`,
					pl: `Umieść z powrotem na taśmę`,
					de: `Lege es wieder auf das Band`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-b-instructions-half-way',
		background: 'slaughterhouse',
		text: {
			da: `Nu er du over halvvejs i opgaverne. Nu prøver vi at kigge nærmere på de ting som kan være galt i produktionen.`,
			en: `You are now more than halfway through the tasks. Now we look more closely at the things that could be wrong in the production.`,
			pl: `Jesteś już w połowie zadań. Teraz przyjrzymy się bliżej rzeczom, które mogą być niewłaściwe w produkcji.`,
			de: `Du hast jetzt mehr als die Hälfte deiner Aufgaben erledigt. Jetzt schauen wir uns genauer an, was in der Produktion schief gehen könnte.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'repetition-2-b-spot-errors-production',
		layout: 'repetition-2-b-production',
		text: {
			da: `Tryk på de 4 fejl.<br /><br />Tryk OK når du mener, du har dem alle.`,
			en: `Press on the 4 errors.<br /><br />Press OK when you think you have them all.`,
			pl: `Zaznacz 4 błędy.<br /><br />Naciśnij OK, gdy uważasz, że wybrano wszystkie.`,
			de: `Klicke die 4 Fehler an.<br /><br />Bestätige mit OK, wenn du glaubst, dass du sie alle gefunden hast.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'drop',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'glove',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'screw',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hammer',
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'repetition-2-b-spot-errors-production2',
		layout: 'repetition-2-b-production2',
		text: {
			da: `Hvad er der galt her? Tryk på de 3 fejl.<br /><br />Tryk OK når du mener, du har dem alle.`,
			en: `What's wrong here? Press on the 3 errors.<br /><br />Press OK when you think you have them all.`,
			pl: `Co tu jest nie tak? Zaznacz 3 błędy.<br /><br />Naciśnij OK,gdy uważasz, że wybrano wszystkie.`,
			de: `Was ist hier falsch? Klicke auf die 3 Fehler.<br /><br />Bestätige mit OK, wenn du glaubst, dass du sie alle gefunden hast.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'box',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'meat',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'overflowing',
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-b-mc-unwanted-visitors',
		background: 'slaughterhouse',
		text: {
			da: `Af og til kommer der ubudne gæster i produktionen. Hvad skal du holde særligt øje efter?`,
			en: `From time to time there are uninvited guests in the production. What should you look out for in particular?`,
			pl: `Od czasu do czasu w produkcji pojawiają się nieproszeni goście. Na co szczególnie zwrócić uwagę?`,
			de: `Hin und wieder gibt es ungebetene Gäste in der Produktion. Worauf solltest du besonders achten?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Mus og rotter`,
					en: `Mice and rats`,
					pl: `Myszy i szczury`,
					de: `Mäuse und Ratten`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Kunder`,
					en: `Customers`,
					pl: `Klienci`,
					de: `Kunden`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Der skal være lige så rent de dage hvor de er der, som når de ikke er der.`,
								en: `It must be just as clean on the days they are there, as when they are not there.`,
								pl: `Musi być tak samo czysto w dni, w których tam są, jak i wtedy, gdy ich tam nie ma.`,
								de: `An den Tagen, an denen sie da sind, muss es genauso sauber sein, wie an den Tagen, an denen sie nicht da sind.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Fluer`,
					en: `Flies`,
					pl: `Muchy`,
					de: `Fliegen`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Børnehaver`,
					en: `Children`,
					pl: `Dzieci`,
					de: `Kinder`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-b-instructions-vermin',
		background: 'slaughterhouse',
		text: {
			da: `Skadedyr kan forurene produkterne, så sørg for altid at holde, døre, vinduer og porte lukkede - på den måde har dyrene sværere ved at komme ind.`,
			en: `Pests can contaminate the products, so make sure to always keep doors, windows and gates closed - that way it is more difficult for the animals to get in.`,
			pl: `Szkodniki mogą zanieczyszczać produkty, więc upewnij się, że drzwi, okna i bramy są zawsze zamknięte - w ten sposób zwierzętom trudniej będzie dostać się do środka.`,
			de: `Schädlinge können die Produkte verunreinigen, also halte Türen, Fenster und Tore stets geschlossen – so ist es für die Tiere schwieriger einzudringen.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-b-instructions-vermin-signs',
		background: 'slaughterhouse',
		text: {
			da: `Det gælder også om at holde øje med de spor, skadedyr laver. Lad os se om du kan spotte dem.`,
			en: `It also important to keeping an eye on the traces that pests leave. Let's see if you can spot them.`,
			pl: `Ważne jest również obserwowanie śladów, które pozostawiają szkodniki. Zobaczmy, czy potrafisz je dostrzec.`,
			de: `Es ist auch wichtig, die Spuren, die Schädlinge hinterlassen, im Auge zu behalten. Mal sehen, ob du diese erkennen kannst`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-b-mc-vermin-signs',
		layout: 'repetition-2-b-vermin-signs',
		background: 'slaughterhouse',
		text: {
			da: `Hvor er der spor efter skadedyr. Du skal kigge godt efter.`,
			en: `Where are there traces of pests. You have to look carefully.`,
			pl: `Gdzie są ślady szkodników. Trzeba się dobrze przyjrzeć.`,
			de: `Wo sind hier die Schädlingspuren zu finden? Du musst genau hinsehen.`
		},
		subtype: 'images',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Kødet skal helt sikkert ikke ligge her, men der er ingen tegn på skadedyr. `,
								en: `The meat should not be placed here but there are no sign of pests.`,
								pl: `Mięsa zdecydowanie nie powinno tu być, ale nie ma śladów szkodników.`,
								de: `Hier sollte kein Fleisch abgelegt werden, aber es gibt keine Anzeichen von Schädlingen.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-b-instructions-one-question',
		background: 'slaughterhouse',
		text: {
			da: `Så er der bare et spørgsmål tilbage.`,
			en: `Now there is only one question left.`,
			pl: `Teraz pozostaje tylko jedno pytanie.`,
			de: `Jetzt bleibt nur noch eine Frage.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-b-mc-why-extra-attention',
		background: 'slaughterhouse',
		shuffleOptions: false,
		text: {
			da: `Hvorfor er det vi skal være ekstra opmærksomme på hygiejne her på slagteriet?`,
			en: `Why do we have to pay extra attention to hygiene here at the slaughterhouse?`,
			pl: `Dlaczego musimy zwracać szczególną uwagę na higienę w rzeźni?`,
			de: `Warum müssen wir hier im Schlachthof besonders auf Hygiene achten?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `På grund af lovkrav`,
					en: `Due to legal requirements`,
					pl: `Ze względu na wymogi prawne`,
					de: `Aufgrund gesetzlicher Vorgaben`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`,
					en: `Because poor hygiene can make people sick`,
					pl: `Ponieważ zła higiena może powodować choroby`,
					de: `Schlechte Hygiene kann krank machen`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`,
					pl: `Aby zapewnić wysoką jakość`,
					de: `Um eine hohe Qualität zu gewährleisten`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all the above reasons`,
					pl: `Ze wszystkich powyższych powodów`,
					de: `Aus all den oben genannten Gründen`
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-b-instructions-finish',
		background: 'slaughterhouse',
		text: {
			da: `Godt gået! 
				<br />Du er nu igennem anden del af gentræningen. Hvis du har fået tre stjerner, er du færdig for denne gang. Hvis ikke, så må du tage modulet en gang til.
				<br />Husk, er du i tvivl om noget, så spørg kursuslederen eller din mester.`,
			en: `Well done!
				<br />You are now through the second part of the retraining. If you got three stars, you're done for this time. If not, then you must take the module again. 
				<br />Remember, if you are in doubt about something, ask the course leader or your manager`,
			pl: `Dobrze zrobione!
				<br />Jesteś teraz w drugiej części szkolenia przypominającego. Jeśli masz trzy gwiazdki, tym razem to koniec. Jeśli nie, musisz ponownie przejść moduł.
				<br />Pamiętaj, jeśli masz co do czegoś wątpliwości, zapytaj prowadzącego kurs lub swojego kierownika.`,
			de: `Gut gemacht!
				<br />Du hast jetzt den zweiten Teil der Schulung hinter dich gebracht. Wenn du drei Sterne gesammelt hast, bist du mit diesem Modul fertig. Wenn nicht, musst du das Modul wiederholen.
				<br />Denke daran, wenn du Zweifel hast, frage den Kursleiter oder deinen Vorgesetzten.`
		}
	})
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'repetition-2-b-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};