const moduleRepetition22B = [
	'modules/tasks/multiple-choice/repetition-2-1b-allergens/option-1.svg',
	'modules/tasks/multiple-choice/repetition-2-1b-allergens/option-2.svg',
	'modules/tasks/multiple-choice/repetition-2-1b-allergens/option-3.svg',
	'modules/tasks/multiple-choice/repetition-2-b-vermin-signs/option-1.svg',
	'modules/tasks/multiple-choice/repetition-2-b-vermin-signs/option-2.svg',
	'modules/tasks/multiple-choice/repetition-2-b-vermin-signs/option-3.svg',
	'modules/tasks/multiple-choice/repetition-2-b-vermin-signs/option-4.svg',

	'modules/tasks/spot-errors/repetition-2-1b-production-hall/background.svg',
	'modules/tasks/spot-errors/repetition-2-1b-production-hall/lamps.svg',
];

export {
	moduleRepetition22B
};