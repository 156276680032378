const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-b-instructions-welcome',
		background: 'slaughterhouse',
		text: {
			da: `Velkommen til gentræning 1B. Nu skal vi ud i produktionen. Husk du skal have tre stjerner for at gennemføre.`,
			en: `Welcome to retraining 1B. Now we go into the production. Remember that you need three stars to complete.`,
			pl: `Witamy w module przypominającym 1B. Teraz przechodzimy do produkcji. Pamiętaj, że do ukończenia potrzebujesz trzech gwiazdek.`,
			de: `Willkommen zur jährlichen Wiederholungsschulung 1B. Jetzt gehen wir in die Produktion. Denke daran, dass du drei Sterne benötigst, um den Kurs zu bestehen.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-b-remember',
		background: 'hygiene-lock',
		text: {
			da: `Hvad skal du huske her inden du går ud i produktionen?`,
			en: `What should you remember here before going into the production?`,
			pl: `O czym należy tutaj pamiętać przed wejściem na produkcję?`,
			de: `Was solltest du beachten, bevor du in die Produktion gehst?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `At vaske hænder`,
					en: `To wash hands`,
					pl: `Myciu rąk`,
					de: `Hände waschen`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `At rette på mit hårnet`,
					en: `Fixing my hairnet`,
					pl: `Poprawieniu siatki na włosach`,
					de: `Den Sitz meines Haarnetzes zu kontrollieren`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Det behøver du kun hvis det sidder dårligt. Men husk at vaske hænder bagefter.`,
								en: `You only need that if you dosent wear it correctly. But remember to wash your hands afterwards.`,
								pl: `Potrzebujesz tego tylko wtedy, gdy nie nosisz jej prawidłowo. Pamiętaj jednak, aby później umyć ręce.`,
								de: `Das brauchst du nur zu tun, wenn du es nicht richtig trägst. Aber denke daran, dir danach die Hände zu waschen.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `At high five mine kollegaer`,
					en: `To high five my colleagues`,
					pl: `Przybiciu piątki kolegom`,
					de: `Meine Kollegen mit einem "High Five" zu begrüßen`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `At sige det hemmelige kodeord`,
					en: `Saying the secret password`,
					pl: `Wypowiadzeniu tajnego hasła`,
					de: `Das geheime Passwort zu nennen`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-b-instructions-procedures',
		background: 'slaughterhouse',
		text: {
			da: `Så er vi ude i produktionen. Her er der mange procedurer som skal overholdes. For eksempel hvordan vi fylder vogne, kar og bakker.`,
			en: `Now we are out in the production. Here there are many procedures to comply. For example, how we fill wagons, tubs and trays.`,
			pl: `Teraz jesteśmy na produkcji. Tutaj istnieje wiele procedur, których należy przestrzegać. Na przykład, jak napełniamy wózki, baseny i pojemniki.`,
			de: `Jetzt sind wir in der Produktion. Hier sind viele Verfahren einzuhalten. Zum Beispiel, wie wir Fleischkästen, Fleischwagen und Boxen befüllen.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-b-mc-filled-boxes',
		background: 'slaughterhouse',
		layout: 'repetition-1-b-filled-boxes',
		subtype: 'images',
		text: {
			da: `Hvilke billeder er korrekte?`,
			en: `Which pictures are correct?`,
			pl: `Które obrazki są prawidłowe?`,
			de: `Welche Bilder sind richtig?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-b-instructions-overfill',
		background: 'slaughterhouse',
		text: {
			da: `Hvis vi overfylder vogne, kar og bakker, så er der større chance for at kød ryger på gulvet. Men lad os fortsætte med emnet forurening.`,
			en: `If we overcrowd wagons, tubs and trays, there is a greater chance of meat falling to the floor. But let's continue with the topic of contamination.`,
			pl: `Jeśli przeładujemy wózki, baseny i pojemniki, istnieje większe prawdopodobieństwo, że mięso spadnie na podłogę. Ale kontynuujmy temat zanieczyszczenia.`,
			de: `Wenn wir Fleischkasten, Fleischkästen und Boxen überfüllen, besteht eine größere Wahrscheinlichkeit, dass Fleisch auf den Boden fällt. Aber machen wir weiter mit dem Thema Kontamination.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-b-instructions-contamination-types',
		background: 'slaughterhouse',
		text: {
			da: `Der er tre forskellige måder vores produkter kan blive forurenet på: fysisk, kemisk og mikrobiologisk. `,
			en: `There are three different ways our products can become contaminated: physical, chemical and microbiological.`,
			pl: `Istnieją trzy różne sposoby skażenia naszych produktów: fizyczne, chemiczne i mikrobiologiczne.`,
			de: `Unsere Produkte können auf drei verschiedene Arten kontaminiert werden: physikalisch, chemisch und mikrobiologisch.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-b-instructions-reaction',
		background: 'slaughterhouse',
		text: {
			da: `Hvordan du skal reagere på forurening afhænger af, hvilken type det er. Lad os kigge nærmere på det. `,
			en: `How you should respond to contamination depends on what type it is. Let's take a closer look.`,
			pl: `Sposób, w jaki należy reagować na zanieczyszczenie, zależy od tego, jakiego jest rodzaju. Przyjrzyjmy się temu bliżej.`,
			de: `Wie du auf eine Kontamination reagieren solltest, hängt davon ab, um welche Art es sich handelt. Lass uns das mal genauer anschauen.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-b-stuck-plastic',
		background: 'slaughterhouse',
		text: {
			da: `Du ser et stykke plast i noget kød på båndet. Hvad skal du gøre?`,
			en: `You see a piece of plastic in some meat on the production belt. What should you do?`,
			pl: `Widzisz kawałek plastiku w kawałku mięsa na taśmie produkcyjnej. Co powinieneś zrobić?`,
			de: `Du siehst ein Stück Plastik im Fleisch auf dem Produktionsband. Was sollte man tun?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Fjern plaststykket`,
					en: `Remove the plastic piece`,
					pl: `Usuń plastikowy element`,
					de: `Entferne das Plastikteil`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Tilkald mester`,
					en: `Call for the manager`,
					pl: `Wezwij kierownika`,
					de: `Rufe deinen Vorgesetzten`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Stands produktionen`,
					en: `Stop the production`,
					pl: `Zatrzymaj produkcję`,
					de: `Stoppe die Produktion`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Smid kødet til destruktion`,
					en: `Discard the meat for destruction`,
					pl: `Odrzuć mięso do zniszczenia`,
					de: `Enstorge das Fleisch zur Destruktion`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Smid kødet til dyrefoder`,
					en: `Discard the meat for animal feed`,
					pl: `Wyrzuć mięso na paszę dla zwierząt`,
					de: `Entsorge das Fleisch für Tierfutter`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-b-gloves-missing-finger',
		background: 'slaughterhouse',
		text: {
			da: `Du opdager at fingerspidsen mangler på en af dine plasthandsker. Hvad skal du gøre?`,
			en: `You discover that the fingertip is missing from one of your plastic gloves. What should you do?`,
			pl: `Odkrywasz, że w jednej z twoich plastikowych rękawiczek brakuje czubka palca. Co powinieneś zrobić?`,
			de: `Du stellst fest, dass an einem deiner Plastikhandschuhe die Fingerkuppe fehlt. Was sollte man tun?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Informere min mester med det samme`,
					en: `Inform the manager immediately`,
					pl: `Poinformuj natychmiast kierownika`,
					de: `Informiere sofort deinen Vorgsetzten`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fortsætte arbejdet. Jeg kan skifte handske i næste pause`,
					en: `Continue the work. I can change gloves in the next break`,
					pl: `Kontynuuj pracę. Możesz zmienić rękawiczki w następnej przerwie`,
					de: `Setze die Arbeit fort. Handschuhe kann man auch in der nächsten Pause wechseln`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Skifte handske, så jeg kan arbejde videre`,
					en: `Change gloves so I can continue working`,
					pl: `Zmień rękawiczki, abyś móc kontynuować pracę`,
					de: `Handschuhe wechseln, damit ich weiterarbeiten kann`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Jeg behøver slet ikke skifte handske`,
					en: `I don't need to change gloves at all`,
					pl: `Wcale nie musisz zmieniać rękawiczek`,
					de: `Handschuhe muss ich gar nicht wechseln`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-b-water-spray',
		background: 'slaughterhouse',
		text: {
			da: `Du skyller gulvet, men kommer til at sprøjte vand op på kødet. Hvorfor er det et problem?`,
			en: `You rinse the floor, but end up splashing water on the meat. Why is it a problem?`,
			pl: `Myjesz podłogę, ale w końcu chlapiesz wodą na mięso. Dlaczego to problem?`,
			de: `Du spülst den Boden, spritzt dabei aber versehentlich Wasser an das Fleisch. Warum ist das ein Problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Der kan komme bakterier fra gulvet op på kødet`,
					en: `Bacteria can get from the floor onto the meat`,
					pl: `Bakterie mogą dostać się z podłogi na mięso`,
					de: `Bakterien können vom Boden auf das Fleisch gelangen`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Kød kan ikke tåle vand`,
					en: `Meat cannot tolerate water`,
					pl: `Mięso nie toleruje wody`,
					de: `Fleisch verträgt kein Wasser`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det er ikke et problem`,
					en: `It is not a problem`,
					pl: `To nie jest problem`,
					de: `Das ist kein Problem`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-b-water-spray2',
		background: 'slaughterhouse',
		text: {
			da: `Hvad skal der ske med det kød, du er kommet til at sprøjte vand op på?`,
			en: `What should happen to the meat you have splashed water on?`,
			pl: `Co powinno się stać z mięsem, które ochlapano wodą?`,
			de: `Was musst du mit dem Fleisch machen, auf das du versehentlich Wasser gespritzt hast?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Jeg skal vaske det`,
					en: `I have to wash it`,
					pl: `Muszę to umyć`,
					de: `Ich muss es abwaschen`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det skal til reinspektion`,
					en: `It needs reinspection`,
					pl: `Wymaga ponownej kontroli`,
					de: `Es bedarf einer erneuten Inspektion`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det skal til destruktion`,
					en: `It must be destroyed`,
					pl: `Musi zostać zniszczony`,
					de: `Es muss entsorgt werden`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Der skal ikke ske noget`,
					en: `Nothing should happen`,
					pl: `Nic nie powinno się wydarzyć`,
					de: `Nichts muss passieren`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-b-bottle',
		background: 'slaughterhouse',
		text: {
			da: `Du ser en flaske med rengøringsmiddel stå i produktionen. Hvad gør du?`,
			en: `You see a bottle of cleaning agent in production. What do you do?`,
			pl: `Widzisz na produkcji butelkę środka czyszczącego. Co robisz?`,
			de: `Du siehst eine Flasche Reinigungsmittel in der Produktion. Wie machst du?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Lader det stå`,
					en: `Let it be`,
					pl: `Pozostaw to, niech tak będzie`,
					de: `Kümmere dich nicht darum`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fjerner det og tilkalder så mester`,
					en: `Removes it and then call for the manager`,
					pl: `Usuń to, a następnie wezwij kierownika`,
					de: `Entferne sie und rufe dann deinen Vorgesetzten`
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Godt! Vi skal sikre os at der ikke er kommet noget i produkterne.`,
								en: `Good! We have to make sure that nothing has gotten into the products.`,
								pl: `Dobrze! Musimy mieć pewność, że nic nie dostało się do produktów.`,
								de: `Gut! Wir müssen sicherstellen, dass nichts in die Produkte gelangt ist.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Tilkalder mester`,
					en: `Call for the manager`,
					pl: `Wezwij kierownika`,
					de: `Rufe deinen Vorgesetzten`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Altid godt at spørge, men husk også at fjerne flasken.`,
								en: `Always good to ask, but also remember to remove the bottle.`,
								pl: `Zawsze dobrze jest zapytać, ale pamiętaj też o wyjęciu butelki.`,
								de: `Es ist immer gut zu fragen, aber denke auch daran, die Flasche weg zu bringen`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Fjerner det`,
					en: `Remove it`,
					pl: `Usuń to`,
					de: `Bringe die Flasche weg`
				},
			}),
		]
	}),
	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-b-instructions-ask-master',
		background: 'slaughterhouse',
		text: {
			da: `Hvis du er i tvivl om hvorvidt noget er blevet forurenet, så spørg din mester.`,
			en: `If you are in doubt as to whether something has been contaminated, ask your manager.`,
			pl: `Jeśli masz wątpliwości, czy coś zostało skażone, zapytaj swojego przełożonego.`,
			de: `Wenn du Zweifel hast, ob etwas kontaminiert wurde, frage deinen Vorgesetzten.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-b-mc-floor-meat',
		background: 'slaughterhouse',
		text: {
			da: `Hvorfor er det vigtigt at kød der har været på gulvet, ikke kommer op på båndet igen?`,
			en: `Why is it important that meat that has been on the floor does not come up on the production line again?`,
			pl: `Dlaczego ważne jest, aby mięso, które leżało na podłodze, nie trafiło ponownie na linię produkcyjną?`,
			de: `Warum ist es wichtig, dass Fleisch, das auf dem Boden lag, nicht wieder auf die Produktionslinie kommt?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Det kan forurene de efterfølgende produkter`,
					en: `It can contaminate the following products`,
					pl: `Może zanieczyścić następujące produkty`,
					de: `Es kann die folgenden Produkte kontaminieren`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det er ligegyldigt, skinken må gerne komme tilbage på båndet`,
					en: `It doesn't matter, the meat is welcome back on the belt`,
					pl: `To nie ma znaczenia, mięso jest mile widziane z powrotem na taśmie prodykcyjnej`,
					de: `Es ist egal, wenn das Fleisch zurück auf das Band kommt`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Der er risiko for at forbrugerne kan blive syge`,
					en: `There is a risk that consumers may become ill`,
					pl: `Istnieje ryzyko, że konsumenci mogą zachorować`,
					de: `Es besteht die Gefahr, dass Verbraucher krank werden`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Kødet er blevet sejt og kan ikke bruges`,
					en: `The meat has become tough and cannot be used`,
					pl: `Mięso stało się twarde i nie nadaje się do użycia`,
					de: `Das Fleisch ist zäh geworden und kann nicht verwendet werden`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-b-instructions-almost-done',
		background: 'slaughterhouse',
		text: {
			da: `Du er nu næsten igennem modulet.`,
			en: `You are now almost through the module.`,
			pl: `Moduł jest już prawie ukończony.`,
			de: `Du hast das Modul jetzt fast abgeschlossen.`
		}
	}),
	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-1-b-why-hygiene',
		background: 'slaughterhouse',
		shuffleOptions: false,
		text: {
			da: `Hvorfor er det vi skal være ekstra opmærksomme på hygiejne her på slagteriet?`,
			en: `Why do we have to pay extra attention to hygiene here at the slaughterhouse?`,
			pl: `Dlaczego musimy zwracać szczególną uwagę na higienę w rzeźni?`,
			de: `Warum müssen wir im Schlachthof besonders auf Hygiene achten?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `På grund af lovkrav`,
					en: `Due to legal requirements`,
					pl: `Ze względu na wymogi prawne`,
					de: `Aufgrund gesetzlicher Vorgaben`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`,
					en: `Because poor hygiene can make people sick`,
					pl: `Ponieważ zła higiena może powodować choroby`,
					de: `Schlechte Hygiene kann krank machen`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`,
					pl: `Aby zapewnić wysoką jakość`,
					de: `Um eine hohe Qualität zu gewährleisten`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all the above reasons`,
					pl: `Ze wszystkich powyższych powodów`,
					de: `Aus all den oben genannten Gründen`
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-1-b-instructions-finish',
		background: 'slaughterhouse',
		text: {
			da: `Godt gået! 
				<br /><br />Du er nu igennem anden og sidste del af gentræningen. Hvis du har fået tre stjerner, er du færdig med uddannelsen for denne gang. Ellers må du tage modulet en gang til.`,
			en: `Well done!
			<br /><br />You are now through the second and final part of the retraining. If you have received three stars, you have completed the training for this time. Otherwise, you must take the module again.`,
			pl: `Dobrze zrobione!
			<br /><br />Jesteś teraz w drugiej i ostatniej części szkolenia przypominającego. Jeśli otrzymałeś/aś trzy gwiazdki, ukończyłeś/aś szkolenie na ten moment. W przeciwnym razie musisz ponownie przejść moduł.`,
			de: `Gut gemacht!
			<br /><br />Du hast jetzt den zweiten und letzten Teil der Schulung hinter dich gebracht. Wenn du drei Sterne erhalten hast, hast du dieses Modul abgeschlossen. Andernfalls musst du das Modul wiederholen.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'repetition-1-b' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};