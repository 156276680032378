const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-instructions-welcome',
		background: 'hygiene-lock',
		text: {
			da: `Velkommen til gentræning 1B.
				<br /><br />Nu skal vi ind i selve produktionen. Husk du skal have tre stjerner for at gennemføre.`,
			en: `Welcome to retraining 1B.
				<br /><br />Now we'll go into the actual production. Remember you need three stars to complete.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-mc-remember-hygiene-lock',
		background: 'hygiene-lock',
		text: {
			da: `Hvad skal du huske her inden du går ind i produktionen?`,
			en: `What should you remember here before entering the production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `At vaske hænder`,
					en: `Wash hands`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `At rette på mit hårnet`,
					en: `Fixing my hairnet`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Det behøver du kun hvis det sidder dårligt. Men husk at vaske hænder bagefter.`,
								en: `You only need that if you dosent wear it correctly. But remember to wash your hands afterwards.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `At high five mine kollegaer`,
					en: `To high five my colleagues`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `At sige det hemmelige kodeord`,
					en: `Saying the secret password`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-order-wash-hands',
		background: 'hygiene-lock',
		text: {
			da: `I hygiejnesluser skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `In hygiene sluices, you must always wash your hands. What is the right order to do things in?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Vask hænder med sæbe og vand`,
					en: `Wash hands with soap and water`
				}, 
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Tør hænder med engangshåndklæde`,
					en: `Dry hands with a disposable towel`
				}, 
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Smid engangshåndklæde i skraldespand`,
					en: `Throw the disposable towel in the trash`
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Brug håndsprit`,
					en: `Use hand sanitizer`
				}, 
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-instructions-many-procedures',
		text: {
			da: `Så er vi inde i produktionen. Her er der mange procedurer som skal overholdes. For eksempel hvordan vi fylder vogne, kar og bakker korrekt.`,
			en: `Now we are in the production. Here there are many procedures to comply. For example, how we fill wagons, tubs and trays corectly.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-mc-trays-carts',
		layout: 'production-2-trays-carts',
		subtype: 'images',
		text: {
			da: `Hvilke vogne, kar og bakker er fyldt korrekt?`,
			en: `Which carts, tubs and trays are filled correctly?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Vogn der er overfyldt
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Bakke der er fyldt til under kanten
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Bakke der er fyldt med top
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Vogn fyldt lige til kanten
				isCorrect: true
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-instructions-contamination-types',
		text: {
			da: `Der er tre forskellige måder vores produkter kan blive forurenet på: fysisk, kemisk og mikrobiologisk.`,
			en: `There are three different ways our products can become contaminated: physical, chemical and microbiological.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-instructions-contamination-reactions',
		text: {
			da: `Hvordan du skal reagere på forurening afhænger af, hvilken type det er. Lad os kigge nærmere på det.`,
			en: `How you should respond to contamination depends on what type it is. Let's take a closer look at it.`
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-order-broken-lamp',
		text: {
			da: `En lampe går i stykker i produktionen. Der er risiko for, der er kommet plastik i produkterne. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge.`,
			en: `A lamp breaks in the production. There is a risk that plastic has gotten into the products. What should you do? Put the actions below in the correct order.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Stop produktionen`,
					en: `Stop the production`
				}, 
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Tilkald mester`,
					en: `Call for a manager`
				}, 
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Afvent frigivelse fra mester`,
					en: `Awaiting release from a manager`
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Fortsæt arbejdet`,
					en: `Continue working`
				}, 
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-mc-glove-hole',
		text: {
			da: `Du opdager at fingerspidsen mangler på en af dine plasthandsker. Hvad skal du gøre?`,
			en: `You discover that the fingertip is missing from one of your plastic gloves. What should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Informere min mester med det samme`,
					en: `Inform the manager immediately`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fortsætte arbejdet. Jeg kan skifte handske i næste pause`,
					en: `Continue the work. I can change gloves in the next break`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Skifte handske, så jeg kan arbejde videre`,
					en: `Change gloves so I can continue working`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Jeg behøver slet ikke skifte handske`,
					en: `I don't need to change gloves at all`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-spot-errors-production-hall',
		layout: 'repetition-2-1b-production-hall',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK når du mener, du har dem alle.`,
			en: `Press the 4 errors. Press OK when you think you have them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'drop', // Dråbe hænger ned fra rør over produktionsbånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'lamp', // En lampe lyser ikke og har hul
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'screw', // Skrue eller møtrik på båndet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hammer', // Hammer på gulvet
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-mc-dripping',
		text: {
			da: `Hvis der drypper fx kondensvand ned fra rør, så er det problematisk. Hvorfor?`,
			en: `If, for example, condensation drips down from pipes, it is problematic. Why?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Det kan forurene de efterfølgende produkter`,
					en: `It can contaminate the following products`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Produkterne må gerne blive på båndet`,
					en: `The products may remain on the belt`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Der er risiko for at forbrugerne kan blive syge`,
					en: `There is a risk that consumers may become ill`
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-mc-can-cleaning-agent',
		text: {
			da: `Du ser en dunk med rengøringsmiddel stå på båndet. Hvad gør du?`,
			en: `You see a bottle of cleaning agent on the belt. What do you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Lader det stå`,
					en: `Let it be`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fjerner det og tilkalder så mester`,
					en: `Removes it and then call for the manager`
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Godt! Vi skal sikre os at vores produkter ikke bliver forurenet.`,
								en: `Good! We have to make sure that nothing has gotten into the products.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Tilkalder mester`,
					en: `Call for the mamager`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Altid godt at spørge, men husk også at fjerne flasken.`,
								en: `Always good to ask, but also remember to remove the bottle.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Fjerner det`,
					en: `Remove it`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-instructions-ask-manager',
		text: {
			da: `Hvis du er i tvivl om hvorvidt noget er blevet kemisk forurenet, så spørg din mester.`,
			en: `If you are in doubt as to whether something has been chemically contaminated, ask your manager.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-mc-allergens',
		layout: 'repetition-2-1b-allergens',
		text: {
			da: `Forurening kan være et problem for folk med allergi. Klik på to allergener.`,
			en: `Contamination can be a problem for people with allergies. Click on two allergens.`
		},
		subtype: 'images',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, //Peanuts
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Hvedemel
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, //Jordbær
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // pineapple
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // apple
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-mc-contamination-problem-why',
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: `Our products can become contaminated in various ways. Why is contamination a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Det kan være farligt`,
					en: `It can be dangerous`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det kan ødelægge smagen`,
					en: `It can spoil the taste`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `This can result in dissatisfied customers`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Det kan give ekstra CO2-udledning`,
					en: `It cost extra CO2-emission`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Det er faktisk ikke et stort problem`,
					en: `It is acually not a big problem`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-instructions-spot-problems',
		text: {
			da: `Hygiejne er ekstremt vigtigt i produktionen. Og fordi du er tæt på produktet, er du den første der kan lægge mærke til problemer.`,
			en: `Hygiene is extremely important in the production. And because you are close to the product, you are the first to notice problems.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-mc-golden-rule',
		text: {
			da: `Hvad er den gyldne regel, hvis du spotter noget, som måske er et problem?`,
			en: `What is the golden rule if you spot something that might be a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Stands produktionen`,
					en: `Stop the production`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Skriv en note`,
					en: `Write a note`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Smid alt kød i nærheden af problemet ud`,
					en: `Throw out any meat that is nearby`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Spørg mester`,
					en: `Ask a manager`
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Lige præcis! Hvis du er i tvivl, så skal du bare spørge mester.`,
								en: `Exactly! If in doubt, just ask the manager.`
							}
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-instructions-almost-done',
		text: {
			da: `Du er næsten igennem modulet.`,
			en: `You are almost through the module.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-mc-extra-attention',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne i produktionen?`,
			en: `Why should we pay extra attention to hygiene in the production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `På grund af lovkrav`,
					en: `Due to legal requirements`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`,
					en: `Because poor hygiene can make people sick`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all the above reasons`
				},
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1b-instructions-finish',
		text: {
			da: `Godt gået! 
				<br /><br />Du er nu igennem gentræningen. Hvis du er i tvivl om noget, så spørg kursuslederen eller din mester. 
				<br /><br />God arbejdslyst derude!`,
			en: `Well done!
				<br /><br />You are now through retraining. If you are in doubt about something, ask the course leader or your manager.
				<br /><br />Have a nice day.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'repetition-2-1b-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};