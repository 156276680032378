const moduleRepetition1A = [
	'modules/tasks/multiple-choice/repetition-1-a-hands/background.svg',
	'modules/tasks/multiple-choice/repetition-1-a-lunch/option-1.svg',
	'modules/tasks/multiple-choice/repetition-1-a-lunch/option-2.svg',
	'modules/tasks/multiple-choice/repetition-1-a-lunch/option-3.svg',
	'modules/tasks/multiple-choice/repetition-1-a-lunch/option-4.svg',
	'modules/tasks/multiple-choice/repetition-1-a-lunch/option-5.svg',
	'modules/tasks/multiple-choice/repetition-1-a-lunch/option-6.svg',
	'modules/tasks/multiple-choice/repetition-1-a-neither-clothes/option-1.svg',
	'modules/tasks/multiple-choice/repetition-1-a-neither-clothes/option-2.svg',
	'modules/tasks/multiple-choice/repetition-1-a-neither-clothes/option-3.svg',
	'modules/tasks/multiple-choice/repetition-1-a-neither-clothes/option-4.svg',
	'modules/tasks/multiple-choice/repetition-1-a-private-clothes/option-1.svg',
	'modules/tasks/multiple-choice/repetition-1-a-private-clothes/option-2.svg',
	'modules/tasks/multiple-choice/repetition-1-a-private-clothes/option-3.svg',
	'modules/tasks/multiple-choice/repetition-1-a-private-clothes/option-4.svg',
	'modules/tasks/multiple-choice/repetition-1-a-work-clothes/option-1.svg',
	'modules/tasks/multiple-choice/repetition-1-a-work-clothes/option-2.svg',
	'modules/tasks/multiple-choice/repetition-1-a-work-clothes/option-3.svg',
	'modules/tasks/multiple-choice/repetition-1-a-work-clothes/option-4.svg',

	'modules/tasks/spot-errors/repetition-1-a-dressing-mistakes/background.svg',
];

export {
	moduleRepetition1A
};