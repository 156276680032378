import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import {paperDollPoints} from 'data/points-data';
import {checkIfConditionsAreFulfilled} from 'helpers/effect-helper';
import { gameUiTexts } from 'data/ui-texts/game-ui-texts';
import { getText } from 'helpers/language-helper';
import TaskIntro from '../task-intro/task-intro';
import { playerUiTexts } from 'data/ui-texts/player-ui-texts';
import './paper-doll.scss';

const PaperDoll = (props) => {
	const {
		languageId,
		playerTaskData, 
		taskData,
		moduleData,
		updateLoggedTime,
		handleInstantTaskEffects, 
		handleCompleteTask,
	} = props;

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Track status and selected items */
	const [selectedItemIds, setSelectedItemIds] = useState([]);
	const [selectEffectsSeen, setSelectEffectsSeen] = useState([]);
	const [deselectEffectsSeen, setDeselectEffectsSeen] = useState([]);

	/**
	 * Get selected item ids
	 * @returns {array} itemIds
	 */
	const getSelectedItemIds = () => {
		let itemIds = [];
		if (playerTaskData && playerTaskData.selectedItemIds) {
			itemIds = [...playerTaskData.selectedItemIds];
		}
		return itemIds;
	};

	/* New task: update status, spotted errors, and countdown  */
	useEffect(() => {
		setSelectedItemIds(getSelectedItemIds());
		setSelectEffectsSeen([]);
		setDeselectEffectsSeen([]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Check if an item is required
	 * @param {object} item 
	 * @returns 
	 */
	const checkIfItemIsRequired = (item) => {
		let itemIsRequired = false;
		if (item.isCorrect) {
			itemIsRequired = true;
		}
		return itemIsRequired;
	};

	/**
	 * Toggle item
	 * @param {string} itemId 
	 * @returns 
	 */
	const toggleItem = (itemId) => {
		/* Already completed */
		if (isCompleted) return;

		/* Update logged time */
		updateLoggedTime();

		/* Toggle item id */
		let newSelectedItemIds = [...selectedItemIds];
		let newSelectEffectsSeen = [...selectEffectsSeen];
		let newDeselectEffectsSeen = [...deselectEffectsSeen];
		const itemData = taskData.items.find((i) => {return i.id === itemId;});
		const itemIndex = newSelectedItemIds.indexOf(itemId);
		let effects = [];
		if (itemIndex < 0) {
			/* Select item */
			newSelectedItemIds.push(itemId);
			if (itemData.selectEffects.length > 0 && selectEffectsSeen.indexOf(itemId) < 0) {
				effects = itemData.selectEffects;
				newSelectEffectsSeen.push(itemId);
			}
		} else {
			/* De-select item */
			newSelectedItemIds.splice(itemIndex, 1);
			if (itemData.deselectEffects.length > 0 && deselectEffectsSeen.indexOf(itemId) < 0) {
				effects = itemData.deselectEffects;
				newDeselectEffectsSeen.push(itemId);
			}
		}
		setSelectedItemIds(newSelectedItemIds);
		setSelectEffectsSeen(newSelectEffectsSeen);
		setDeselectEffectsSeen(newDeselectEffectsSeen);
		
		/* Effects */
		if (effects.length > 0) handleInstantTaskEffects(effects);		
	};

	/**
	 * Complete task
	 */
	const completeTask = () => {		
		/* Errors */
		let numberOfCorrectItems = 0;
		let missedItems = [];
		let excessItems = [];
		taskData.items.forEach((item) => {
			const itemIsRequired = checkIfItemIsRequired(item);
			if (itemIsRequired) {
				numberOfCorrectItems += 1;
				if (selectedItemIds.indexOf(item.id) < 0) missedItems.push(getText(item.title, languageId));
			} else {
				if (selectedItemIds.indexOf(item.id) >= 0) excessItems.push(getText(item.title, languageId));
			}
		});
		const errors = missedItems.length + excessItems.length;

		/* Calculate points */
		let points = paperDollPoints.minPoints;
		let pointIndex = paperDollPoints.pointLimits.findIndex((limit) => {return errors <= limit;});
		if (pointIndex >= 0) points = paperDollPoints.pointValues[pointIndex];

		/* Result popup */
		let popupText = getText(gameUiTexts.paperDollPopup.text1, languageId);
		if (missedItems.length > 0) {
			popupText = getText(gameUiTexts.paperDollPopup.text2, languageId);
			popupText = popupText.replace('%correctItems%', numberOfCorrectItems - missedItems.length);
			missedItems.forEach((item, index) => {
				popupText += item + (index < missedItems.length - 1 ? ', ' : '.');
			});	
		}
		if (excessItems.length > 0) {
			popupText += '<br /><br />' + getText(gameUiTexts.paperDollPopup.notNeeded, languageId);
			excessItems.forEach((item, index) => {
				popupText += item + (index < excessItems.length - 1 ? ', ' : '.');
			});	
		}

		/* Effects */
		let effects = [
			{
				type: 'popup',
				popup: {
					type: 'paper-doll-result',
					text: popupText
				}
			},
			{
				type: 'streak',
				isCorrectAnswer: (missedItems.length === 0 && excessItems.length === 0)
			}
		];
		if (taskData.doneEffects && taskData.doneEffects.length > 0) {
			taskData.doneEffects.forEach((effect) => {
				const conditionsAreFulfilled = checkIfConditionsAreFulfilled(effect, errors);
				if (conditionsAreFulfilled) {
					if (effect.type === 'points') {
						/* Effect: points */
						points += effect.value;
					} else {
						/* Effect: feedback, popup, special points, new avatar item */
						effects.push(effect);
					}
				}
			});
		}
	
		/* Save completed task */
		handleCompleteTask(
			'paper-doll', 
			points, 
			errors, 
			effects,
			{selectedItemIds: selectedItemIds}
		);
	};

	return (
		<div className={'PaperDoll ' + taskData.taskId + (isCompleted ? ' completed' : '')}>
			<div id="taskIntro" className="PaperDoll-intro">
				<TaskIntro 
					languageId={languageId}
					moduleId={moduleData.id}
					showNumberOfAnswersToSelect={taskData.showNumberOfAnswersToSelect}
					text={taskData.text ? getText(taskData.text, languageId) : null}
					image={taskData.image}
					audioData={{taskType: taskData.type, taskId: taskData.taskId}}
				/>

				{/* Available items */}
				<div className="PaperDoll-panel">
					<div className="PaperDoll-availableItems">
						{taskData.items.map((item) => {
							let image = null;
							try {
								image = require('assets/images/modules/tasks/paper-doll/' + item.id + '-a.svg');
							} catch (e) {
								console.error('image not found: ' + item.id);
							}
	
							return (
								<div 
									key={item.id} 
									className={'PaperDoll-item ' + item.id 
										+ (selectedItemIds.indexOf(item.id) >= 0 ? ' selected' : '')}
									onClick={() => {toggleItem(item.id);}} 
								>
									{image && <ReactSVG src={image}/>}
								</div>
							);
						})}
					</div>
				</div>
			</div>

			{/* Selected items in place */}
			<div className="PaperDoll-selectedItems">
				{taskData.items.map((itemData, index) => {
					const itemIsRequired = checkIfItemIsRequired(itemData);
					if (isCompleted && !itemIsRequired) return null;
					if (!isCompleted && selectedItemIds.indexOf(itemData.id) < 0) return null;
					let image = null;
					let zIndex = 1;
					zIndex = (index + 1);
					let suffix = 'a';
					if (itemData.type === 'alternative') suffix = 'b';
					try {
						image = require('assets/images/modules/tasks/paper-doll/' 
							+ itemData.id + '-' + suffix + '.svg');
					} catch (e) {
						console.error('image not found: ' + itemData.id);
					}

					return (
						<div 
							key={itemData.id}
							style={{zIndex: zIndex}}
							className={
								'PaperDoll-item ' + itemData.id
							}
							onClick={() => {if (!isCompleted) toggleItem(itemData.id);}} 
						>{image && <ReactSVG src={image}/>}</div>
					);
				})}
			</div>
			
			{/* Done button */}
			{!isCompleted && 
				<div onClick={(e) => {e.stopPropagation(); completeTask();}} className="PaperDoll-confirmBtn">
					<span>{getText(playerUiTexts.ok, languageId)}</span>
				</div>
			}
		</div>
	);
};

PaperDoll.propTypes = {
	languageId: PropTypes.string.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	moduleData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
};

export default PaperDoll;
