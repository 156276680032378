import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {checkIfValidUserId} from 'helpers/login-helper';
import apiHelper from 'helpers/api-helper';
import {facilitatorUiTexts} from 'data/ui-texts/facilitator-ui-texts';
import {errorUiTexts} from 'data/ui-texts/error-ui-texts';
import Button from 'components/ui/button/button';
import './rename-player-popup.scss';

const RenamePlayerPopup = ({languageId, playerData, closePopup, togglePlayerPopup}) => {
	/* Loading, feedback */
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [successMsg, setSuccessMsg] = useState(null);
	
	/* Input new employee id */
	const [employeeId, setEmployeeId] = useState('');
	const updateEmployeeId = (e) => {
		setEmployeeId(e.target.value);
		setErrorMsg(null);
		setSuccessMsg(null);
	};

	/**
	 * Confirm new player id
	 * @returns 
	 */
	const handleRenamePlayer = () => {
		if (isLoading) return;

		setIsLoading(true);
		setErrorMsg(null);
		setSuccessMsg(null);

		const {isValid, feedback} = checkIfValidUserId(employeeId);

		if (!isValid) {
			setErrorMsg(getText(facilitatorUiTexts.renamePlayerPopup[feedback], languageId));
			setIsLoading(false);
		} else {
			renamePlayer().then((response) => {
				if (response.status === 'success') {
					togglePlayerPopup();
				} else {
					if (response.error === 'newIdAlreadyInUse') {
						setErrorMsg(getText(facilitatorUiTexts.renamePlayerPopup.idAlreadyInUse, languageId));
					} else {
						setErrorMsg(getText(errorUiTexts.unknownError, languageId));
						console.error(response.error);
					}
					setIsLoading(false);
				}				
			});
		}
	};

	/* Rename player */
	const renamePlayer = () => {
		return new Promise((resolve) => {
			apiHelper('facilitator/rename-player', {
				employeeId: playerData.id,
				newEmployeeId: employeeId
			}).then(
				(response) => {
					// Success
					if (response.status === 'success') {
						resolve({status: 'success'});
					} else {
						console.error(response);
						resolve({status: 'error', error: response.error});
					}
				},
				(rejection) => {
					// Error
					console.error(rejection);
					resolve({status: 'error', error: rejection});
				}
			);
		});
	};

	return (
		<div className="RenamePlayerPopup" onClick={() => {closePopup();}}>
			<div 
				className="RenamePlayerPopup-content" 
				onClick={(e) => {e.stopPropagation();}}
			>
				<div className="RenamePlayerPopup-contentWrap">
					<div className="RenamePlayerPopup-title">
						<span>{getText(facilitatorUiTexts.renamePlayerPopup.title, languageId)}</span>
					</div>

					<div className="RenamePlayerPopup-text">
						<div className="RenamePlayerPopup-currentEmployeeId">
							<span>{getText(facilitatorUiTexts.renamePlayerPopup.currentEmployeeId, languageId)}:</span>
							<span>{playerData ? playerData.id : '-'}</span>
						</div>

						<div className="RenamePlayerPopup-input">
							<span>{getText(facilitatorUiTexts.renamePlayerPopup.newEmployeeId, languageId)}:</span>
							<input 
								type="text"
								name="email"
								placeholder=""
								value={employeeId ? employeeId : ''}
								onChange={(e) => {updateEmployeeId(e);}}
							/>
						</div>
						<div className={'RenamePlayerPopup-feedback' 
						+ (errorMsg ? ' error' : '') + (successMsg ? ' success' : '')}
						>
							{errorMsg && <span>{errorMsg}</span>}
							{successMsg && <span>{successMsg}</span>}
						</div>
						<div className="RenamePlayerPopup-confirmBtn">
							<Button 
								isLoading={isLoading}
								classes={['facilitator']}
								text={getText(facilitatorUiTexts.renamePlayerPopup.okBtn, languageId)}
								onClick={() => {handleRenamePlayer();}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

RenamePlayerPopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	playerData: PropTypes.object,
	closePopup: PropTypes.func.isRequired,
	togglePlayerPopup: PropTypes.func.isRequired,
};

export default RenamePlayerPopup;
