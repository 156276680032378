import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {getGameUrl} from 'helpers/game-helper';
import LoginFacilitatorController from './login-facilitator-controller';
import ResetPassword from './reset-password';
import CreateUserController from './create-user-controller';
import LoginPlayerController from './login-player-controller';
import Logo from 'components/ui/logo/logo';
import DcBackground from 'components/ui/dc-background/dc-background';
import CookieConsent from 'components/ui/cookie-consent/cookie-consent';
import './login.scss';

const Login = ({languageId}) => {
	/* Get active box component for facilitator box */
	let [facilitatorComponentType, setFacilitatorComponentType] = useState('login');
	
	let LoginComponent = LoginPlayerController;

	/* Get game url */
	const gameUrl = getGameUrl(window.location.pathname);
	if (gameUrl === appConfig.faclitatorSectionUri) {
		LoginComponent = LoginFacilitatorController;
		if (facilitatorComponentType === 'createUser') LoginComponent = CreateUserController;
		if (facilitatorComponentType === 'resetPassword') LoginComponent = ResetPassword;
	}

	const [scenarioId, setScenarioId] = useState('scenario1');

	// Get scenarioData on component init
	useEffect(() => {
		getScenarioData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Gets scenarioId from 
	 * @returns promise
	 */
	const getScenarioData = () => {
		const db = firebase.firestore();
		return new Promise((resolve) => {
			db.collection(appConfig.gamesDbName).where('url', '==', gameUrl).get().then((snapshot) => {
				snapshot.forEach((doc) => {
					if (doc.exists) {
						/* Save game scenario state */
						setScenarioId(doc.data().scenarioId ? doc.data().scenarioId : 'scenario1');
					} else {
						/* Error: No game data */
						resolve({status: 'error', error: 'no-game-data'});
					}
				});
			},
			(error) => {
				/* Error: Could not get game data */
				console.error('Could not get game data: ', error);
				resolve({status: 'error', error: error});
			}
			);
		});
	};

	return (
		<div className="Login">
			<DcBackground scenarioID={scenarioId}/>
			<div className="Login-logoCGL">
				<Logo type="cgl"/>
			</div>
			<div className="Login-logoAndTitle">
				<div className="Login-logo"><Logo /></div>
				<div className="Login-title"><span>{appConfig.name}</span></div>
			</div>
			<LoginComponent 
				languageId={languageId} 
				setFacilitatorComponentType={setFacilitatorComponentType} 
			/>
			<CookieConsent languageId={languageId} page="login" />
		</div>
	);
};

Login.propTypes = {
	languageId: PropTypes.string.isRequired
};

export default Login;