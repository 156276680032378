import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import './character.scss';
import {ReactComponent as Manager1} from '../../../assets/images/characters/manager-1-blinking.svg';

const Character = ({page, characterId}) => {
	let blinkTimeout = useRef(null);
	let timeout = useRef(null);

	const minBlinkTime = 500;
	const maxBlinkTime = 2000;

	const [isBlinking, setIsBlinking] = useState(false);
	const [Manager, setManager] = useState(Manager1); // Default value for manager

	const blink = () => {
		let currentBlink = false;
		var randomInterval = Math.round(Math.random() * (maxBlinkTime - minBlinkTime)) + minBlinkTime;
		blinkTimeout.current = setTimeout(() => {
			// Blink
			currentBlink = !currentBlink;
			setIsBlinking(currentBlink);

			timeout.current = setTimeout(() => {
				// Unblink
				currentBlink = !currentBlink;
				setIsBlinking(currentBlink);
				clearTimeout(timeout.current);
				
				// repeat loop with new random
				blink();
			}, 100);
		}, randomInterval);
	};

	useEffect(() => {
		if (characterId === 'manager-1') {
			setManager(Manager1);

			blink();
		}

		return () => {
			/* Component will unmount */
			if (blinkTimeout.current) clearTimeout(blinkTimeout.current);
			if (timeout.current) clearTimeout(timeout.current);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='Character'>
			<Manager className={'Character-manager ' + page + ' ' + characterId + (isBlinking ? ' blinking' : '')}/>
		</div>
	);
};

Character.propTypes = {
	page: PropTypes.string.isRequired,
	characterId: PropTypes.string.isRequired,
};

export default Character;
