/* Stars */
const starsPerPercentPoints = {
	pointSteps: [0, 15, 50, 85],
	filledStars: [0, 1, 2, 3]
};

/* Game engines */
const multipleChoicePoints = {
	basePoints: 5,
	minusPointsPerWrongAnswers: 1,
	minimumPoints: 1
};

const paperDollPoints = {
	minPoints: 1,
	pointLimits: [0, 1, 3, 5],
	pointValues: [8, 6, 4, 2]
};

const orderPoints = {
	minPoints: 1,
	pointLimits: [0, 1, 3, 5],
	pointValues: [8, 6, 4, 2]
}

const organizePoints = {
	minPoints: 1,
	pointLimits: [0, 1, 3, 5],
	pointValues: [8, 6, 4, 2]
}

const sortPoints = {
	minPoints: 1,
	pointLimits: [0, 1, 3, 5],
	pointValues: [8, 6, 4, 2]
};

const spotErrorsMinusPoints = {
	minPoints: 1,
	errorLimits: [5, 3, 1],
	pointValues: [3, 2, 1]
}

export {
	starsPerPercentPoints,
	multipleChoicePoints,
	orderPoints,
	organizePoints,
	sortPoints,
	spotErrorsMinusPoints,
	paperDollPoints
};