import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {scenariosData} from 'data/scenarios-data';

export function getAllModulesData() {
	const modulesData = [];
	scenariosData.forEach((scenarioData) => {
		scenarioData.modulesData.forEach((moduleData) => {
			modulesData.push(moduleData);
		});
	});

	return modulesData;
}

/**
 * Create new module in player modules array
 * @param {string} moduleId 
 * @returns 
 */
export function createNewPlayerModule(moduleId) {
	const modulesData = getAllModulesData();
	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	const taskId = (moduleData.tasks && moduleData.tasks.length > 0 ? moduleData.tasks[0].id : null);
	const sessionTemplate = {
		isCompleted: false,
		started: dayjs(new Date()).format('YYYY-MM-DD'),
		currentTaskId: taskId,
		points: 0,
		tasks: []
	};
	const playerModule = {
		moduleId: moduleId,
		maxStars: 0,
		maxPoints: 0,
		completedDate: null,
		sessions: [sessionTemplate],
	};
	return playerModule;		
}

/**
 * Get player all modules data
 * @param {object} playerGameData 
 * @returns {array}
 */
export function getPlayerModulesData(playerData) {
	let playerModulesData = (playerData && playerData.modules 
		? [...playerData.modules]
		: []
	);
	return playerModulesData;
}

/**
 * Get index of player module data
 * @param {string} moduleId
 * @param {object} playerGameData 
 * @returns {number} moduleIndex
 */
export function getPlayerModuleIndex(playerData, moduleId) {
	const playerModulesData = getPlayerModulesData(playerData);
	const moduleIndex = playerModulesData.findIndex((m) => {return m.moduleId === moduleId;});
	return moduleIndex;
}

/**
 * Get player module data
 * @param {string} moduleId
 * @param {object} playerGameData 
 * @returns {object || null}
 */
export function getPlayerModuleData(moduleId, playerData) {
	const playerModulesData = getPlayerModulesData(playerData);
	const playerModuleData = (playerModulesData.some((m) => {return m.moduleId === moduleId;})
		? playerModulesData.find((m) => {return m.moduleId === moduleId;})
		: null
	);
	return playerModuleData;
}

/**
 * Check if module is disabled (happens if they fail it 3 times in a row)
 */
export function checkIfModuleIsDisabled(playerModuleData) {
	let isDisabled = false;
	const today = dayjs(new Date()).format('YYYY-MM-DD');
	if (
		playerModuleData && 
		playerModuleData.disabledDate &&
		dayjs(today).diff(dayjs(playerModuleData.disabledDate), 'day') < appConfig.moduleDisabledDays
	) {
		isDisabled = true;
	}

	return isDisabled;
}

/**
 * Check if module group is disabled (happens if 1 of its modules is disabled)
 * @param {array} moduleGroups 
 * @param {object} playerData 
 * @returns 
 */
export function checkIfModuleInModuleGroupIsDisabled(moduleGroups, playerData) {
	let isDisabled = false; 

	moduleGroups.forEach((moduleId) => {
		if (isDisabled) return;
		const playerModuleData = getPlayerModuleData(moduleId, playerData);
		if (playerModuleData) {
			isDisabled = checkIfModuleIsDisabled(playerModuleData);
		}
	});

	return isDisabled;
}

/**
 * Check if a module is unlocked
 * @param {string} moduleId 
 * @param {object} playerData 
 * @returns 
 */
export function checkIfModuleIsUnlocked(moduleId, playerData) {
	let moduleIsUnlocked = false;
	const modulesData = getAllModulesData();
	const moduleData = modulesData.find((m) => {return m.id === moduleId;});

	if (moduleData) {
		moduleIsUnlocked = true;
		
		if (moduleData.requiredModuleIds && moduleData.requiredModuleIds.length > 0) {
			moduleData.requiredModuleIds.forEach((moduleId, index) => {
				if (!moduleIsUnlocked) return;

				/* Check if required module has been completed */
				const playerHasRequiredNumberOfStars = 
					checkIfPlayerHasRequiredNumberOfStars(playerData, moduleId);
				if (!playerHasRequiredNumberOfStars) {
					moduleIsUnlocked = false;
				} else {
					/* Check if required waiting days (if any) has passed */
					if (
						moduleData.requiredWaitingMonths && 
						moduleData.requiredWaitingMonths.length === moduleData.requiredModuleIds.length
					) {
						const today = dayjs(new Date()).format('YYYY-MM-DD');
						const completedDate = getModuleCompletedDate(playerData, moduleId);
						if (
							!completedDate ||
							dayjs(today).diff(dayjs(completedDate), 'month') < moduleData.requiredWaitingMonths[index]
						) {
							moduleIsUnlocked = false;
						}
					}
				}
			});
		}
	}

	return moduleIsUnlocked;
}

/**
 * Get index of current player session
 * @param {array} playerModulesData 
 * @param {string} moduleId 
 * @returns {number}
 */
export function getPlayerModuleSessionIndex(playerData, moduleId) {
	const playerModuleData = getPlayerModuleData(moduleId, playerData); 
	if (playerModuleData && playerModuleData.sessions && playerModuleData.sessions.length > 0) {
		return (playerModuleData.sessions.length - 1);
	}
	return -1;
}

/**
 * Get player data for current module session
 * @param {array} playerModulesData 
 * @param {string} moduleId 
 * @returns 
 */
export function getPlayerModuleSessionData(playerData, moduleId) {
	const playerModuleData = getPlayerModuleData(moduleId, playerData); 
	if (playerModuleData && playerModuleData.sessions && playerModuleData.sessions.length > 0) {
		return playerModuleData.sessions[playerModuleData.sessions.length - 1];
	}
	return null;
}

/**
 * Check if player has completed a module
 * @param {object} playerData 
 * @param {string} moduleId 
 * @returns 
 */
export function checkIfModuleIsCompleted(playerData, moduleId) {
	let isCompleted = false;

	const playerModuleData = getPlayerModuleData(moduleId, playerData); 
	if (playerModuleData && playerModuleData.sessions) {
		if (playerModuleData.sessions.length > 1) {
			isCompleted = true;
		} else {
			const playerModuleSessionData = getPlayerModuleSessionData(playerData, moduleId);
			isCompleted = checkIfModuleSessionIsCompleted(playerModuleSessionData);
		}
	}
	return isCompleted;
};

/**
 * Get module completion date
 * @param {object} playerData 
 * @param {string} moduleId 
 * @returns 
 */
export function getModuleCompletedDate(playerData, moduleId) {
	let completedDate = null;
	const playerModuleData = getPlayerModuleData(moduleId, playerData);
	if (playerModuleData && playerModuleData.completedDate) {
		completedDate = playerModuleData.completedDate;
	}
	return completedDate;
}

/**
 * Check if player has achieved required number of stars in module
 * @param {object} playerData 
 * @param {string} moduleId 
 * @returns 
 */
export function checkIfPlayerHasRequiredNumberOfStars(playerData, moduleId) {
	let playerHasRequiredNumberOfStars = false;

	const isCompleted = checkIfModuleIsCompleted(playerData, moduleId);

	if (isCompleted) {
		const modulesData = getAllModulesData();
		const moduleData = modulesData.find((m) => {return m.id === moduleId;});
		if (moduleData.hasOwnProperty('minStars')) {
			const modulePlayerData = getPlayerModuleData(moduleId, playerData);
			if (
				modulePlayerData && 
				modulePlayerData.maxStars && 
				modulePlayerData.maxStars >= moduleData.minStars
			) {
				playerHasRequiredNumberOfStars = true;
			}
		}
	}

	return playerHasRequiredNumberOfStars;
};


/**
 * Check if module session is completed
 * @param {object} playerModuleSessionData 
 * @returns 
 */
export function checkIfModuleSessionIsCompleted(playerModuleSessionData) {
	let sessionIsCompleted = false;
	if (playerModuleSessionData && playerModuleSessionData.isCompleted === true) sessionIsCompleted = true;
	return sessionIsCompleted;
}

/**
 * Get last completed module id
 * @returns {string}
 */
export function getLastCompletedModuleId(playerData, scenarioId) {
	let lastCompletedModuleId = null;

	const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
	const scenarioModulesData = (scenarioData ? scenarioData.modulesData : []);

	const playerModulesData = getPlayerModulesData(playerData);
	const completedModuleIds = [];
	if (playerModulesData && playerModulesData.length > 0) {
		playerModulesData.forEach((pm) => {
			if (scenarioModulesData.some((smd) => {return smd.id === pm.moduleId;})) {
				if (checkIfModuleIsCompleted(playerData, pm.moduleId)) {
					completedModuleIds.push(pm.moduleId);
				}
			}
		});
		if (completedModuleIds.length > 0) {
			lastCompletedModuleId = completedModuleIds[completedModuleIds.length - 1];
		}
	}
	return lastCompletedModuleId;
}

/**
 * Check if player has earned max stars in all modules in a group
 * @param {array} moduleIds 
 * @param {object} playerData 
 * @returns 
 */
export function checkIfModuleGroupIsCompleted(moduleIds, playerData) {
	let moduleGroupIsCompleted = true;
	moduleIds.forEach((moduleId) => {
		if (!moduleGroupIsCompleted) return;
		if ( !checkIfPlayerHasRequiredNumberOfStars(playerData, moduleId)) {
			moduleGroupIsCompleted = false;
		}
	});

	return moduleGroupIsCompleted;
}

export function getModuleGroupStartedDate(moduleIds, playerData) {
	let startedDate = null;

	moduleIds.forEach((moduleId) => {
		const playerModuleData = getPlayerModuleData(moduleId, playerData);
		const firstSessionData = (playerModuleData && playerModuleData.sessions && playerModuleData.sessions.length > 0
			? playerModuleData.sessions[0]
			: null
		);
		if (firstSessionData && firstSessionData.started) {
			if (!startedDate || startedDate > firstSessionData.started) {
				startedDate = firstSessionData.started;
			}
		}
		
	});

	return startedDate;
}

export function getModuleGroupCompletedDate(moduleIds, playerData) {
	let completedDate = null;

	moduleIds.forEach((moduleId) => {
		const playerModuleData = getPlayerModuleData(moduleId, playerData);
		if (
			playerModuleData.completedDate &&
			(
				!completedDate ||
				completedDate < playerModuleData.completedDate
			)
		) {
			completedDate = playerModuleData.completedDate;
		}
	});

	return completedDate;
}

/**
 * Get the last played taskId of the module (null if player has just started the module)
 * @param {string} moduleId 
 * @param {object} playerData 
 * @returns 
 */
export function getLastPlayedTaskId(moduleId, playerData) {
	let taskId = null;
	const modulesData = getAllModulesData();
	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	if (moduleData.tasks && moduleData.tasks.length > 0) {
		/* Default: First task in module */
		taskId = moduleData.tasks[0].id;
		const playerModuleSessionData = getPlayerModuleSessionData(playerData, moduleId);
		if (
			playerModuleSessionData && 
			playerModuleSessionData.currentTaskId &&
			moduleData.tasks.some((m) => {return m.id === playerModuleSessionData.currentTaskId;})
		) {
			/* Last played task */
			taskId = playerModuleSessionData.currentTaskId;
		}
	}

	return taskId;
}


/**
 * Count number of required tasks in a module
 * @param {object} moduleData 
 * @returns 
 */
export function getNumberOfRequiredTasksInModule(moduleData) {
	return moduleData.tasks.filter((task) => {
		return task.isSolveToContinue === true;
	}).length;
}

/**
 * Count number of completed (required) tasks in a module
 * @param {object} moduleData 
 * @param {object} playerModuleData 
 * @returns 
 */
export function getNumberOfCompletedTasksInModule(moduleData, playerModuleData) {
	let numberOfCompletedTasks = 0;

	if (checkIfModuleIsCompleted({modules: [playerModuleData]}, moduleData.id) === true) {
		/* Module completed => all tasks completed */
		numberOfCompletedTasks = getNumberOfRequiredTasksInModule(moduleData);
	} else {
		const playerSessionData = getPlayerModuleSessionData({modules: [playerModuleData]}, moduleData.id);
		if (playerSessionData && playerSessionData.tasks) {
			moduleData.tasks.forEach((task) => {
				if (task.isSolveToContinue === true) {
					if (playerSessionData.tasks.some((playerTask) => {
						return (playerTask.taskId === task.id && playerTask.isCompleted === true);
					})) {
						numberOfCompletedTasks += 1;
					}
				}
			});
		}
	}
	
	return numberOfCompletedTasks;
}

export function getNumberOfAttemptsAndErrors(playerModuleData) {
	let attempts = 0;
	let errors = 0;

	if (playerModuleData && playerModuleData.sessions && playerModuleData.sessions.length > 0) {
		const allSessionTasks = playerModuleData.sessions.map((session) => {return session.tasks;}).flat();
		if (allSessionTasks.length > 0) {
			attempts = allSessionTasks.length;
			errors = allSessionTasks.reduce((prev, current) => {
				if (current.hasOwnProperty('errors')) {
					return prev + current.errors;	
				}
				return prev;
				
			}, 0);
			
		}
	}

	return {attempts, errors};
}


/**
 * Get error stats for a session
 * @param {object} moduleData 
 * @param {object} playerModuleSession 
 * @returns 
 */
export function getSessionErrorStats(moduleData, playerModuleSessionData) {
	let numberOfTasks = 0;
	let numberOfErrors = 0;
	let numberOfErrorFreeTasks = 0;

	moduleData.tasks.forEach((taskData) => {
		if (taskData.isSolveToContinue === false) return;
		const playerTaskData = (taskData.taskId && playerModuleSessionData && playerModuleSessionData.tasks 
			?	playerModuleSessionData.tasks.find((t) => {return t.taskId === taskData.taskId;})
			: null
		);
		if (playerTaskData) {
			numberOfTasks += 1;
			const errors = (playerTaskData.hasOwnProperty('errors') ? playerTaskData.errors : 0);
			if (errors > 0) {
				numberOfErrors += errors;
			} else {
				numberOfErrorFreeTasks += 1;
			}
		}
	});

	const avrNumberOfErrorsPerTask = (numberOfTasks > 0 
		? (numberOfErrors / numberOfTasks).toFixed(1)
		: '-'
	);

	return {
		numberOfTasks, avrNumberOfErrorsPerTask, numberOfErrorFreeTasks
	};
}

/**
 * Start new module session
 * @param {string} moduleId 
 * @param {object} playerData 
 * @returns 
 */
export function startNewSession(moduleId, playerData) {
	const playerModulesData = getPlayerModulesData(playerData);
	const moduleIndex = playerModulesData.findIndex((m) => {return m.moduleId === moduleId;});
	if (moduleIndex < 0) return null;
	if (!playerModulesData[moduleIndex].hasOwnProperty('sessions')) return null;
	const modulesData = getAllModulesData();
	const moduleData = modulesData.find((m) => {return m.id === moduleId;});
	const	taskId = (moduleData.tasks && moduleData.tasks.length > 0 ? moduleData.tasks[0].id : null);
	const sessionTemplate = {
		isCompleted: false,
		started: dayjs(new Date()).format('YYYY-MM-DD'),
		currentTaskId: taskId,
		points: 0,
		milisecondsPlayed: 0,
		tasks: []
	};
	playerModulesData[moduleIndex].sessions.push(sessionTemplate);

	return playerModulesData;
}