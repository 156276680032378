import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import Button from 'components/ui/button/button';
import './login-player.scss';


const LoginPlayer = (props) => {
	const {
		isLoading,
		languageId,
		userId,
		feedback,
		handleInput,
		handleShowLoginPopup,
	} = props;

	return (
		<div className="LoginPlayer">
			<form className="LoginPlayer-form" onSubmit={(e) => {handleShowLoginPopup(e);}}>
				<input
					name="userId"
					type="text"
					placeholder={getText(loginUiTexts.userId, languageId)} 
					autoComplete="section-grouplogin userId"
					value={userId ? userId : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<div className="LoginPlayer-loginBtn">
					<Button 
						isLoading={isLoading}
						classes={['login']}
						text={getText(loginUiTexts.start, languageId)}
						onClick={(e) => {handleShowLoginPopup(e);}}
					/>
				</div>
				<div className="LoginPlayer-errorMessage"><span>{feedback}</span></div>
			</form>
		</div>
	);
};

LoginPlayer.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	userId: PropTypes.any,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleShowLoginPopup: PropTypes.func.isRequired,
};

export default LoginPlayer;