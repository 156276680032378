const moduleRepetition2B = [
	'modules/tasks/multiple-choice/repetition-2-b-never-bring/option-1.svg',
	'modules/tasks/multiple-choice/repetition-2-b-never-bring/option-2.svg',
	'modules/tasks/multiple-choice/repetition-2-b-never-bring/option-3.svg',
	'modules/tasks/multiple-choice/repetition-2-b-never-bring/option-4.svg',
	'modules/tasks/multiple-choice/repetition-2-b-contaminated-meat/option-1.svg',
	'modules/tasks/multiple-choice/repetition-2-b-contaminated-meat/option-2.svg',
	'modules/tasks/multiple-choice/repetition-2-b-contaminated-meat/option-3.svg',
	'modules/tasks/multiple-choice/repetition-2-b-contaminated-meat/option-4.svg',
	'modules/tasks/multiple-choice/repetition-2-b-contaminated-meat2/option-1.svg',
	'modules/tasks/multiple-choice/repetition-2-b-contaminated-meat2/option-2.svg',
	'modules/tasks/multiple-choice/repetition-2-b-contaminated-meat2/option-3.svg',
	'modules/tasks/multiple-choice/repetition-2-b-contaminated-meat2/option-4.svg',
	'modules/tasks/multiple-choice/repetition-2-b-vermin-signs/option-1.svg',
	'modules/tasks/multiple-choice/repetition-2-b-vermin-signs/option-2.svg',
	'modules/tasks/multiple-choice/repetition-2-b-vermin-signs/option-3.svg',
	'modules/tasks/multiple-choice/repetition-2-b-vermin-signs/option-4.svg',

	'modules/tasks/spot-errors/repetition-2-b-production/background.svg',
	'modules/tasks/spot-errors/repetition-2-b-production2/background.svg',
];


export {
	moduleRepetition2B
};