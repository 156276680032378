const modulesData = [
	{
		isPublished: true,
		id: 'personal-hygiene',
		background: 'danish-crown',
		title: {
			da: 'DC Hygiejnetræning A',
			en: 'DC Hygiene Training A',
			pl: 'DC Szkolenie z higieny A',
			de: 'DC Hygieneschulung A',
		},
		minStars: 3,
		requiredModuleIds: [],
		tasks: require('./modules/personal-hygiene').moduleTasks,
	},
	{
		isPublished: true,
		id: 'production',
		background: 'danish-crown',
		title: {
			da: 'DC Hygiejnetræning B',
			en: 'DC Hygiene Training B',
			pl: 'DC Szkolenie z higieny B',
			de: 'DC Hygieneschulung B',
		},
		minStars: 3,
		requiredModuleIds: ['personal-hygiene'],
		tasks: require('./modules/production').moduleTasks,
	},
	{
		isPublished: true,
		id: 'repetition-1-a',
		background: 'danish-crown',
		title: {
			da: 'Gentræning 1A',
			en: 'Repetition 1A',
			pl: 'Szkolenie odświeżające 1A',
			de: 'Wiederholungsschulung 1A',
		},
		minStars: 3,
		requiredModuleIds: ['production'],
		requiredWaitingMonths: [3],
		tasks: require('./modules/repetition-1-a').moduleTasks,
	},
	{
		isPublished: true,
		id: 'repetition-1-b',
		background: 'danish-crown',
		title: {
			da: 'Gentræning 1B',
			en: 'Repetition 1B',
			pl: 'Szkolenie odświeżające 1B',
			de: 'Wiederholungsschulung 1B',
		},
		minStars: 3,
		requiredModuleIds: ['repetition-1-a'],
		tasks: require('./modules/repetition-1-b').moduleTasks,
	},
	{
		isPublished: true,
		id: 'repetition-2-a',
		background: 'danish-crown',
		title: {
			da: 'Gentræning 2A',
			en: 'Repetition 2A',
			pl: 'Szkolenie odświeżające 2A',
			de: 'Wiederholungsschulung 2A',
		},
		minStars: 3,
		requiredModuleIds: ['repetition-1-b'],
		requiredWaitingMonths: [3],
		tasks: require('./modules/repetition-2-a').moduleTasks,
	},
	{
		isPublished: true,
		id: 'repetition-2-b',
		background: 'danish-crown',
		title: {
			da: 'Gentræning 2B',
			en: 'Repetition 2B',
			pl: 'Szkolenie odświeżające 2B',
			de: 'Wiederholungsschulung 2B',
		},
		minStars: 3,
		requiredModuleIds: ['repetition-2-a'],
		tasks: require('./modules/repetition-2-b').moduleTasks,
	}
]

const moduleGroups = [
	{
		id: 'hygiene-and-production',
		courseNumber: '50000000',
		title: {
			da: 'DC hygiejnetræning',
			en: 'DC Hygiene Training',
			pl: 'DC Szkolenie z higieny',
			de: 'DC Hygieneschulung',
		},
		moduleIds: [
			'personal-hygiene',
			'production'
		]
	},
	{
		id: 'repetition-1',
		courseNumber: '50000510',
		title: {
			da: 'Gentræning 1',
			en: 'Repetition 1',
			pl: 'Szkolenie odświeżające 1',
			de: 'Wiederholungsschulung 1',
		},
		moduleIds: [
			'repetition-1-a',
			'repetition-1-b'
		]
	},
	{
		id: 'repetition-2',
		courseNumber: '50000510',
		title: {
			da: 'Gentræning 2',
			en: 'Repetition 2',
			pl: 'Szkolenie odświeżające 2',
			de: 'Wiederholungsschulung 2',
		},
		moduleIds: [
			'repetition-2-a',
			'repetition-2-b'
		]
	}
];

export {
	modulesData,
	moduleGroups
};