const taskTemplates = require('data/templates/task-templates');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-instructions-welcome',
		background: 'slaughterhouse',
		text: {
			da: `Velkommen til hygiejnetræning B. Nu skal det handle om hygiejne i produktionen.`,
			en: `Welcome to hygiene training B. Now we will focus on hygiene in production.`,
			pl: `Witamy w module B szkolenia z higieny. Teraz będzie o higienie w produkcji.`,
			de: `Willkommen zur Hygieneschulung B. Jetzt geht es um die Hygiene in der Produktion.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-instructions-dos-and-donts',
		background: 'slaughterhouse',
		text: {
			da: `Det handler om, hvordan du skal håndtere produkter i produktionen. Altså hvad må man gøre, og hvad må man ikke gøre i produktionen.`,
			en: `It is about how you should handle the products in the production. In other words, what you can and connot do in the production.`,
			pl: `Chodzi o to, jak należy obchodzić się z produktami podczas produkcji. Innymi słowy, co można, a czego nie można robić w produkcji.`,
			de: `Es geht darum, wie du mit den Produkten in der Produktion umgehen solltest. Mit anderen Worten, was du in der Produktion tun kannst und was eben nicht.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-instructions-stars',
		background: 'slaughterhouse',
		text: {
			da: `Lad os kaste os ud i det. Husk, du skal have tre stjerner for at gennemføre modulet.`,
			en: `Let's dive into it. Remember you need three stars to complete the module.`,
			pl: `Zagłębmy się w to. Pamiętaj, że do ukończenia modułu potrzebujesz trzech gwiazdek.`,
			de: `Fangen wir an. Denke daran, dass du drei Sterne benötigst, um dieses Modul abzuschließen.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'production-mc-hygiene-extra-careful',
		background: 'slaughterhouse',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne i produktionen?`,
			en: `Why should we pay extra attention to hygiene in the production?`,
			pl: `Dlaczego powinniśmy zwracać szczególną uwagę na higienę podczas produkcji?`,
			de: `Warum sollten wir besonders auf Hygiene in der Produktion achten?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `På grund af lovkrav`,
					en: `Due to legal requirements`,
					pl: `Ze względu na wymogi prawne`,
					de: `Aufgrund gesetzlicher Vorgaben`
				}				
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`,
					en: `Because poor hygiene can make people sick`,
					pl: `Ponieważ zła higiena może powodować choroby`,
					de: `schlechte Hygiene kann krank machen`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`,
					pl: `Aby zapewnić wysoką jakość`,
					de: `Um eine hohe Qualität zu gewährleisten`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all the above reasons`,
					pl: `Ze wszystkich powyższych powodów`,
					de: `Aus all den oben genannten Gründen`
				},
				isCorrect: true
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-instructions-hygiene-issues',
		text: {
			da: `Lad os kigge nærmere på produktionen. På næste billede skal du spotte de ting, som er problematiske i forhold til hygiejne.`,
			en: `Let's take a closer look at the production. On the next picture, you must spot the things that are problematic in terms of hygiene.`,
			pl: `Przyjrzyjmy się bliżej produkcji. Na kolejnym obrazku musisz wskazać rzeczy, które są problematyczne z punktu widzenia higieny.`,
			de: `Schauen wir uns die Produktion genauer an. Auf dem nächsten Bild musst du die hygienisch problematischen Dinge erkennen.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'production-spot-errors-slaughter-hall',
		layout: 'production-slaughter-hall',
		text: {
			da: `Tryk på de 5 fejl.<br /><br />Tryk OK når du mener, du har fundet dem alle.`,
			en: `Press on the 5 mistakes.<br /><br />Press OK when you believe you have found them all.`,
			pl: `Zaznacz 5 błędów.<br /><br />Naciśnij OK, gdy uważasz, że znalazione zostały wszystkie.`,
			de: `Wähle 5 Fehler.<br /><br />Bestätige mit OK, wenn du glaubst, dass du alle Fehler erkannt hast.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'meat-trays',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'woodbox',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'meat-on-display',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'tools',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'floor-meat',
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'production-mc-ham-on-floor',
		background: 'slaughterhouse',
		text: {
			da: `Der lå en skinke på gulvet i den forrige opgave - hvad skal du gøre med den?`,
			en: `There was a pork leg on the floor in the previous task - what should you do with it?`,
			pl: `W poprzednim zadaniu na podłodze leżał udziec wieprzowy – co z tym zrobić?`,
			de: `Bei der vorherigen Aufgabe lag ein Schweine Schinken auf dem Boden – was musst du damit machen?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Smide den ud i almindelig skraldespand`,
					en: `Throw it away in the regular trashcan`,
					pl: `Wyrzuć do zwykłego kosza na śmieci`,
					de: `Wirf es in den normalen Mülleimer`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Sende den til reinspektion`,
					en: `Send it to reinspection`,
					pl: `Wyślij do ponownego sprawdzenia`,
					de: `Gib den Schinken zur Re-Inspektion`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Lægge den tilbage på båndet`,
					en: `Put it back on the production line`,
					pl: `Umieść z powrotem na linii produkcyjnej`,
					de: `Lege den Schinken zurück auf die Produktionslinie`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'production-mc-ham-why-not-return',
		background: 'slaughterhouse',
		text: {
			da: `I sidste opgave fandt du ud af, at du ikke må lægge skinken tilbage på båndet. Hvorfor må du ikke det?`,
			en: `In the previous task you found out that you must not put the pork leg back on the production belt. Why not?`,
			pl: `W poprzednim zadaniu dowiedziałeś/łaś się, że nie wolno odkładać udźca wieprzowego na taśmę produkcyjną. Dlaczego nie?`,
			de: `In der vorherigen Aufgabe hast du herausgefunden, dass du den Schweine Schinken nicht zurück auf das Produktionsband legen darfst. Warum nicht?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Det kan forurene de efterfølgende produkter`,
					en: `It can contaminate the following products`,
					pl: `Może zanieczyścić następujące produkty`,
					de: `Er kann die folgenden Produkte kontaminieren`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det er ligegyldigt, skinken må gerne komme tilbage på båndet`,
					en: `It does not matter, the pork leg can be put back on the production line`,
					pl: `To nie ma znaczenia, udziec wieprzowy można ponownie umieścić na taśmie produkcyjnej`,
					de: `Es spielt keine Rolle, der Schinken kann wieder auf die Produktionslinie gelegt werden`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Der er risiko for at forbrugerne kan blive syge`,
					en: `There is a risk that the consumers may become ill`,
					pl: `Istnieje ryzyko zachorowania konsumentów`,
					de: `Es besteht die Gefahr, dass daran Verbraucher erkranken könnten`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Kødet er blevet sejt og kan ikke bruges`,
					en: `The meat has become tough and cannot be used`,
					pl: `Mięso stało się twarde i nie nadaje się do użycia`,
					de: `Das Fleisch ist zäh geworden und kann nicht verwendet werden`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-instructions-ask-master',
		text: {
			da: `Du er tæt på produktionen hver dag, og derfor lægger du garanteret mærke til ting, som andre overser. Og hvis du er i tvivl, er det altid bedre at spørge en mester.`,
			en: `You are close to the production every day and therefore you are guaranteed to notice things that others overlook. And if in doubt, it is always better to ask a manager`,
			pl: `Codziennie jesteś blisko produkcji, dzięki czemu masz gwarancję, że zauważysz rzeczy, które inni przeoczą. A w razie wątpliwości zawsze lepiej zapytać kierownika.`,
			de: `Du bist jeden Tag nah dran an der Produktion und so fallen dir garantiert Dinge auf, die andere übersehen. Und im Zweifel ist es immer besser, deinen Vorgesetzten zu fragen.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'production-mc-clothes',
		background: 'slaughterhouse',
		layout: 'production-clothes',
		subtype: 'images',
		text: {
			da: `Det er vigtigt, at dit tøj ikke kommer i berøring med uemballerede produkter. Hvad skal du bruge eller gøre for at undgå det?`,
			en: `It is important that your clothes do not come into contact with unpackaged products. What should you use or do to avoid it?`,
			pl: `Ważne jest, aby Twoje ubrania nie miały kontaktu z rozpakowanymi produktami. Co należy stosować lub robić, aby tego uniknąć?`,
			de: `Wichtig ist, dass deine Kleidung nicht mit unverpackten Produkten in Berührung kommt. Was solltest du verwenden oder tun, um das zu vermeiden?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-instructions-contamination',
		text: {
			da: `Nu skal vi kigge lidt på forurening. Det handler grundlæggende om, at der kommer ting i produkterne, som ikke skal være der.`,
			en: `Now let's take a look at contamination. It's basically about things getting into the products that shouldn't be there.`,
			pl: `Teraz spójrzmy na zanieczyszczenie. Zasadniczo chodzi o to, aby do produktów nie dostały się rzeczy, których nie powinno tam być.`,
			de: `Werfen wir nun einen Blick auf die Kontamination. Es geht im Grunde darum, dass Dinge in die Produkte gelangen, die dort nicht sein sollten.`
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'production-order-broken-glass',
		background: 'slaughterhouse',
		text: {
			da: `En lampe går i stykker i produktionen. Der er risiko for, der er kommet fremmedlegmer i produkterne. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge.`,
			en: `A lamp breaks in production. There is a risk that foreign bodies have entered the products. What should you do? Put the actions below in the correct order.`,
			pl: `Lampa pęka w dziale produkcji. Istnieje ryzyko przedostania się ciał obcych do produktów. Co powinieneś zrobić? Ułóż poniższe czynności we właściwej kolejności.`,
			de: `Eine Lampe geht in der Produktion kaputt. Es besteht die Gefahr, dass Fremdkörper in die Produkte gelangen. Was sollte man tun? Bringe die folgenden Aktionen in die richtige Reihenfolge.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Stop produktionen`,
					en: `Stop the production`,
					pl: `Zatrzymaj produkcję`,
					de: `Stoppe die Produktion`
				}, 
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Tilkald mester`,
					en: `Call for the manager`,
					pl: `Wezwij kierownika`,
					de: `Rufe deinen Vorgesetzten`
				}, 
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Afvent frigivelse fra mester`,
					en: `await release from the manager`,
					pl: `Poczekaj na zezwolenie od kierownika`,
					de: `Warte auf die Freigabe deines Vorgsetzten`
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Fortsæt arbejdet`,
					en: `Continue the work`,
					pl: `Kontynuuj pracę`,
					de: `Setze die Arbeit fort`
				}, 
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'production-mc-product-contamination',
		background: 'slaughterhouse',
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: `Our products can become contaminated in various ways. Why is contamination an issue?`,
			pl: `Nasze produkty mogą zostać skażone na różne sposoby. Dlaczego zanieczyszczenie stanowi problem?`,
			de: `Unsere Produkte können auf verschiedene Weise kontaminiert werden. Warum sind Kontaminationen ein Problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Det kan være farligt`,
					en: `It can be dangerous`,
					pl: `Może być niebezpieczne`,
					de: `Es kann gefährlich sein`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det kan ødelægge smagen`,
					en: `It can spoil the taste`,
					pl: `Może zepsuć smak`,
					de: `Es kann den Geschmack verderben`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `It can result in dissatified customers`,
					pl: `Może skutkować niezadowoleniem klientów`,
					de: `Dies kann zu unzufriedenen Kunden führen`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Det udleder mere CO2`,
					en: `It emits more CO2`,
					pl: `Emituje więcej CO2`,
					de: `Es emittiert mehr CO2`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Forurening er kun et problem, hvis inspektører ser det`,
					en: `Contamination is only a problem if inspectors see it.`,
					pl: `Zanieczyszczenie jest problemem tylko wtedy, gdy inspektorzy je zauważą.`,
					de: `Kontamination ist nur dann ein Problem, wenn Inspektoren sie sehen.`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Det kan gøre vores tøj beskidt`,
					en: `It can make our clothes dirty`,
					pl: `Może zabrudzić nasze ubrania`,
					de: `Es kann unsere Kleidung schmutzig machen`
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'production-mc-allergies',
		background: 'slaughterhouse',
		subtype: 'images',
		layout: 'production-allergies',
		text: {
			da: `Forurening kan være et problem for folk med allergi. Hvad må du aldrig tage med på slagteriet - heller ikke i madpakken?`,
			en: `Contamination can be a problem for people with allergies. What shoud you never bring to the slaughterhouse - not even in the lunchbox?`,
			pl: `Zanieczyszczenia mogą stanowić problem dla alergików. Czego nigdy nie należy zabierać do rzeźni – nawet w pudełku śniadaniowym?`,
			de: `Verunreinigungen können für Allergiker ein Problem darstellen. Was darf man auf keinen Fall zum Schlachthof mitbringen – auch nicht in der Lunchbox?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,  // Nutella
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Peanuts
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Strawberries
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Shrimps
			})
		]
	}),


	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-instructions-hygiene-quality',
		text: {
			da: `God hygiejne og dermed høj kvalitet starter med dig. Se om du kan spotte problemerne på næste billede.`,
			en: `Good hygiene and high quality starts with you. See if you can spot the problems in the next picture.`,
			pl: `Dobra higiena i wysoka jakość zaczynają się od Ciebie. Sprawdź, czy potrafisz dostrzec problemy na następnym obrazku.`,
			de: `Gute Hygiene und hohe Qualität fangen bei dir an. Guck, ob du die Probleme im nächsten Bild erkennen kannst.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'production-spot-errors-slaughter-hall-2',
		layout: 'production-slaughter-hall-2',
		text: {
			da: `Tryk på de 4 fejl.<br /><br />Tryk OK når du mener, du har fundet dem alle.`,
			en: `Press on the 4 mistakes.<br /><br />Press OK when you think you've found them all.`,
			pl: `Zaznacz 4 błędy.<br /><br />Naciśnij OK, gdy uważasz, że znalezione zostały wszystkie.`,
			de: `Klicke auf die 4 Fehler.<br /><br />Bestätige mit OK, wenn du denkst, dass du sie alle gefunden hast.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'drop',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'gloves',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'soap',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'thread',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-instructions-pests',
		text: {
			da: `Vi skal undgå skadedyr i produktionen.`,
			en: `We must avoid pests in the production.`,
			pl: `Musimy unikać szkodników w produkcji.`,
			de: `Wir müssen Schädlinge in der Produktion vermeiden.`
		}
	}),	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'production-mc-mouse',
		background: 'slaughterhouse',
		text: {
			da: `Du spotter en mus på gulvet i produktionen. Hvad skal du gøre?`,
			en: `You spot a mouse on the production floor. What should you do?`,
			pl: `Zauważasz mysz na hali produkcyjnej. Co powinieneś/powinnaś zrobić?`,
			de: `Du entdeckst eine Maus in der Produktionshalle. Was sollte man tun?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Forsøge at holde øje med musen imens jeg tilkalder hjælp`,
					en: `Try to keep an eye on the mouse while I call for help`,
					pl: `Staraj się mieć oko na mysz, kiedy będziesz wzywać pomocy`,
					de: `Versuche die Maus im Auge zu behalten, während Du um Hilfe rufst`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Forsøge at fange musen selv og derefter tilkalde hjælp, hvis det ikke kan lade sig gøre`,
					en: `Try to catch the mouse yourself and then call for help if that is not possible`,
					pl: `Spróbuj sam złapać mysz, a jeśli nie jest to możliwe, wezwij pomoc.`,
					de: `Versuche die Maus selbst zu fangen, und rufe dann um Hilfe, wenn dies nicht möglich ist`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Sige det til nærmeste leder efter endt arbejdsdag`,
					en: `Tell the immediate manager after the end of the work day`,
					pl: `Po zakończeniu dnia pracy poinformuj o tym bezpośredniego przełożonego.`,
					de: `Informiere deinen direkten Vorgesetzten nach Feierabend`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-instructions-finish',
		text: {
			da: `Sådan, du er nu færdig med andet modul. Hvis du har fået tre stjerner, er du færdig med uddannelsen for denne gang. Hvis ikke, så må du tage modulet en gang til.`,
			en: `That's it, you have now finished the second module. If you have received at three stars, you are done with the training for this time. If not, then you must take the module again.`,
			pl: `To wszystko, właśnie ukończyłeś/aś drugi moduł. Jeśli otrzymałeś/aś trzy gwiazdki, skończyłeś/aś szkolenie. Jeśli nie, musisz ponownie przejść moduł.`,
			de: `Das wars, du hast jetzt das zweite Modul abgeschlossen. Wenn du drei Sterne erhalten hast, bist du mit diesem Trainingsabschnitt fertig. Wenn nicht, musst du das Modul wiederholen.`
		}
	})
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'production-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};