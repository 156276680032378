const moduleRepetition22A = [
	'modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-1.svg',
	'modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-2.svg',
	'modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-3.svg',
	'modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-4.svg',
	'modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-5.svg',
	'modules/tasks/multiple-choice/repetition-2-a-labcoat/option-1.svg',
	'modules/tasks/multiple-choice/repetition-2-a-labcoat/option-2.svg',
	'modules/tasks/multiple-choice/repetition-2-a-labcoat/option-3.svg',
	'modules/tasks/multiple-choice/repetition-2-a-labcoat/option-4.svg',
	'modules/tasks/multiple-choice/repetition-2-2a-lockers/option-1.svg',
	'modules/tasks/multiple-choice/repetition-2-2a-lockers/option-2.svg',
	'modules/tasks/multiple-choice/repetition-2-2a-lockers/option-3.svg',
	'modules/tasks/multiple-choice/repetition-2-2a-lockers/option-4.svg',
	'modules/tasks/multiple-choice/repetition-2-2a-lockers/option-5.svg',
	'modules/tasks/multiple-choice/repetition-2-2a-lockers/option-6.svg',
	// 'modules/tasks/multiple-choice/repetition-2-2a-lockers/option-7.svg',
	'modules/tasks/multiple-choice/repetition-2-2a-lockers/option-8.svg',

	'modules/tasks/paper-doll/avatar.svg',
	'modules/tasks/paper-doll/hairnet-a.svg',
	'modules/tasks/paper-doll/hairnet-b.svg',
	'modules/tasks/paper-doll/earplugs-a.svg',
	'modules/tasks/paper-doll/earplugs-b.svg',
	'modules/tasks/paper-doll/white-shoes-a.svg',
	'modules/tasks/paper-doll/white-shoes-b.svg',
	'modules/tasks/paper-doll/glasses-a.svg',
	'modules/tasks/paper-doll/phone-a.svg',
	'modules/tasks/paper-doll/phone-b.svg',
	'modules/tasks/paper-doll/watch2-a.svg',

	'modules/tasks/spot-errors/repetition-2-a-person/background.svg',
	'modules/tasks/spot-errors/repetition-2-1a-person2/background.svg',
	
];

export {
	moduleRepetition22A
};