import React, {useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {getText} from 'helpers/language-helper';
import {
	getPlayerModuleData, 
	getNumberOfRequiredTasksInModule,
	getNumberOfCompletedTasksInModule,
	getNumberOfAttemptsAndErrors,
} from 'helpers/module-helper';
import apiHelper from 'helpers/api-helper';
import {getTimeSpentInModule} from 'helpers/time-helper';
import {scenariosData} from 'data/scenarios-data';
import {facilitatorUiTexts} from 'data/ui-texts/facilitator-ui-texts';
import { loginUiTexts } from 'data/ui-texts/login-ui-texts';
import { adminUiTexts } from 'data/ui-texts/admin-ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/ui/button/button';
import Star from 'components/ui/star/star';
import Select from 'components/ui/select/select';
import Popup from 'components/ui/popup/popup';
import RenamePlayerPopup from './rename-player-popup/rename-player-popup';
import './facilitator-player-popup.scss';

const FacilitatorPlayerPopup = ({
	languageId, 
	gameData,
	players,
	selectedPlayerId, 
	updatePlayer, 
	togglePopup, 
}) => {
	/* Get scenario data */
	const scenarioId = (gameData.scenarioId ? gameData.scenarioId : 'scenario1');
	const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});

	/* Selected module group id */
	const [selectedModuleGroupId, setSelectedModuleGroupId] = useState(scenarioData.moduleGroups[0].id);

	/* Selected module to reset */
	const [showModuleResetPopup, setShowModuleResetPopup] = useState(false);
	const [isLoadingReset, setIsLoadingReset] = useState(false);

	/* Selected module group data */
	const selectedModuleGroupData = scenarioData.moduleGroups.find((moduleGroup) => {
		return moduleGroup.id === selectedModuleGroupId;
	});

	/* Selected player to delete */
	const [showPlayerDeletePopup, setShowPlayerDeletePopup] = useState(false);
	const [isLoadingDelete, setIsLoadingDelete] = useState(false);

	/* Selected player to rename */
	const [showPlayerRenamePopup, setShowPlayerRenamePopup] = useState(false);

	const playerData = players.find((player) => {return player.id === selectedPlayerId;});

	/* Player Id */
	let playerId = '';

	/* Player last login */
	let lastLogin = '-';

	/* Player status */
	let [status, setStatus] = useState((playerData && playerData.isInactive) ? 'inactive' : 'active');

	if (playerData) {
		/* Player Id */
		playerId = (playerData.id ? playerData.id : '');

		/* Player last login */
		lastLogin = (playerData.lastLogin ? dayjs(playerData.lastLogin).format('DD/MM-YY') : '-');
	}

	/**
	 * Toggle player status
	 */
	const togglePlayerStatus = () => {
		const isInactive = (status === 'inactive' ? true : false);
		if (isInactive) {
			/* Confirm reactivation of player */
			setShowResetPlayerPopup(true);
		} else {
			/* Deactivate player */
			updatePlayer(playerData.id, {isInactive: !isInactive}).then(() => {
				setStatus('inactive');
			});
		}
	};

	/**
	 * Reactivate player 
	 */
	const reactivatePlayer = (resetPlayer = false) => {
		let updates = {isInactive: false};

		if (resetPlayer) {
			updates.modules = [];
		}

		updatePlayer(playerData.id, updates).then(() => {
			setStatus('active');
			setShowResetPlayerPopup(false);
		});
	};

	/**
	 * Resets currently chosen module player data
	 */
	const deletePlayer = () => {
		setIsLoadingDelete(true);
		return new Promise((resolve) => {
			apiHelper('facilitator/delete-player', {playerId: playerData.id}).then(
				(response) => {
					// Success
					if (response.status === 'success') {
						setIsLoadingDelete(false);
						setShowPlayerDeletePopup(false);
						togglePopup();
						resolve({status: 'success'});
					} else {
						console.error(response);
						setIsLoadingDelete(false);
						resolve({status: 'error', error: response.error});
					}
				},
				(rejection) => {
					// Error
					console.error(rejection);
					setIsLoadingDelete(false);
					resolve({status: 'error', error: rejection});
				}
			);
		});
	};

	/**
	 * Resets currently chosen module player data
	 */
	const resetModule = () => {
		// Filter off modules set to be reset
		let filteredPlayerModulesData = playerData.modules;

		selectedModuleGroupData.moduleIds.forEach((moduleId) => {
			filteredPlayerModulesData = filteredPlayerModulesData.filter((module) => {
				return module.moduleId !== moduleId;
			});
		});

		setIsLoadingReset(true);

		const updates = {modules: filteredPlayerModulesData};

		updatePlayer(playerData.id, updates).then(() => {
			setIsLoadingReset(false);
			setShowModuleResetPopup(false);
		});
	};

	const statusBtnTxt = (status === 'active'
		? getText(facilitatorUiTexts.deactivateBtn, languageId)
		: getText(facilitatorUiTexts.reactivateBtn, languageId)
	);

	/* Reset player when re-activating popup */
	const [showResetPlayerPopup, setShowResetPlayerPopup] = useState(false);

	/* Module group data */
	let numberOfRequiredTasks = 0;
	let numberOfStars = 0;
	let numberOfCompletedTasks = 0;
	let numberOfAttempts = 0;
	let numberOfErrors = 0;
	let timePlayed = 0;
	selectedModuleGroupData.moduleIds.forEach((moduleId) => {
		const moduleData = scenarioData.modulesData.find((moduleData) => {return moduleData.id === moduleId;});
		numberOfRequiredTasks += getNumberOfRequiredTasksInModule(moduleData);
		const playerModuleData = (playerData ? getPlayerModuleData(moduleId, playerData) : null);
		if (playerModuleData) {
			if (playerModuleData.maxStars) numberOfStars += playerModuleData.maxStars;
			numberOfCompletedTasks += getNumberOfCompletedTasksInModule(moduleData, playerModuleData);
			const {attempts, errors} = getNumberOfAttemptsAndErrors(playerModuleData);
			numberOfAttempts += attempts;
			numberOfErrors += errors;
			timePlayed += getTimeSpentInModule(playerModuleData);
		}
	});

	let avgErrors = 0;
	if (numberOfAttempts > 0) {
		avgErrors = (numberOfErrors / numberOfAttempts).toFixed(2);
	}

	let taskCompletion = 0;
	if (numberOfRequiredTasks > 0) {
		taskCompletion = ((numberOfCompletedTasks / numberOfRequiredTasks) * 100).toFixed(2);
	}

	let filledStars = Array(selectedModuleGroupData.moduleIds.length * 3).fill(false);
	for (let i = 0; i < numberOfStars; i++) {
		filledStars[i] = true;
	}

	return (
		<div className="FacilitatorPlayerPopup" onClick={() => {togglePopup(null);}}>
			<div className="FacilitatorPlayerPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="FacilitatorPlayerPopup-header">
					<div className='FacilitatorPlayerPopup-playerInfo'>
						<div className='FacilitatorPlayerPopup-playerId'>
							<div className='FacilitatorPlayerPopup-playerIdText'>
								{getText(facilitatorUiTexts.employeeNumber, languageId) + ': '}
								<span>{playerId}</span>
							</div>
							<div className='FacilitatorPlayerPopup-deleteButton'>
								<Button
									text={getText(adminUiTexts.delete, languageId)}
									classes={['default', 'togglePlayerStatus']}
									onClick={() => {setShowPlayerDeletePopup(true);}}
								/>
							</div>
							<div className='FacilitatorPlayerPopup-renameButton'>
								<Button
									text={getText(facilitatorUiTexts.renamePlayer, languageId)}
									classes={['default', 'togglePlayerStatus']}
									onClick={() => {setShowPlayerRenamePopup(true);}}
								/>
							</div>
						</div>
						<div className='FacilitatorPlayerPopup-lastLogin'>
							{getText(facilitatorUiTexts.lastLogin, languageId) + ': ' + lastLogin}
						</div>
						<div className='FacilitatorPlayerPopup-status'>
							<div className='FacilitatorPlayerPopup-statusLabel'>
								<span>{getText(facilitatorUiTexts.status, languageId) + ': '}</span>
								<span>{getText(facilitatorUiTexts[status], languageId)}</span>
							</div>
							<div className='FacilitatorPlayerPopup-statusBtn'>
								<Button 
									classes={['default', 'togglePlayerStatus']}
									text={statusBtnTxt}
									onClick={() => {togglePlayerStatus();}}
								/>
							</div>
						</div>
					</div>
					<div className='FacilitatorPlayerPopup-modules'>
						<div className='FacilitatorPlayerPopup-modulesSelectWrapper'>
							<div className='FacilitatorPlayerPopup-modulesLabel'>
								{getText(facilitatorUiTexts.moduleGroup, languageId)}:
							</div>
							<div className='FacilitatorPlayerPopup-dropdown'>
								<Select 
									type="facilitator-player-popup"
									isDisabled={false}
									languageId={languageId}
									defaultId={selectedModuleGroupId}
									selectedId={selectedModuleGroupId}
									options={scenarioData.moduleGroups}
									onSelect={setSelectedModuleGroupId}
								/>
							</div>
							<div className='FacilitatorPlayerPopup-modulesResetButton'>
								<Button
									text={getText(loginUiTexts.reset, languageId)}
									classes={['default', 'togglePlayerStatus']}
									onClick={() => {setShowModuleResetPopup(true);}}
								/>
							</div>
						</div>
					</div>
					<div
						className="FacilitatorPlayerPopup-closeBtn" 
						onClick={() => {togglePopup();}}
					/>
				</div>

				{/* Module header */}
				<div className='FacilitatorPlayerPopup-moduleHeader'>
					<div className='FacilitatorPlayerPopup-moduleTitle'>
						<p>{(selectedModuleGroupData ? getText(selectedModuleGroupData.title, languageId) : '')}</p>
						<div className='FacilitatorPlayerPopup-moduleStars'>
							{filledStars.map((filled, index) => {
								return <Star key={index} page='facilitator' isFilled={filled}/>;
							})}
						</div>
					</div>
					<div className='FacilitatorPlayerPopup-progress'>
						<span>{getText(facilitatorUiTexts.completion, languageId)}</span>
						<span>{taskCompletion + '%'}</span>
					</div>
					<div className='FacilitatorPlayerPopup-timeSpent'>
						<span>{getText(facilitatorUiTexts.timeSpent, languageId)}</span>
						<span>{timePlayed + ' ' + getText(facilitatorUiTexts.minutes, languageId)}</span>
					</div>
					<div className='FacilitatorPlayerPopup-mistakes'>
						<span>{getText(facilitatorUiTexts.avgMistakes, languageId)}</span>
						<span>{avgErrors}</span>
					</div>
				</div>

				{/* Tasks header & body */}
				<div className='FacilitatorPlayerPopup-tasksHeader'>
					<div>{getText(facilitatorUiTexts.task, languageId)}</div>
					<div>{getText(facilitatorUiTexts.type, languageId)}</div>
					<div>{getText(facilitatorUiTexts.mistakes, languageId)}</div>
					<div>{getText(facilitatorUiTexts.attempts, languageId)}</div>
				</div>
				<div className='FacilitatorPlayerPopup-tasksBody'>
					{selectedModuleGroupData && 					
						selectedModuleGroupData.moduleIds.map((moduleId) => {
							const moduleData = scenarioData.modulesData.find((moduleData) => {
								return moduleData.id === moduleId;
							});
							const playerModuleData = (playerData ? getPlayerModuleData(moduleId, playerData) : null);
							return (
								moduleData.tasks.map((taskData) => {
									if (!taskData.isSolveToContinue) return null;
									let numberOfErrors = 0;
									let timesPlayed = 0;
									if (
										playerModuleData && 
										playerModuleData.sessions &&
										playerModuleData.sessions.length > 0
									) {
										playerModuleData.sessions.forEach((session) => {
											if (session.tasks) {
												const playerTaskData = session.tasks.find((t) => {
													return t.taskId === taskData.taskId;
												});
												if (playerTaskData && playerTaskData.hasOwnProperty('errors')) {
													numberOfErrors += playerTaskData.errors;
													timesPlayed += 1;
												}
											}
										});
									}
		
									const avgErrors = (timesPlayed > 0 
										? (numberOfErrors / timesPlayed).toFixed(1)
										: '-'
									);
									return ( 
										<div key={taskData.id} className="FacilitatorPlayerPopup-row">  
											<div className='FacilitatorPlayerPopup-rowContent'>{taskData.id}</div>
											<div className='FacilitatorPlayerPopup-rowContent'>{taskData.type}</div>
											<div className='FacilitatorPlayerPopup-rowContent'>{avgErrors}</div>
											<div className='FacilitatorPlayerPopup-rowContent'>{timesPlayed}</div>
										</div>
									);		
								})
							);
						})
					}
				</div>
			</div>

			{/* Reset player popup */}
			{showResetPlayerPopup && 
				<div className="FacilitatorPlayerPopup-resetPlayerPopup" onClick={(e) => {e.stopPropagation();}}>
					<div className="FacilitatorPlayerPopup-resetPlayerPopupContent">
						<div className="FacilitatorPlayerPopup-resetPlayerPopupText">
							{renderMarkdown(getText(facilitatorUiTexts.resetPlayerPopup.text, languageId))}
						</div>
						<div className="FacilitatorPlayerPopup-resetPlayerPopupButtons">
							<Button 
								classes={['facilitator']}
								text={getText(facilitatorUiTexts.resetPlayerPopup.noBtn, languageId)}
								onClick={() => {reactivatePlayer(false);}}
							/>
							<Button 
								classes={['facilitator']}
								text={getText(facilitatorUiTexts.resetPlayerPopup.yesBtn, languageId)}
								onClick={() => {reactivatePlayer(true);}}
							/>
							<Button 
								classes={['facilitator']}
								text={getText(facilitatorUiTexts.resetPlayerPopup.cancelBtn, languageId)}
								onClick={() => {setShowResetPlayerPopup(false);}}
							/>
						</div>
					</div>
				</div>
			}

			{/* Reset player module popup */}
			{showModuleResetPopup && selectedModuleGroupData &&
				<Popup
					type={'facilitator'}
					title={getText(facilitatorUiTexts.resetPlayerModule.title, languageId)}
					text={JSON.parse(JSON.stringify(getText(facilitatorUiTexts.resetPlayerModule.text, languageId)))
						.replace('%moduleGroup%', selectedModuleGroupData.title[languageId])}
					togglePopup={() => {setShowModuleResetPopup(false);}}
					buttons={[
						{
							isDisabled: isLoadingReset,
							isLoading: isLoadingReset,
							classes: ['login', 'small'],
							text: getText(facilitatorUiTexts.resetPlayerModule.cancelBtn, languageId),
							action: () => {setShowModuleResetPopup(false);}
						},
						{
							isDisabled: isLoadingReset,
							isLoading: isLoadingReset,
							classes: ['login', 'small'],
							text: getText(facilitatorUiTexts.resetPlayerModule.confirmBtn, languageId),
							action: resetModule
						}
					]}
				/>
			}

			{/* Rename player popup */}
			{showPlayerRenamePopup &&
				<RenamePlayerPopup 
					languageId={languageId}
					playerData={playerData}
					closePopup={() => {setShowPlayerRenamePopup(false);}}
					togglePlayerPopup={togglePopup}
				/>
			}

			{/* Delete player popup */}
			{showPlayerDeletePopup &&
				<Popup
					type={'facilitator'}
					title={getText(facilitatorUiTexts.deletePlayer.title, languageId)}
					text={JSON.parse(JSON.stringify(getText(facilitatorUiTexts.deletePlayer.text, languageId)))
						.replace('%playerId%', playerId)}
					togglePopup={() => {setShowPlayerDeletePopup(false);}}
					buttons={[
						{
							isDisabled: isLoadingDelete,
							isLoading: isLoadingDelete,
							classes: ['login', 'small'],
							text: getText(facilitatorUiTexts.deletePlayer.cancelBtn, languageId),
							action: () => {setShowPlayerDeletePopup(false);}
						},
						{
							isDisabled: isLoadingDelete,
							isLoading: isLoadingDelete,
							classes: ['login', 'small'],
							text: getText(facilitatorUiTexts.deletePlayer.confirmBtn, languageId),
							action: deletePlayer
						}
					]}
				/>
			}
		</div>
	);
};

FacilitatorPlayerPopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	players: PropTypes.array.isRequired,
	selectedPlayerId: PropTypes.string.isRequired,
	updatePlayer: PropTypes.func.isRequired,
	togglePopup: PropTypes.func.isRequired,
};

export default FacilitatorPlayerPopup;
