import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import {playerUiTexts} from 'data/ui-texts/player-ui-texts';
import Audio from 'components/ui/audio/audio';
import './task-intro.scss';

const TaskIntro = (props) => {
	/* Props */
	const {
		languageId, 
		moduleId, 
		showNumberOfAnswersToSelect, 
		numberOfCorrectAnswers, 
		text, 
		image, 
		audioData
	} = props;

	/* Show number of answers to select (multiple choice) */
	const showRequiredNumberOfAnswers = (
		showNumberOfAnswersToSelect && 
		numberOfCorrectAnswers && 
		(numberOfCorrectAnswers > 0) ? true : false
	);

	return (
		<div className={'TaskIntro' + (image ? ' ' + image : '')}>
			<div className="TaskIntro-text">
				{text && renderMarkdown(text)}
				{showRequiredNumberOfAnswers && 
					<span>
						{getText(playerUiTexts.choose, languageId)} {numberOfCorrectAnswers + ':'} 
						{/* {(numberOfCorrectAnswers > 1 
							? getText(playerUiTexts.options, languageId) 
							: getText(playerUiTexts.option, languageId)
						)}: */}
					</span>
				}
			</div>
			{image && <div className="TaskIntro-image" />}
			<div className="TaskIntro-audio">
				<Audio 
					type='task-intro'
					languageId={languageId}
					moduleId={moduleId}
					audioData={audioData}
				/>
			</div>
		</div>
	);
};

TaskIntro.propTypes = {
	languageId: PropTypes.string.isRequired,
	moduleId: PropTypes.string.isRequired,
	showNumberOfAnswersToSelect: PropTypes.bool,
	numberOfCorrectAnswers: PropTypes.number,
	text: PropTypes.string,
	image: PropTypes.string,
	audioData: PropTypes.object.isRequired
};

export default TaskIntro;
