// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const gameUiTexts = {

	paperDollPopup: {
		text1: {
			da: 'Du fik alle rigtige ting med!',
			en: 'You had them all right!',
			pl: 'Miałeś wszystkie poprawnie.',
			de: 'Du hattest sie in Ordnung!'
		},
		text2: {
			da: 'Du valge %correctItems% rigtige ting.<br /><br />Du glemte: ',
			en: 'You had %correctItems% right.<br /><br />You forgot: ',
			pl: 'Miałeś %correctItems% poprawnie.<br /><br />Zapomniałeś: ',
			de: 'Du hattest %correctItems% recht.<br /><br />Du hast vergessen:'
		},
		notNeeded: {
			da: 'Du har ikke brug for: ',
			en: 'You do not need: ',
			pl: 'Nie potrzebujesz: ',
			de: 'Du brauchst nicht: '
		}
	}
};

module.exports = {
	gameUiTexts
};