import './gradient-frame.scss';

const GradientFrame = () => {

	return (
		<div className="GradientFrame" />
	);
};



export default GradientFrame;
