const taskTemplates = require('data/templates/task-templates');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-2-instructions-welcome',
		text: {
			da: `Velkommen til hygiejnetræning B. Nu skal det handle om hygiejne i selve produktionen.`,
			en: `Welcome to hygiene training B. Now it will be about hygiene in the production.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-2-instructions-dos-and-donts',
		text: {
			da: `Det handler om, hvordan du skal håndtere produkter i produktionen. Altså hvad man må gøre, og hvad man ikke må gøre i produktionen.`,
			en: `It is about how you should handle the products in the production. In other words, what you can and connot do in the production.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-2-instructions-stars',
		text: {
			da: `Lad os kaste os ud i det. Husk, du skal have tre stjerner for at gennemføre modulet.`,
			en: `Let's dive into it. Remember you need three stars to complete the module.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'production-2-mc-hygiene-extra-careful',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne i produktionen?`,
			en: `Why should we pay extra attention to hygiene in the production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `På grund af lovkrav`,
					en: `Due to legal requirements`
				}				
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`,
					en: `Because poor hygiene can make people sick`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all the above reasons`
				},
				isCorrect: true
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-2-instructions-hygiene-issues',
		text: {
			da: `Lad os kigge nærmere på produktionen. På næste billede skal du spotte de ting, som er problematiske i forhold til hygiejne.`,
			en: `Let's take a closer look at the production. On the next picture, you must spot the things that are problematic in terms of hygiene.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'production-2-spot-errors-production',
		layout: 'production-2-production-hall',
		text: {
			da: `Tryk på de 4 fejl.<br /><br />Tryk OK når du mener, du har dem alle.`,
			en: `Press on the 4 mistakes.<br /><br />Press OK when you believe you have found them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'drop', // Dråbe hænger ned fra rør over produktionsbånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'lamp', // En lampe er i stykker
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'glove-piece', // Stykke fra handske på båndet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hammer', // Hammer on floor
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'production-2-mc-water-drip',
		text: {
			da: `Hvis der drypper fx kondensvand ned fra rør, så skal produkterne fjernes. Hvorfor?`,
			en: `If, for example, condensation water drips down from pipes, the products must be removed. Why?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Det kan forurene de efterfølgende produkter`,
					en: `It can contaminate the following products`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Produkterne må gerne blive på båndet`,
					en: `The products may remain on the belt`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Der er risiko for at forbrugerne bliver syge`,
					en: `There is a risk that consumers may become ill`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kondens kan gøre kødet sejt`,
					en: `Condensation can make the meat tough`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'production-2-mc-trays-carts',
		layout: 'production-2-trays-carts',
		subtype: 'images',
		text: {
			da: `Hvilke vogne, kar og bakker er fyldt korrekt?`,
			en: `Which carts, tubs and trays are filled correctly?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Vogn der er overfyldt
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Bakke der er fyldt til under kanten
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Bakke der er fyldt med top
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Vogn fyldt lige til kanten
				isCorrect: true
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-2-instructions-ask-master',
		text: {
			da: `Du er tæt på produktionen hver dag, og derfor lægger du garanteret mærke til ting, som andre overser. Og hvis du er i tvivl, er det altid bedre at spørge en mester.`,
			en: `You are close to production every day, and therefore you are guaranteed to notice things that others overlook. And if in doubt, it's always better to ask a manager.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-2-instructions-contamination',
		text: {
			da: `Nu skal vi kigge lidt på forurening. Det handler grundlæggende om, at der kommer ting i produkterne, som ikke skal være der.`,
			en: `Now let's take a look at contamination. It's basically about things getting into the products that shouldn't be there.`
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'production-2-order-broken-lamp',
		text: {
			da: `En lampe går i stykker i produktionen. Der er risiko for, der er kommet fremmedlegmer i produkterne. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge.`,
			en: `A lamp breaks in the production. There is a risk that foreign bodies have entered the products. What should you do? Put the actions below in the correct order.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
                id: 1, 
                text: {
                    da: `Stop produktionen`,
                    en: `Stop the production`
                }, 
                correctOrderNumbers: [1]
            }),
            Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
                id: 2, 
                text: {
                    da: `Tilkald mester`,
                    en: `Call for a manager`
                }, 
                correctOrderNumbers: [2]
            }),

			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Afvent frigivelse fra mester`,
					en: `Await release from manager`
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Fortsæt arbejdet`,
					en: `Continue working`
				}, 
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'production-2-mc-product-contamination',
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: `Our products can become contaminated in various ways. Why is contamination a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Det kan være farligt`,
					en: `It can be dangerous`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Det kan ødelægge smagen`,
					en: `It can spoil the taste`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `This can result in dissatisfied customers`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Det udleder mere CO2`,
					en: `It emits more CO2`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Forurening er kun et problem, hvis inspektører ser det`,
					en: `Contamination is only a problem if inspectors see it`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Det kan gøre vores tøj beskidt`,
					en: `It can make our clothes dirty`
				},
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'production-2-mc-allergies',
		subtype: 'images',
		layout: 'production-allergies-2',
		text: {
			da: `Forurening kan være et problem for folk med allergi. Klik på to allergener.`,
			en: `Contamination can be a problem for people with allergies. Click on two allergens.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Peanuts
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Strawberries
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Flour
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Apples
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // Pineapple
			}),
		]
	}),


	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-2-instructions-hygiene-quality',
		text: {
			da: `God hygiejne og dermed høj kvalitet starter med dig. Se om du kan spotte problemerne på næste billede.`,
			en: `Good hygiene and thus high quality starts with you. See if you can spot the problems in the next picture.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'production-2-spot-errors-production-2',
		layout: 'production-2-production-hall-2',
		text: {
			da: `Tryk på de 4 fejl.<br /><br />Tryk OK når du mener, du har fundet dem alle.`,
			en: `Press the 4 errors.<br /><br />Press OK when you think you've found them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'drop', // Dråbe hænger ned fra rør over produktionsbånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'screw', // Skrue eller møtrik på båndet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'soap', // Sæbeskum på transportbånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hammer', // Hammer på gulvet
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-2-instructions-pests',
		text: {
			da: `Vi mangler en sidste ting. Et af de helt store problemer i forhold til hygiejne er, hvis der er skadedyr.`,
			en: `We're missing one last thing. One of the biggest problems in relation to hygiene is if there are pests.`
		}
	}),	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'production-2-mc-mouse',
		text: {
			da: `Du spotter en mus på gulvet i produktionen. Hvad skal du gøre?`,
			en: `You notice a mouse on the production floor. What should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Forsøge at holde øje med musen imens jeg tilkalder hjælp`,
					en: `Try to keep an eye on the mouse while I call for help`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Forsøge at fange musen selv og derefter tilkalde hjælp, hvis det ikke kan lade sig gøre`,
					en: `Try to catch the mouse yourself and then call for help if that is not possible`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Sige det til nærmeste leder efter endt arbejdsdag`,
					en: `Tell the immediate manager after the end of the working day`
				}
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'production-2-instructions-finish',
		text: {
			da: `Sådan, du er nu færdig med andet modul. Hvis du har fået tre stjerner, er du færdig med hygiejnetræningen for denne gang. Hvis ikke, så må du tage modulet en gang til.`,
			en: `That's it, you have now finished the second module. If you got three stars, you are done with the hygiene training for this time. If not, then you must take the module again.`
		}
	})
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'production-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};