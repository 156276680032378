const modulePersonalHygiene = [
	'backgrounds/dc-sunrise-1.svg',
	'backgrounds/dc-sunrise-2.svg',
	'backgrounds/dc-sunrise-3.svg',
	'backgrounds/dc-sunrise-4.svg',
	'backgrounds/dc-sunrise-5.svg',

	'modules/tasks/image/personal-hygiene-lockers.svg',
	
	'modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-1.svg',
	'modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-2.svg',
	'modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-3.svg',
	'modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-4.svg',
	'modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-5.svg',
	'modules/tasks/multiple-choice/personal-hygiene-jewelry/option-1.svg',
	'modules/tasks/multiple-choice/personal-hygiene-jewelry/option-2.svg',
	'modules/tasks/multiple-choice/personal-hygiene-jewelry/option-3.svg',
	'modules/tasks/multiple-choice/personal-hygiene-jewelry/option-4.svg',
	'modules/tasks/multiple-choice/personal-hygiene-jewelry/option-5.svg',
	'modules/tasks/multiple-choice/personal-hygiene-lockers/background.svg',
	'modules/tasks/multiple-choice/personal-hygiene-lockers/option-1.svg',
	'modules/tasks/multiple-choice/personal-hygiene-lockers/option-2.svg',
	'modules/tasks/multiple-choice/personal-hygiene-lockers/option-3.svg',
	'modules/tasks/multiple-choice/personal-hygiene-lockers/option-4.svg',
	'modules/tasks/multiple-choice/personal-hygiene-lockers/option-5.svg',
	'modules/tasks/multiple-choice/personal-hygiene-lockers/option-6.svg',
	'modules/tasks/multiple-choice/personal-hygiene-nails/option-1.svg',
	'modules/tasks/multiple-choice/personal-hygiene-nails/option-2.svg',
	'modules/tasks/multiple-choice/personal-hygiene-nails/option-3.svg',
	'modules/tasks/multiple-choice/personal-hygiene-nails/option-4.svg',

	'modules/tasks/spot-errors/personal-hygiene-dressing-room/background.svg',
];

export {
	modulePersonalHygiene
};