const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');


let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-a-instructions-welcome',
		background: 'dressing-room',
		text: {
			da: `Velkommen til Gentræning 2A. Jeg er din mester, og guider dig igennem spillet.`,
			en: `Welcome to re-training 2A. I am your manager and will guide you through the game.`,
			pl: `Witamy w module przypominającym 2A. Jestem twoim kierownikiem i poprowadzę Cię przez grę.`,
			de: `Willkommen zur jährlichen Wiederholungsschulung 2A. Ich bin dein Vorgesetzter und werde dich durch das Spiel führen.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-a-instructions-points',
		background: 'dressing-room',
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In the game you get points for solving tasks. The fewer mistakes you make, the more points you get.`,
			pl: `W grze otrzymujesz punkty za rozwiązywanie zadań. Im mniej błędów popełnisz, tym więcej punktów otrzymasz.`,
			de: `Im Spiel bekommt man Punkte für das Lösen von Aufgaben. Desto weniger Fehler du machst, desto mehr Punkte erhältst du.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-a-instructions-stars',
		background: 'dressing-room',
		text: {
			da: `Når du får point kan du også få stjerner. Se hvor mange point og stjerner du har i toppen af skærmen.`,
			en: `When you get points, you also get stars. See how many points and stars you have at the top of the screen.`,
			pl: `Kiedy zdobywasz punkty, otrzymujesz również gwiazdki. Na górze ekranu możesz zobaczyć, ile punktów i gwiazdek masz.`,
			de: `Wenn du Punkte erhältst, bekommst du auch Sterne. Oben auf dem Bildschirm siehst du, wie viele Punkte und Sterne du gesammelt hast.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-a-instructions-required-stars',
		background: 'dressing-room',
		text: {
			da: `Husk du skal have tre stjerner for at gennemføre modulet.`,
			en: `Remember you must have three stars to complete the module.`,
			pl: `Pamiętaj, że aby ukończyć moduł, musisz mieć trzy gwiazdki.`,
			de: `Denke daran, dass du drei Sterne haben musst, um das Modul abzuschließen.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-a-instructions-clothes',
		background: 'dressing-room',
		text: {
			da: `Vi starter med påklædning. På næste billede skal du vælge de ting, du skal have på.`,
			en: `We start with clothing. In the next picture, choose the things you must wear.`,
			pl: `Zaczynamy od odzieży. Na następnym obrazku wybierz rzeczy, które musisz założyć.`,
			de: `Wir beginnen mit der Kleidung. Wähle im nächsten Bild die Dinge aus, die du tragen musst.`
		}
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		taskId: 'repetition-2-a-paper-doll-items',
		background: 'dressing-room',
		showProgressBar: true,
		text: {
			da: `Vælg de 3 ting du skal have på, inden du går ud i produktionen.`,
			en: `Choose the 3 things you must wear before entering the production.`,
			pl: `Wybierz 3 rzeczy, które musisz założyć przed wejściem do produkcji.`,
			de: `Wähle die 3 Dinge aus, die du tragen musst, bevor du die Produktion betreten darfst`
		},
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hairnet',
				isCorrect: true,
				type: 'alternative',
				title: {
					da: 'Hårnet',
					en: 'hairnet',
					pl: 'Siatka na włosy',
					de: 'Haarnetz',
				}
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'earplugs',
				isCorrect: true,
				type: 'alternative',
				title: {
					da: 'Ørepropper med snor',
					en: 'Earplugs with cord',
					pl: 'Zatyczki do uszu ze sznurkiem',
					de: 'Ohrstöpsel mit Kordel'
				}
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'cutting-glove',
				title: {
					da: 'Skærehandske',
					en: 'Whizard glove',
					pl: 'Rękawica do cięcia',
					de: 'Schneidhandschuh'
				}
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'earplugs-wireless',
				type: 'alternative',
				title: {
					da: 'Ørepropper uden snor',
					en: 'Earplugs without cord',
					pl: 'Zatyczki do uszu bez sznurka',
					de: 'Ohrstöpsel ohne Kabel'
				}
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'watch',
				title: {
					da: 'Armbåndsur',
					en: 'Wrist watch',
					pl: 'Zegarek na rękę',
					de: 'Armbanduhr'
				}
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'coat',
				isCorrect: true,
				type: 'alternative',
				title: {
					da: 'Hvid kittel',
					en: 'White smock',
					pl: 'Biały fartuch',
					de: 'Weißer Kittel'
				}
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'phone',
				type: 'alternative',
				title: {
					da: 'Mobiltelefon',
					en: 'Cell phone',
					pl: 'Komórka',
					de: 'Mobíltelefon'
				}
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-a-mc-hair-and-beard',
		background: 'dressing-room',
		layout: 'personal-hygiene-hair-and-beard',
		subtype: 'images',
		text: {
			da: `Hvordan skal hårnet og skægbind sidde? <br />Tryk på de rigtige billeder.`,
			en: `How should you correctly wear hair net and beard mask? <br />Click on the correct pictures.`,
			pl: `Jak prawidłowo nosić siatkę na włosy i maskę na brodę? <br />Kliknij na odpowiednie zdjęcia.`,
			de: `Wie trägt man das Haarnetz und den Bartschutz richtig? Klicke auf die richtigen Bilder.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4				
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5		
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-a-mc-labcoat',
		background: 'dressing-room',
		layout: 'repetition-2-a-labcoat',
		subtype: 'images',
		text: {
			da: `Tryk på den rigtige kittel.`,
			en: `Press the correct smock.`,
			pl: `Wybierz na zdjęciu właściwy fartuch.`,
			de: `Klicke auf den richtigen Kittel.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Nemlig. Kitlen skal være lukket, den skal dække dit almindelige tøj og så skal den være ren.`,
								en: `Correct. The smock must be closed, it must cover your normal clothes and it must be clean.`,
								pl: `Poprawnie. Fartuch musi być zapięty, musi zakrywać normalne ubranie i musi być czysty.`,
								de: `Richtig. Der Kittel muss geschlossen sein, die normale Kleidung bedecken und sauber sein.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4				
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-a-sort-lockers',
		background: 'dressing-room',
		layout: 'repetition-2-a-lockers',
		subtype: 'images',
		text: {
			da: `I omklædningen er der skabe til private ting og steder til arbejdsting. Men hvilke ting skal IKKE i skabet til privat tøj?`,
			en: `In the changing room, there are closets for private things and places for work things. But which things should NOT be in the closet for private clothes?`,
			pl: `W szatni znajdują się szafki na rzeczy prywatne oraz miejsca na rzeczy do pracy. Ale jakie rzeczy NIE powinny znajdować się w szafce na ubrania prywatne?`,
			de: `In der Umkleidekabine gibt es Schränke für Privates und Plätze für Arbeitssachen. Doch welche Dinge gehören bei privater Kleidung NICHT in den Schrank?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // beard net
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // knife
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // cutting glove
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // hair net
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // white work shoes
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // private shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // safety glasses
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8, // thermo jacket
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'repetition-2-a-order-wash-hands',
		background: 'dressing-room',
		text: {
			da: `I hygiejnesluser skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `In hygiene sluice, you must always wash your hands. What is the right order to do things in?`,
			pl: `W śluzach higienicznych należy zawsze myć ręce. Jaka jest właściwa kolejność wykonywania czynności?`,
			de: `In der Hygieneschleuse musst du dir immer die Hände waschen. Was ist hierbei die richtige Reihenfolge?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Vask hænder`,
					en: `Wash hands`,
					pl: `Umyj ręce`,
					de: `Hände waschen`
				}, 
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Tør hænder`,
					en: `Dry hands with paper towel`,
					pl: `Wytrzyj ręce ręcznikiem papierowym`,
					de: `Hände mit Handtuchpapier trocknen`
				}, 
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Smid håndklædepapir i skraldepose`,
					en: `Throw paper towels in the garbage bag`,
					pl: `Ręcznik papierowy wrzuć do worka na śmieci`,
					de: `Papierhandtücher in den Müllsack werfen`
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Brug håndsprit`,
					en: `Use hand sanitizer`,
					pl: `Użyj sanityzera rąk`,
					de: `Nutzung des Handdesinfektionsmittels`
				}, 
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-a-instructions-before-production',
		background: 'dressing-room',
		text: {
			da: `Vi er næsten klar til at gå ud i produktionen, men lad os lige se om vi er helt klar. Se hvad du kan finde af fejl på det næste billede.`,
			en: `We're almost ready to go into the production, but let's see if we're all set. See what mistakes you can find in the next picture.`,
			pl: `Jesteśmy prawie gotowi do rozpoczęcia produkcji, ale zobaczmy, czy wszystko jest gotowe. Zobacz, jakie błędy możesz znaleźć na kolejnym obrazku.`,
			de: `Wir sind fast bereit, in die Produktion zu gehen, aber mal sehen, ob wir fertig sind. Siehst du, welche Fehler im nächsten Bild zu finden sind?`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'repetition-2-a-spot-errors-person',
		layout: 'repetition-2-a-person',
		background: 'dressing-room',
		text: {
			da: `Tryk på de 4 fejl.<br /><br />Tryk OK når du tror, du har fundet dem alle.`,
			en: `Press on the 4 errors.<br /><br />Press OK when you think you've found them all.`,
			pl: `Znajdź 4 błędy.<br /><br />Naciśnij OK, gdy uważasz, że znaleziono wszystkie.`,
			de: `Klicke auf die 4 Fehler.<br /><br />Bestätige mit OK, wenn du glaubst, dass du sie alle gefunden hast.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'watch',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'beard',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'homeshirt',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'cola',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-a-instructions-ready-production',
		background: 'slaughterhouse',
		text: {
			da: `Så er vi klar til at gå ud i produktionen.`,
			en: `Then we are ready to go into the production.`,
			pl: `Jesteśmy gotowi, aby przejść do produkcji.`,
			de: `Jetzt sind wir bereit, in die Produktion zu gehen.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-a-mc-always-do',
		background: 'slaughterhouse',
		text: {
			da: `Hvad skal du altid gøre lige inden du går i gang med at arbejde?`,
			en: `What should you always do right before you start working?`,
			pl: `Co zawsze powinieneś robić tuż przed rozpoczęciem pracy?`,
			de: `Was du immer tun solltest, bevor du mit der Arbeit beginnst.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Tjekke at mit arbejdstøj og udstyr ikke er i stykker`,
					en: `Check that my work clothes and equipment are whole and with out holes`,
					pl: `Sprawdź, czy odzież robocza i sprzęt są całe i bez dziur`,
					de: `Überprüfen, ob meine Arbeitskleidung und Ausrüstung unversehrt und ohne Löcher sind`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Give mester en high five`,
					en: `Give your manager a high five`,
					pl: `Przybij piątkę swojemu kierownikowi`,
					de: `Gib deinem Vorgesetzten ein High Five`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Synge Danish Crown-sangen`,
					en: `Sing the Danish Crown-song`,
					pl: `Zaśpiewaj duńską pieśń koronną`,
					de: `Singe das Danish Crown Lied`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Vaske hænder`,
					en: `Wash hands`,
					pl: `Umyj ręce`,
					de: `Hände waschen`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Det har du lige gjort i hygiejneslusen.`,
								en: `not wrong but you just did that in the hygiene sluice.`,
								pl: `nie jest złą opcją, ale właśnie to zrobiłeś w śluzie higienicznej.`,
								de: `Nicht falsch, aber das du gerade in der Hygieneschleuse gemacht.`
							}
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'repetition-2-a-spot-errors-person2',
		layout: 'repetition-2-1a-person2',
		background: 'slaughterhouse',
		text: {
			da: `Se om du kan spotte de 4 fejl på udstyret her.<br /><br />Tryk på dem og tryk så ok når du mener du har dem alle.`,
			en: `See if you can spot the 4 errors on the equipment here.<br /><br />Press on them and then press ok when you think you have them all.`,
			pl: `Sprawdź, czy możesz znaleźć 4 błędy dotyczące odpowiedniego wyposażenia/ubioru w pracy.<br /><br />Zaznacz je, a następnie naciśnij OK, gdy uważasz, że znaleziono wszystkie.`,
			de: `Gucke hier, ob du die 4 Fehler an der Ausrüstung erkennen kannst.<br /><br />Klicke darauf und bestätige mit OK, wenn du glaubst, dass du sie alle gefunden hast.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'apron', // hole in apron
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'glove',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'pants',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'sleeve',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-a-instructions-hygiene-important',
		background: 'slaughterhouse',
		text: {
			da: `Hygiejne er ekstremt vigtigt i produktionen. Og fordi du er tæt på produktet er du den første der kan lægge mærke til problemer.`,
			en: `Hygiene is extremely important in the production. And because you are close to the product, you are the first to notice problems.`,
			pl: `Higiena jest niezwykle ważna w produkcji. A ponieważ jesteś blisko produktu, jako pierwszy zauważasz problemy.`,
			de: `Hygiene in der Produktion ist extrem wichtig. Und weil du nah am Produkt bist, bemerkst du Probleme oft zuerst.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-a-mc-spot-problem',
		background: 'slaughterhouse',
		text: {
			da: `Hvad er den gyldne regel hvis du spotter noget, som måske er et problem?`,
			en: `What is the golden rule if you spot something that might be a problem?`,
			pl: `Jaka jest złota zasada, jeśli zauważysz coś, co może stanowić problem?`,
			de: `Was ist die goldene Regel, wenn du etwas entdeckst, das ein Problem sein oder werden könnte?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Stands produktionen`,
					en: `Stop production`,
					pl: `Zatrzymaj produkcję`,
					de: `Produktion stoppen`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Skriv en note`,
					en: `Write a note`,
					pl: `Napisz notatkę`,
					de: `Schreibe eine Notiz`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Smid alt kød i nærheden af problemet ud`,
					en: `Throw out any meat near the problem`,
					pl: `Wyrzuć mięso w pobliżu problemu`,
					de: `Wirf Fleisch in der Nähe des Problems weg`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Spørg mester`,
					en: `Ask the manager`,
					pl: `Zapytaj kierownika`,
					de: `Frage Deinen Vorgesetzten`
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Lige præcis! Hvis du er i tvivl, så skal du bare spørge mester.`,
								en: `Exactly! If in doubt, just ask the manager.`,
								pl: `Dokładnie! W razie wątpliwości po prostu zapytaj kierownika.`,
								de: `Genau! Im Zweifelsfall einfach deinen Vorgesetzten fragen!`
							}
						})
					})
				]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-a-instructions-finish',
		background: 'slaughterhouse',
		text: {
			da: `Godt gået! 
				<br />Du er nu igennem første del af gentræningen. Hvis du har fået tre stjerner, kan du fortsætte til næste modul. Hvis ikke, så må du tage modulet en gang til.
				<br />Er du i tvivl om noget, så husk at spørge kursuslederen eller din mester.`,
			en: `Well done!
				<br />You are now through the first part of the re-training. If you have received three stars, you can continue to the next module. If not, then you must take the module again. 
				<br />If you are in doubt about something, remember to ask the course leader or your manager.`,
			pl: `Dobrze zrobione!
				<br />Zakończono teraz pierwszą część szkolenia przypominającego. Jeśli otrzymałeś/aś trzy gwiazdki, możesz przejść do następnego modułu. Jeśli nie, musisz ponownie przejść moduł.
				<br />Jeśli masz jakieś wątpliwości, pamiętaj, aby zapytać prowadzącego kurs lub swojego kierownika.`,
			de: `Gut gemacht! 
				<br />Du hast jetzt den ersten Teil dieser Schulung hinter dich gebracht. Wenn du drei Sterne erhalten hast, kannst du mit dem nächsten Modul fortfahren. Wenn nicht, musst du das Modul wiederholen.
				<br />Denke im Zweifelsfall daran, den Kursleiter oder deinen Vorgesetzten zu fragen.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'repetition-2-a-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};