import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

/**
 * Get audio src
 * @param {string} type 
 * @param {string} languageId 
 * @param {string} moduleId 
 * @param {object} audioData 
 */
export function getAudioSrc(type, languageId, moduleId, audioData) {
	const languageFolder = languageId;
	const moduleFolder = moduleId;
	let fileName = null;

	/* TYPE: task intro */
	if (type === 'task-intro') {
		fileName = languageFolder + '/' + moduleFolder + '/' +
			languageFolder + '-' + audioData.taskId + '.mp3';
	}

	/* TYPE: task option */
	if (type === 'task-option') {
		fileName = languageFolder + '/' + moduleFolder + '/' +
		languageFolder + '-' + audioData.taskId + '-' + audioData.optionId + '.mp3';
	}

	/* TYPE: feedback */
	if (type === 'feedback') {
		fileName = languageFolder + '/' + moduleFolder + '/' + languageId + '-' + audioData.audioId + '.mp3';
	}

	/* Get link to audio file */
	return new Promise((resolve) => {
		if (!fileName) resolve({status: 'error', error: 'no-such-file'});

		const storageRef = firebase.storage().ref();
		storageRef.child(fileName).getDownloadURL()
			.then((src) => {resolve({status: 'success', src: src});})
			.catch((error) => {resolve({status: 'error', error: error});});
	});
}