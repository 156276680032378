import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import FacilitatorHeader from './facilitator-header';
import FacilitatorPlayers from './facilitator-players';
import FacilitatorPlayerPopup from './facilitator-player-popup/facilitator-player-popup';
import './facilitator.scss';

const Facilitator = (props) => {
	const {
		languageId, 
		userData, 
		numberOfGames, 
		gameData, 
		handleSelectGame, 
		goToPage, 
		updatePlayer, 
		handleLogout
	} = props;

	/* Players of game */
	const [players, setPlayers] = useState([]);

	/* Selected player id */
	const [selectedPlayerId, setSelectedPlayerId] = useState(null);


	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */
		let unsubscribePlayers = null;

		if (gameData) {
			/* Subscribe to players */
			const db = firebase.firestore();
			const query = db.collection(appConfig.playersDbName).where('gameId', '==', gameData.id);
			unsubscribePlayers = query.onSnapshot((querySnapshot) => {
				let players = [];
				querySnapshot.forEach((doc) => {players.push({id: doc.id, ...doc.data()});});
				setPlayers(players);
			},
			(error) => {
				console.error('could not get players: ', error);
			});
		}
		
		return () => {
			/* Component will unmount */
			if (unsubscribePlayers) {
				/* Unsubscribe players */
				unsubscribePlayers();
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="Facilitator">
			<div className="Facilitator-header">
				<FacilitatorHeader 
					languageId={languageId}
					numberOfGames={numberOfGames}
					userData={userData}
					gameData={gameData}
					goToPage={goToPage}
					handleSelectGame={handleSelectGame}
					handleLogout={handleLogout}
				/>
			</div>
			<div className="Facilitator-content">				
				<FacilitatorPlayers 
					languageId={languageId}
					gameData={gameData}
					players={players}
					setSelectedPlayerId={setSelectedPlayerId}
				/>
			</div>
			{selectedPlayerId &&
				<FacilitatorPlayerPopup 
					languageId={languageId}
					gameData={gameData}
					players={players}
					selectedPlayerId={selectedPlayerId}
					updatePlayer={updatePlayer}
					togglePopup={() => {setSelectedPlayerId(null);}}
				/>
			}
		</div>
	);

};

Facilitator.propTypes = {
	languageId: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	numberOfGames: PropTypes.number.isRequired,
	gameData: PropTypes.object,
	handleSelectGame: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired,
	updatePlayer: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default Facilitator;
