import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {renderMarkdown} from 'helpers/text-helper';
import {playerUiTexts} from 'data/ui-texts/player-ui-texts';
import {spotErrorsMinusPoints} from 'data/points-data';
import {checkIfConditionsAreFulfilled} from 'helpers/effect-helper';
import GradientFrame from 'components/ui/gradient-frame/gradient-frame';
import Audio from 'components/ui/audio/audio';
import './spot-errors.scss';

const SpotErrors = (props) => {
	const {
		languageId,
		playerTaskData, 
		taskData, 
		moduleData,
		updateLoggedTime,
		handleInstantTaskEffects,
		handleCompleteTask
	} = props;

	var timeout = null;

	/* Check if completed already */
	let isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Track available and selected options */
	const [spottedErrorIds, setSpottedErrorIds] = useState([]);
	const [numberOfMisses, setNumberOfMisses] = useState(0);

	/* Shake screen animation */
	const [shakeScreen, setShakeScreen] = useState(false);

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	const getSpottedErrorIds = () => {
		let errorIds = [];
		if (playerTaskData && playerTaskData.spottedErrorIds) {
			errorIds = playerTaskData.spottedErrorIds;
		}
		return errorIds;
	};

	/* New task: update status and spotted errors */
	useEffect(() => {
		setSpottedErrorIds(getSpottedErrorIds());
		setNumberOfMisses(0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/* Component did mount / will unmount */
	useEffect(() => {
		return () => {
			if (timeout) clearTimeout(timeout);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Spot error
	 * @param {string} errorId 
	 * @returns 
	 */
	const spotErrorId = (errorId) => {
		/* Already completed */
		if (isCompleted === true) return;		

		/* Already spotted */
		if (spottedErrorIds.indexOf(errorId) >= 0) return;

		/* Update logged time */
		updateLoggedTime();

		/* Spot error */
		let newSpottedErrorIds = [...spottedErrorIds];
		newSpottedErrorIds.push(errorId);
		setSpottedErrorIds(newSpottedErrorIds);

		/* Effects */
		let instantEffects = [{type: 'streak', isCorrectAnswer: true}];
		const spottedErrorData = taskData.errors.find((error) => {return error.id === errorId;});
		if (spottedErrorData && spottedErrorData.effects && spottedErrorData.effects.length > 0) {
			spottedErrorData.effects.forEach((effect) => {
				/* Effect: feedback (instant) */
				if (effect.type === 'feedback') {
					instantEffects.push(effect);
				}
			});
		}

		if (taskData.doneWhenAllErrorsSpotted && newSpottedErrorIds.length === taskData.errors.length) {
			/* Complete task automatically */
			completeTask(instantEffects);
		} else {
			/* Instant effects */
			if (instantEffects.length > 0) handleInstantTaskEffects(instantEffects);
		}
	};

	/**
	 * Click on background
	 */
	const backgroundClick = () => {
		/* Already completed */
		if (isCompleted === true) return;		

		/* Shake screen */
		setShakeScreen(true);
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(() => {setShakeScreen(false);}, 500);

		/* Update misses count */
		const newNumberOfMisses = numberOfMisses + 1;
		setNumberOfMisses(newNumberOfMisses);
		
		/* Update streak */
		const instantEffects = [{type: 'streak', isCorrectAnswer: false}];
		handleInstantTaskEffects(instantEffects);		

		
	};

	/**
	 * Complete task
	 */
	const completeTask = (instantEffects = null) => {
		/* Prepare array of effects */
		let effects = (instantEffects ? [...instantEffects] : []);
		
		/* Errors */ 
		const errors = numberOfMisses; // (numberOfErrors - spottedErrors);

		/* Calculate points */
		let points = spottedErrorIds.length;
		if (points > 0 && errors > 0) {
			const errorIndex = spotErrorsMinusPoints.errorLimits.findIndex((limit) => {return errors >= limit;});
			if (errorIndex >= 0) {
				const minusPoints = spotErrorsMinusPoints.pointValues[errorIndex];
				points = Math.max(spotErrorsMinusPoints.minPoints, points - minusPoints);
			}
		}

		/* Result popup */
		const numberOfErrors = taskData.errors.length;
		const spottedErrors = spottedErrorIds.length;
		if (taskData.showDefaultResultPopup) {
			let resultTier = 1;
			if (spottedErrors / numberOfErrors > 0.333) resultTier = 2;
			if (spottedErrors / numberOfErrors > 0.667) resultTier = 3;
			const popupTitle = getText(playerUiTexts.spotErrorsPopup['title' + resultTier], languageId);
			const popupText = getText(playerUiTexts.spotErrorsPopup['text' + resultTier], languageId)
				.replace('%spottedErrors%', spottedErrors)
				.replace('%numberOfErrors%', numberOfErrors);
			effects.push({
				type: 'popup',
				popup: {type: 'spot-errors-result', title: popupTitle, text: popupText}
			});
		}



		/* Effects */
		if (taskData.doneEffects && taskData.doneEffects.length > 0) {
			taskData.doneEffects.forEach((effect) => {
				const conditionsAreFulfilled = checkIfConditionsAreFulfilled(effect, errors);
				if (conditionsAreFulfilled) {
					if (effect.type === 'points') {
						/* Effect: points */
						points += effect.value;
					} else {
						/* Effect: feedback, popup */
						effects.push(effect);
					}
				}
			});
		}
	
		/* Save completed task */
		handleCompleteTask(
			'spot-errors', 
			points, 
			errors, 
			effects,
			{spottedErrorIds: spottedErrorIds}
		);
	};

	return (
		<div className={'SpotErrors ' + taskData.layout 
			+ (isCompleted ?  ' completed' : '') + (shakeScreen ? ' shake' : '')}
		>
			<GradientFrame />
			<div className={'SpotErrors-errors ' + (taskData.image ? taskData.image : '')} 
				onClick={() => {backgroundClick();}}>
				{taskData.errors.map((error) => {
					return (
						<div 
							key={error.id} 
							className={'SpotErrors-error ' + error.id 
								+ (spottedErrorIds.indexOf(error.id) >= 0 ? ' spotted' : '')}
							onClick={(e) => {e.stopPropagation(); spotErrorId(error.id);}} 
						/>
					);
				})}
			</div>

			{taskData.text && <div className="SpotErrors-text">
				{renderMarkdown(getText(taskData.text, languageId))}
				<div className="SpotErrors-audio">
					<Audio 
						type='task-intro'
						languageId={languageId}
						moduleId={moduleData.id}
						audioData={{taskType: taskData.type, taskId: taskData.taskId}}
					/>
				</div>
			</div>}
			
			{(!isCompleted && !taskData.doneWhenAllErrorsSpotted) && 
				<div onClick={(e) => {e.stopPropagation(); completeTask();}} className="SpotErrors-confirmBtn">
					<span>{getText(playerUiTexts.ok, languageId)}</span></div>}
		</div>
	);
};

SpotErrors.propTypes = {
	languageId: PropTypes.string.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	moduleData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired
};

export default SpotErrors;
