const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-instructions-welcome',
		text: {
			da: `Velkommen til gentræning 2A. Det er det første af to moduler, du skal igennem i dag.`,
			en: `Welcome to retraining 2A. This is the first of two modules you will go through today.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-instructions-points',
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In the game you get points for solving tasks. The fewer mistakes you make, the more points you get.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-instructions-stars',
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har.`,
			en: `When you get points, you also get stars. See how many points and stars you have at the top of the screen.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-instructions-points-to-progress',
		text: {
			da: `Husk du skal have tre stjerner for at gennemføre modulet.`,
			en: `Remember you must have three stars to complete the module.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-instructions-first-task',
		text: {
			da: `Vi starter med påklædning.`,
			en: `We start with clothes. On the next picture, drag the things you need to wear onto the figure.`
		}
	}),
	Object.assign({}, taskTemplates.paperDollTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-paper-doll-items',
		background: 'dressing-room',
		text: {
			da: `Vælg de tre ting du skal have på, inden du går ind i produktionen.`,
			en: `Choose what to wear before entering the production.`
		},
		items: [
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'hairnet',
				isCorrect: true,
				type: 'alternative',
				title: {
					da: 'Hårnet',
					en: 'Hairnet'
				}
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'earplugs',
				isCorrect: true,
				type: 'alternative',
				title: {
					da: 'Ørepropper med snor',
					en: 'Earplugs with cord'
				}
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'white-shoes',
				isCorrect: true,
				type: 'alternative',
				title: {
					da: 'Hvide arbejdssko',
					en: 'White work shoes'
				}
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'glasses',
				title: {
					da: 'Briller med smykkesten',
					en: 'Glasses with gemstones'
				}
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'phone',
				type: 'alternative',
				title: {
					da: 'Mobiltelefon',
					en: 'Cell phone'
				}
			}),
			Object.assign({}, taskTemplates.paperDollTemplate.itemTemplate, {
				id: 'watch2',
				title: {
					da: 'Armbåndsur',
					en: 'Wrist watch'
				}
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-mc-hair-and-beard',
		background: 'dressing-room',
		layout: 'personal-hygiene-hair-and-beard',
		subtype: 'images',
		text: {
			da: `Hvordan skal hårnet og skægbind sidde?<br /><br />Tryk på de rigtige billeder.`,
			en: `How should hair nets and beard masks fit?<br /><br />Click on the correct pictures.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4				
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5		
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-mc-labcoat',
		background: 'dressing-room',
		layout: 'repetition-2-a-labcoat',
		subtype: 'images',
		text: {
			da: `Tryk på den rigtige kittel.`,
			en: `Clik on the correct smock.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // closed, covers clothes
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Nemlig. Kitlen skal være lukket, den skal dække dit almindelige tøj og så skal den være ren.`,
								en: `Exactly. The smock must be closed, it must cover your normal clothes and then it must be clean.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // open
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3 // closed but dirty
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4 // almost closed
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-mc-lockers',
		background: 'dressing-room2',
		layout: 'repetition-2-2a-lockers',
		subtype: 'images',
		text: {
			da: `I omklædningsrummet er der et skab til privat tøj og et sted til arbejdstøj. Det er vigtigt at lægge ting de rigtige steder. Hvilke ting må IKKE opbevares i skabet til private ting?`,
			en: `In the changing room there is a closet for personal clothes and a place for work clothes. It is important to put things in the right places. What items may NOT be stored in the closet for private belongings?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // beard net
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // safety glasses
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // apron
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4 // phone
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // normal shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // work shoes
				isCorrect: true,
			}),
			// Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
			// 	id: 7, // safety glasses (?)
			// }),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8, // peanuts
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-order-wash-hands',
		background: 'dressing-room',
		text: {
			da: `I hygiejnesluser skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `In hygiene sluice, you must always wash your hands. What is the right order to do things in?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Vask hænder`,
					en: `Wash hands`
				}, 
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Tør hænder`,
					en: `Drying hands`
				}, 
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Smid håndklædepapir i skraldepose`,
					en: `Throw paper towels in the garbage bag`
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Brug håndsprit`,
					en: `Use hand sanitizer`
				}, 
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-instructions-almost-ready',
		text: {
			da: `Vi er næsten klar til at gå ind i produktionen, men lad os lige se om vi er helt klar. Se hvad du kan finde af fejl på det næste billede.`,
			en: `We're almost ready to go into production, but let's see if we're all set. See which mistakes you can find in the next picture.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-spot-errors-person',
		layout: 'repetition-2-a-person',
		background: 'hygiene-lock',
		text: {
			da: `Tryk på de 4 fejl.<br /><br />Tryk OK når du tror, du har fundet dem alle.`,
			en: `Press the errors. <br /><br />Press OK when you think you've found them all.`
		},		
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'watch',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'beard',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'homeshirt',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'cola',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-instructions-ready',
		background: 'dressing-room',
		text: {
			da: `Så er vi klar til at gå ind i produktionen.`,
			en: `Then we are ready to go into the production.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-mc-check-before-work',
		text: {
			da: `Hvad skal du altid gøre lige inden du går i gang med at arbejde?`,
			en: `What should you always do right before you start working?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Tjekke at mit arbejdstøj og udstyr ikke er i stykker`,
					en: `Check that my work clothes and equipment are not broken`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Give mester en high five`,
					en: `Give the manager a high five`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Synge Danish Crown-sangen`,
					en: `Sing the Danish Crown song`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Vaske hænder`,
					en: `Wash hands`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Det har du lige gjort i hygiejneslusen.`,
								en: `Not wrong, but you did that in the hygiene sluice.`
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-spot-errors-person2',
		layout: 'repetition-2-1a-person2',
		background: 'production-room-b',
		text: {
			da: `Tryk på de 4 fejl.<br /><br />Tryk OK når du tror, du har fundet dem alle. `,
			en: `See if you can spot the 4 errors on the equipment here.<br /><br />Tap them and then tap ok when you think you have them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'apron',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'glove',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'pants',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'sleeve',
			}),

		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-2a-instructions-finish',
		text: {
			da: `Godt gået! 
				<br /><br />Du er nu igennem første del af gentræningen. Hvis du er i tvivl om noget, så spørg kursuslederen eller din mester. 
				<br /><br />Nu fortsætter vi til anden del af gentræningen.`,
			en: `Well done!
				<br /><br />You are now through the first part of the retraining. If you are in doubt about something, ask the course leader or your manager.
				<br /><br />Now we continue to the second part of the retraining`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'repetition-2-2a-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};