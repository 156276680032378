import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Login from './login';
import FacilitatorController from 'components/users/facilitator-controller';
import PlayerController from 'components/users/player-controller';
import ImageLoader from 'components/ui/image-loader/image-loader';
import './login.scss';

class LoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isLoggedIn: false,
			userId: null,
			isFacilitator: false,
			email: null,
		};
		this.unsubscribeOnAuthStateChanged = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		
		/* Check if logged in */
		this.checkIfLoggedIn();
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	};

	/**
	 * Check if user is logged in
	 */
	checkIfLoggedIn = () => {
		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) {
			this.unsubscribeOnAuthStateChanged();
		}

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user) {
				const isFacilitator = user.email !== null;
				if (isFacilitator) {
					/* Logged in as facilitator */
					this.setState({
						isLoggedIn: true,
						isLoading: false,
						userId: user.uid,
						isFacilitator: isFacilitator,
						email: user.email
					});
				} else {
					/* Logged in as player */
					this.setState({isLoggedIn: true, isLoading: false, userId: user.uid});
				}
			} else {
				this.setState({
					isLoading: false,
					isLoggedIn: false,
					userId: null,
					isFacilitator: false,
					email: null,
				});
			}
		});	
	};



	/**
	 * Logout
	 */
	handleLogout = () => {
		firebase.auth().signOut();
		this.setState({
			isLoggedIn: false,
			userId: null,
			isFacilitator: false,
			email: null,
		});
	};

	/**
	 * Switch between facilitator / player login box
	 * @param {string} activeLoginBox 
	 */
	setActiveLoginBox = (activeLoginBox) => {
		this.setState({activeLoginBox});
	};

	/**
 	* Render component
 	*/
	render() {	
		/* User is not loggged in */
		if (!this.state.isLoggedIn) {
			return (
				<Login 
					languageId={this.props.languageId}
					activeLoginBox={this.state.activeLoginBox}
					setActiveLoginBox={this.setActiveLoginBox}
				/>
			);
		}

		/* User is logged in, get controller component for facilitator / player */
		const UserComponent = (this.state.isFacilitator ? FacilitatorController : PlayerController);

		return (
			<>
				<ImageLoader type={this.state.isFacilitator ? 'facilitator' : 'basic-game'} />
				<UserComponent 
					languageId={this.props.languageId}
					userId={this.state.userId}
					scrollToTop={this.props.scrollToTop}
					handleLogout={this.handleLogout}
				/>
			</>
		);
	}
}

LoginController.propTypes = {
	languageId: PropTypes.string.isRequired,
	scrollToTop: PropTypes.func.isRequired,
};

export default LoginController;