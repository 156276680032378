const moduleRepetition21A = [
	'modules/tasks/multiple-choice/repetition-1-a-hands/background.svg',
	'modules/tasks/multiple-choice/repetition-2-1a-work-locker/option-1.svg',
	'modules/tasks/multiple-choice/repetition-2-1a-work-locker/option-2.svg',
	'modules/tasks/multiple-choice/repetition-2-1a-work-locker/option-3.svg',
	'modules/tasks/multiple-choice/repetition-2-1a-work-locker/option-4.svg',
	'modules/tasks/multiple-choice/repetition-2-1a-work-locker/option-5.svg',
	'modules/tasks/multiple-choice/repetition-2-1a-private-locker/option-1.svg',
	'modules/tasks/multiple-choice/repetition-2-1a-private-locker/option-2.svg',
	'modules/tasks/multiple-choice/repetition-2-1a-private-locker/option-3.svg',
	'modules/tasks/multiple-choice/repetition-2-1a-private-locker/option-4.svg',

	'modules/tasks/spot-errors/repetition-2-1a-person/background.svg',
	'modules/tasks/spot-errors/repetition-2-1a-person2/background.svg',
];

export {
	moduleRepetition21A
};