const modulesData = [
	{
		isPublished: true,
		id: 'personal-hygiene-2',
		background: 'production-room-a',
		title: {
			da: 'Hygiejnetræning A',
			en: 'Hygiene training A',
			pl: 'Hygiene training A'
		},
		description: {
			da: 'TBD Beskrivelse af modul',
			en: 'TBD Description of module',
			pl: 'TBD Description of module'
		},
		minStars: 3,
		requiredModuleIds: [],
		tasks: require('./modules/personal-hygiene-2').moduleTasks,
	},
	{
		isPublished: true,
		id: 'production-2',
		background: 'production-room-b',
		title: {
			da: 'Hygiejnetræning B',
			en: 'Hygiene training B',
			pl: 'Hygiene training B'
		},
		description: {
			da: 'TBD Beskrivelse af modul',
			en: 'TBD Description of module',
			pl: 'TBD Description of module'
		},
		minStars: 3,
		requiredModuleIds: ['personal-hygiene-2'],
		tasks: require('./modules/production-2').moduleTasks,
	},
	{
		isPublished: true,
		id: 'repetition-2-1a',
		background: 'production-room-b',
		title: {
			da: 'Gentræning 1A',
			en: 'Repetition 1A',
			pl: 'Repetition 1A'
		},
		description: {
			da: 'TBD Beskrivelse af modul',
			en: 'TBD Description of module',
			pl: 'TBD Description of module'
		},
		minStars: 3,
		requiredModuleIds: ['production-2'],
		requiredWaitingMonths: [3],
		tasks: require('./modules/repetition-2-1a').moduleTasks,
	},
	{
		isPublished: true,
		id: 'repetition-2-1b',
		background: 'production-room-b',
		title: {
			da: 'Gentræning 1B',
			en: 'Repetition 1B',
			pl: 'Repetition 1B'
		},
		description: {
			da: 'TBD Beskrivelse af modul',
			en: 'TBD Description of module',
			pl: 'TBD Description of module'
		},
		minStars: 3,
		requiredModuleIds: ['repetition-2-1a'],
		tasks: require('./modules/repetition-2-1b').moduleTasks,
	},
	{
		isPublished: true,
		id: 'repetition-2-2a',
		background: 'production-room-b',
		title: {
			da: 'Gentræning 2A',
			en: 'Repetition 2A',
			pl: 'Repetition 2A'
		},
		description: {
			da: 'TBD Beskrivelse af modul',
			en: 'TBD Description of module',
			pl: 'TBD Description of module'
		},
		minStars: 3,
		requiredModuleIds: ['repetition-2-1b'],
		requiredWaitingMonths: [3],
		tasks: require('./modules/repetition-2-2a').moduleTasks,
	},
	{
		isPublished: true,
		id: 'repetition-2-2b',
		background: 'production-room-b',
		title: {
			da: 'Gentræning 2B',
			en: 'Repetition 2B',
			pl: 'Repetition 2B'
		},
		description: {
			da: 'TBD Beskrivelse af modul',
			en: 'TBD Description of module',
			pl: 'TBD Description of module'
		},
		minStars: 3,
		requiredModuleIds: ['repetition-2-2a'],
		tasks: require('./modules/repetition-2-2b').moduleTasks,
	},
]

const moduleGroups = [
	{
		id: 'hygiene-and-processing',
		courseNumber: '50000000',
		title: {
			da: 'Hygiejne og processing',
			en: 'Hygiene and processing',
			pl: 'Hygiene and processing'
		},
		moduleIds: [
			'personal-hygiene-2',
			'production-2'
		]
	},
	{
		id: 'repetition-2-1',
		courseNumber: '50000510',
		title: {
			da: 'Gentræning 1',
			en: 'Repetition 1',
			pl: 'Repetition 1'
		},
		moduleIds: [
			'repetition-2-1a',
			'repetition-2-1b'
		]
	},
	{
		id: 'repetition-2-2',
		courseNumber: '50000510',
		title: {
			da: 'Gentræning 2',
			en: 'Repetition 2',
			pl: 'Repetition 2'
		},
		moduleIds: [
			'repetition-2-2a',
			'repetition-2-2b'
		]
	},
];

export {
	modulesData,
	moduleGroups
};