const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-instructions-welcome',
		text: {
			da: `Velkommen til gentræning 1A. Det er det første af to moduler, du skal igennem i dag.`,
			en: `Welcome to retraining 1A. This is the first of two modules you will go through today.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-instructions-points',
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In the game you get points for solving tasks. The fewer mistakes you make, the more points you get.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-instructions-stars',
		text: {
			da: `Når du får point, kan du også få stjerner. I toppen af skærmen kan du se, hvor mange stjerner og point du har.`,
			en: `When you get points, you can also get stars. See how many points and stars you have at the top of the screen.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-instructions-points-to-progress',
		text: {
			da: `Husk du skal have tre stjerner for at gennemføre modulet.`,
			en: `Remember you must have at least tree stars to complete the module.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-instructions-first-task',
		text: {
			da: `Vi starter med påklædning. På næste billede skal du trykke på de ting, som er forkerte.`,
			en: `We start with attire. On the next picture, press the things that are wrong.`
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-spot-errors-person',
		layout: 'repetition-2-1a-person',
		background: 'production-room-b',
		text: {
			da: `Tryk på de 4 fejl.<br /><br/>Tryk OK når du tror, du har fundet dem alle.`,
			en: `Press the 4 errors.<br /><br/>Press OK when you think you have found them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'watch',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hair',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'dirt',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'buttons',
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-instructions-hand-hygiene',
		background: 'dressing-room',
		text: {
			da: `En af de vigtigste regler er at have god håndhygiejne. Det handler både om at vaske hænder tit, men også om at gøre det ordentligt.`,
			en: `One of the most important rules is to have good hand hygiene. It is both about washing your hands often, but also about doing it properly.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-mc-hands',
		layout: 'repetition-1-a-hands',
		background: 'dressing-room',
		subtype: 'images',
		text: {
			da: `Der er især to områder, som er nemme at glemme under håndvask. Hvilke er det?`,
			en: `There are two areas in particular that are easy to forget during hand washing. Which ones are they?
 `
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // palm
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // back of hand
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // nails
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // inside of wrist
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5 // between fingers
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-when-washing-hands',
		background: 'dressing-room',
		text: {
			da: `Hvor tit skal du huske at vaske hænder?`,
			en: `How often should you remember to wash your hands?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Hver anden time`,
					en: `Every other hour`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Når de er blevet forurenet`,
					en: `When they have been contaminated`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Nemlig! For eksempel hvis du bruger stiger, rører ved dit ansigt eller samler noget op fra gulvet.`,
								en: `Exactly! For example if you use ladders, touch your face or pick something up from the floor.`
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Efter pauser`,
					en: `After breaks`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Når jeg passerer en hygiejnesluse`,
					en: `When I pass a hygiene sluice`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Hver time`,
					en: `Every hour`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, 
				text: {
					da: `Hvert kvarter`,
					en: `Every 15 minutes`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-instructions-lockers',
		background: 'dressing-room',
		text: {
			da: `Inden vi går ind i produktionen, så skal vi lige forbi skabene.`,
			en: `Before we go into the production, we have to pass by the closets.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-instructions-lockers2',
		background: 'dressing-room2',
		text: {
			da: `Du har et skab til privat tøj og et sted til arbejdstøj.`,
			en: `You have a closet for personal clothes and a place for work clothes.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-mc-work-locker',
		layout: 'repetition-2-1a-work-locker',
		background: 'dressing-room2',
		subtype: 'images',
		text: {
			da: `Hvilket af følgende hører IKKE til i skabet til privat tøj?`,
			en: `Which of the following does NOT belong in the closet for private clothing?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // everyday shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // thermo jacket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // sandals
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // white work shoes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true // hair net
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-mc-private-locker',
		layout: 'repetition-2-1a-private-locker',
		background: 'dressing-room2',
		subtype: 'images',
		text: {
			da: `Hvilket af følgende hører til i skabet til privat tøj?`,
			en: `Which of the following belongs in the closet for private clothes?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1 // safety glasses
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // white working clothes
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true // thermo jacket
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true // neck wrap and hat
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-instructions-storage',
		background: 'dressing-room2',
		text: {
			da: `Det er vigtigt at opbevare ting de rigtige steder. På den måde undgår du at værktøj, tøj og skabe bliver forurenet.`,
			en: `It is important to store things in the right places. This way, you avoid tools, clothes and closets becoming contaminated.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-before-work',
		background: 'production-room-a',
		text: {
			da: `Hvad skal du altid gøre lige inden du går i gang med at arbejde?`,
			en: `What should you always do right before you start working?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Tjekke at mit arbejdstøj og udstyr ikke er i stykker`,
					en: `Check that my work clothes and equipment are not broken`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Give mester en high five`,
					en: `Give the manager a high five`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Synge Danish Crown-sangen`,
					en: `Sing the Danish Crown song`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Vaske hænder`,
					en: `Wash your hands`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							type: 'manager',
							text: {
								da: `Det har du lige gjort i hygiejneslusen.`,
								en: `Not wrong, but you just did that in the hygiene sluice.`
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-spot-errors-person2',
		layout: 'repetition-2-1a-person2',
		background: 'production-room-b',
		text: {
			da: `Se om du kan spotte de 4 fejl på udstyret her.<br /><br />Tryk på dem og tryk så OK når du mener du har dem alle.`,
			en: `See if you can spot the 4 mistakes on the equipment here.<br /><br />press them and then press ok when you think you have them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'apron',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'glove',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'pants',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'sleeve',
			}),

		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-instructions-ready',
		background: 'dressing-room2',
		text: {
			da: `Nu er vi klar til at gå ind i produktionen. Det gør vi i næste modul.`,
			en: `Now we are ready to go into the production. We will do that in the next module.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'repetition-2-1a-instructions-finish',
		background: 'dressing-room2',
		text: {
			da: `Godt gået! 
				<br /><br />Du er nu igennem første del af gentræningen. 
				<br /><br />Hvis du er i tvivl om noget, så spørg kursuslederen eller din mester. Nu fortsætter vi til anden del af gentræningen.`,
			en: `Well done!
				<br /><br />You are now through the first part of retraining. If you are in doubt about anything, ask the course leader or your manager.
				<br /><br />Now you can continue to the second part of retraining`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'repetition-2-1a-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};