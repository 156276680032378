/* Instructions */
const instructionsTemplate = {
	mainTemplate: {
		type: 'instructions',
		isSolveToContinue: false,
		showProgressBar: true,
		background: null,
		title: null,
		text: null,
		textType: 'speech', // 'info', 'speech',
		images: ['manager-1']
	}
}

/* Image */
const imageTemplate = {
	mainTemplate: {
		type: 'image',
		isSolveToContinue: false,
		showProgressBar: true,
		image: null,
		text: null,
	}
}

/* Paper doll */
const paperDollTemplate = {
	mainTemplate: {
		type: 'paper-doll',
		isSolveToContinue: true,
		taskId: null, // e.g. 'deli-1-paper-doll-items'
		showToolbox: false,
		showProgressBar: true,
		showPoints: true,
		showToolbox: true,
		items: [], // should be ordered after intended z-index (small to large)
		doneEffects: []
	},
	itemTemplate: {
		id: null,
		type: 'default', // default (selected image is same as in panel), alternative (suffix -b), bodytype (suffix f/m-b)
		isCorrect: false,
		title: null,
		selectEffects: [],
		deselectEffects: [],
	}
};

/* Multiple choice */
const multipleChoiceTemplate = {
	mainTemplate: {
		type: 'multiple-choice',
		isSolveToContinue: true,
		isDilemma: false,
		subtype: null, // null, images
		showNumberOfAnswersToSelect: true, // set to false to hide the "choose x answers"
		numberOfAnswersToSelect: 1, // used if isDilemma
		maxPoints: 0, // used if isDilemma
		layout: null, // used if subtype is 'images'
		shuffleOptions: true,
		showProgressBar: true,
		image: null,
		text: null,
		options: [],
		doneEffects: []
	},
	optionTemplate: {
		text: null, 
		isCorrect: false,
		effects: []
	}
};

/* Sort */
const sortTemplate = {
	mainTemplate: {
		type: 'sort',
		subtype: null, // null, images
		isSolveToContinue: true,
		showProgressBar: true,
		layout: '',
		text: null,
		image: null,
		categories: [],
		items: []	
	},
	categoryTemplate: {
		title: null,
		text: null,
	},
	itemTemplate: {
		text: null,
		categoryIds: []
	}
};

/* Order */
const orderTemplate = {
	mainTemplate: {
		type: 'order',
		isSolveToContinue: true,
		showProgressBar: true,
		image: null,
		text: null,
		items: []
	},
	itemTemplate: {
		text: null,
		correctOrderNumbers: []
	}
};

/* Organize */
const organizeTemplate = {
	mainTemplate: {
		type: 'organize',
		isSolveToContinue: true,
		showProgressBar: true,
		showSolutionWhenDone: false,
		layout: '',
		title: null,
		text: null,
		items: [],
		rules: [],
		mainContainers: [],
		doneConditions: [],
		doneEffects: []
	},
	itemTemplate: {
		containerId: null, 
		image: null, 
		alt: null,
		correctContainerIds: []
	},
	mainContainerTemplate: {
		title: null,
		containers: []
	},
	containerTemplate: {
		type: '',
		finalItemId: null
	},
	doneConditionTemplate: {
		type: '', // empty-main-container
		mainContainerId: null
	},
	rulesTemplate: {
		type: '', // items-in-correct-containers
	}
};

/* Spot errors */
const spotErrorsTemplate = {
	mainTemplate: {
		type: 'spot-errors',
		background: 'white',
		layout: '',
		isSolveToContinue: true,
		showProgressBar: true,
		doneWhenAllErrorsSpotted: false,
		showDefaultResultPopup: true,
		text: null,
		errors: [],
		doneEffects: []
	},
	errorTemplate: {
		effects: []
	}
};

export {
	multipleChoiceTemplate,
	instructionsTemplate,
	spotErrorsTemplate,
	paperDollTemplate,
	organizeTemplate,
	imageTemplate,
	orderTemplate,
	sortTemplate,
};
