const taskTemplates = require('data/templates/task-templates');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-instructions-welcome',
		showProgressBar: false,
		background: 'sunrise-1',
		text: {
			da: `Velkommen til hygiejnetræning A. Jeg er din manager og hjælper dig gennem spillet.`,
			en: `Welcome to hygiene training A. I'm your manager and will help you through the game.`,
			pl: `Witamy w module A szkolenia z higieny. Jestem Twoim menadżerem i poprowadzę Cię przez grę.`,
			de: `Willkommen bei der Hygieneschulung A. Ich bin dein Manager und helfe dir durch das Spiel.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-instructions-points-errors',
		showProgressBar: false,
		background: 'sunrise-2',
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du.`,
			en: `In the game you get points for solving tasks. The fewer mistakes you make, the more points you get.`,
			pl: `W grze otrzymujesz punkty za rozwiązywanie zadań. Im mniej błędów popełnisz, tym więcej punktów otrzymasz.`,
			de: `Im Spiel bekommt man Punkte für das Lösen von Aufgaben. Je weniger Fehler du machst, desto mehr Punkte erhältst du.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'sunrise-3',
		taskId: 'personal-hygiene-instructions-points-stars',
		text: {
			da: `Når du får point, kan du også få stjerner. Se hvor mange point og stjerner du har på toppen af skærmen.`,
			en: `When you get points, you also get stars. See how many points and stars you have at the top of the screen.`,
			pl: `Kiedy zdobywasz punkty, otrzymujesz również gwiazdki. Na górze ekranu możesz zobaczyć, ile punktów i gwiazdek masz.`,
			de: `Wenn du Punkte erhältst, bekommst du gleichzeitig Sterne. Du kannst oben im Bildschirm sehen, wie viele Punkte und Sterne du gesammelt hast.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'sunrise-4',
		taskId: 'personal-hygiene-instructions-points-to-progress',
		text: {
			da: `Du skal have tre stjerner for at gå videre til det næste modul.`,
			en: `You need three stars to move on to the next module.`,
			pl: `Potrzebujesz trzech gwiazdek, aby przejść do następnego modułu.`,
			de: `Du benötigst drei Sterne, um zum nächsten Modul zu gelangen.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		background: 'sunrise-5',
		taskId: 'personal-hygiene-instructions-first-task',
		text: {
			da: `Første opgave handler om den rigtige påklædning.`,
			en: `The first task is about the proper attire.`,
			pl: `Pierwsze zadanie dotyczy odpowiedniego stroju.`,
			de: `Bei der ersten Aufgabe geht es um die richtige Kleidung.`
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'personal-hygiene-order-dress',
		background: 'slaughterhouse',
		text: {
			da: `I hvilken rækkefølge skal du klæde dig på til at arbejde i produktionen? Sæt handlingerne i den rigtige rækkefølge. Tryk ok, når det er rigtigt.`,
			en: `In what order should you get dressed for work in production? Put the actions in the right order. Press ok when it is correct.`,
			pl: `W jakiej kolejności należy się ubrać do pracy na produkcji? Ułóż czynności w odpowiedniej kolejności. Następnie naciśnij ok, aby potwierdzić.`,
			de: `In welcher Reihenfolge sollte man sich für die Arbeit in der Produktion anziehen? Bringe die Aktionen in die richtige Reihenfolge. Bestätige mit ok, wenn du fertig bist.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Hårnet og skægbind`,
					en: `Hair net and beard mask`,
					pl: `Siatka na włosy i maska na brodę`,
					de: `Haarnetz und Bartschutz`
				}, 
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Afsprit hænderne`,
					en: `Disinfect hands`,
					pl: `Dezynfekcja rąk`,
					de: `Hände desinfizieren`
				}, 
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, 
				text: {
					da: `Bukser og kittel`,
					en: `Pants and smock`,
					pl: `Spodnie i fartuch`,
					de: `Hosen und Kittel`
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Sko eller støvler`,
					en: `Shoes or boots`,
					pl: `Buty lub kalosze`,
					de: `Schuhe oder Stiefel`
				}, 
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'personal-hygiene-mc-hair-and-beard',
		background: 'slaughterhouse',
		layout: 'personal-hygiene-hair-and-beard',
		subtype: 'images',
		text: {
			da: `Hvordan skal hårnet og skægbind sidde?<br />Tryk på de rigtige billeder.`,
			en: `How should you correctly wear hair net and beard mask?<br />Click on the correct pictures.`,
			pl: `Jak prawidłowo nosić siatkę na włosy i maskę na brodę?<br />Kliknij na odpowiednie zdjęcia.`,
			de: `Wie trägt man das Haarnetz und den Bartschutz richtig?<br />Klicke auf die richtigen Bilder.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4				
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5		
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'personal-hygiene-mc-jewelry',
		background: 'slaughterhouse',
		subtype: 'images',
		layout: 'personal-hygiene-jewelry',
		text: {
			da: `Hvilke smykker må du IKKE have på i produktionslokaler?`,
			en: `What kind of jewelry are you NOT allowed to wear in production areas?`,
			pl: `Jakiej biżuterii NIE wolno nosić w obszarach produkcyjnych?`,
			de: `Welche Art von Schmuck darfst du NICHT in Produktionsbereichen tragen?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-instructions-hygiene-dressing-room',
		text: {
			da: `God hygiejne starter allerede i omklædningsrummet. Prøv at se, hvilke problemer du kan spotte på næste billede.`,
			en: `Good hygiene starts in the changing room. Try to see which problems you can spot in the next picture.`,
			pl: `Właściwa higiena zaczyna się w szatni. Spróbuj zobaczyć, jakie problemy możesz dostrzec na następnym obrazku.`,
			de: `Gute Hygiene beginnt im Umkleidebereich. Erkennst du die Fehler im nächsten Bild?`
		},
		background: 'dressing-room',
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-spot-errors-dressing-room',
		layout: 'personal-hygiene-dressing-room',
		text: {
			da: `Tryk på de ting som er forkerte på billedet.<br /><br/>Tryk OK når du tror, du har dem alle.`,
			en: `Click on the things that are wrong in the picture.<br /><br/>Press OK when you think you have them all.`,
			pl: `Kliknij na rzeczy/przedmioty, które są niewłaściwe na obrazku.<br /><br />Naciśnij OK, gdy uważasz, że zaznaczono wszystkie.`,
			de: `Klicke auf die Dinge, die im Bild falsch sind. Bestätige anschließend mit OK.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'gloves',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'knife',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'work-shoes',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hair-net',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'sandwich',
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'soda',
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-instructions-clothes-dressing-room',
		text: {
			da: `I omklædningsrummet er der et skab til privat tøj og et sted til arbejdstøj. Det er vigtigt at lægge ting de rigtige steder.`,
			en: `In the changing room there is a closet for personal clothes and a place for work clothes. It is important to put things in the right places.`,
			pl: `W szatni znajduje się szafka na ubrania osobiste oraz miejsce na ubrania robocze. Ważne jest, aby umieścić rzeczy we właściwych miejscach.`,
			de: `In den Umkleiden gibt es einen Schrank für persönliche Kleidung und einen Platz für Arbeitskleidung. Es ist wichtig, die Arbeitskleidung und persönlichen Dinge korrekt zu lagern.`
		},
		background: 'dressing-room',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'personal-hygiene-mc-lockers',
		layout: 'personal-hygiene-lockers',
		subtype: 'images',
		text: {
			da: `Hvilke ting skal i skabet til privat tøj?`,
			en: `Which items should be in the closet for private clothes?`,
			pl: `Jakie rzeczy powinny znaleźć się w szafce na ubrania prywatne?`,
			de: `Welche Gegenstände gehören in den Schrank für private Kleidung?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Gul refleksvest,
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Hvide arbejdssko
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Beskyttelsesbriller
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Termojakke
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Blå frysejakke
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Halsedisse
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.imageTemplate.mainTemplate, {
		taskId: 'personal-hygiene-mc-lockers',
		layout: 'personal-hygiene-lockers',
		background: 'dressing-room',
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-instructions-hygiene-lock',
		text: {
			da: `Godt, så er vi vist ved at være klar til at gå ind i produktionen. Der møder du altid en hygiejnesluse. Lad os lige kigge nærmere på den.`,
			en: `Good, now we're about ready to go into the production. There you will always meet a hygiene sluice. Let's take a closer look at it.`,
			pl: `Dobrze, teraz jesteśmy prawie gotowi, aby przejść do produkcji. Tam zawsze spotkasz śluzę higieniczną. Przyjrzyjmy się temu bliżej.`,
			de: `Gut, jetzt sind wir bereit, in die Produktion zu gehen. Dort wirst du immer auf eine Hygieneschleuse treffen. Schauen wir uns das mal genauer an.`
		},
		background: 'dressing-room',
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 'personal-hygiene-order-wash-hands',
		background: 'dressing-room',
		text: {
			da: `I hygiejnesluser skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `In hygiene sluice, you must always wash your hands. What is the correct order to do things?`,
			pl: `W śluzie higienicznej zawsze musisz myć ręce. Jaka jest prawidłowa kolejność wykonywania czynności?`,
			de: `In der Hygieneschleuse musst du immer die Hände waschen. Was ist hierbei die richtige Reihenfolge?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, 
				text: {
					da: `Vask hænder`,
					en: `Wash hands`,
					pl: `Umyj ręce`,
					de: `Hände waschen`
				}, 
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, 
				text: {
					da: `Tør hænder`,
					en: `Dry hands in paper towel`,
					pl: `Wytrzyj ręce ręcznikiem papierowym`,
					de: `Hände mit Papierhandtuch trocknen`
				}, 
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Smid håndklædepapir i skraldepose`,
					en: `Throw the paper towel in the garbage bag`,
					pl: `Ręcznik papierowy wrzuć do worka na śmieci`,
					de: `Papiertuch im Müll entsorgen`
				}, 
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, 
				text: {
					da: `Brug håndsprit`,
					en: `Use hand sanitizer`,
					pl: `Użyj sanityzera rąk`,
					de: `Handdesinfektionsmittel benutzen`
				}, 
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'personal-hygiene-mc-nails',
		background: 'dressing-room',
		layout: 'personal-hygiene-nails',
		subtype: 'images',
		text: {
			da: `Håndhygiejne handler også om neglene.<br />Tryk på billedet, som er korrekt.`,
			en: `Hand hygiene also includes the nails.<br />Click on the correct picture.`,
			pl: `Higiena rąk obejmuje również paznokcie.<br />Kliknij na właściwy obrazek.`,
			de: `Zur Handhygiene gehören auch die Nägel.<br />Klicke auf das richtige Bild.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4				
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 'personal-hygiene-mc-when-wash-hands',
		shuffleOptions: false,
		background: 'dressing-room',
		text: {
			da: `Efter hvilke situationer skal du huske at vaske hænder?`,
			en: `After which situations should you remember to wash your hands?`,
			pl: `Po jakich sytuacjach należy pamiętać o umyciu rąk?`,
			de: `Nach welchen Situationen solltest du daran denken, deine Hände zu waschen?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Når du passerer en hygiejnesluse`,
					en: `When you pass a hygiene sluice`,
					pl: `Kiedy mijasz śluzę higieniczną`,
					de: `Wenn du eine Hygieneschleuse passierst`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Efter pauser`,
					en: `After breaks`,
					pl: `Po przerwach`,
					de: `Nach Pausen`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Efter at binde snørebånd`,
					en: `After tying shoelaces`,
					pl: `Po zawiązaniu sznurowadeł`,
					de: `Nach dem Schuhe Zuschnüren`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Efter du har rørt ved din mund og næse`,
					en: `After touching your mouth and nose`,
					pl: `Po dotknięciu ust i nosa`,
					de: `Nach dem Berühren von Mund und Nase`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Efter brug af stiger`,
					en: `After using ladders`,
					pl: `Po użyciu drabiny`,
					de: `Nach der Benutzung von Leitern`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: {
					da: `I alle ovenstående situationer`,
					en: `In all the above situations`,
					pl: `We wszystkich powyższych sytuacjach`,
					de: `In allen oben genannten Situationen`
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 'personal-hygiene-instructions-finish',
		background: 'dressing-room',
		text: {
			da: `Sådan, du er nu færdig med første modul. Hvis du har fået tre stjerner, kan du fortsætte til næste modul. Hvis ikke, så må du tage modulet en gang til. `,
			en: `That's it, you have now finished the first module. If you have received at least three stars, you can continue to the next module. If not, then you must take the module again.`,
			pl: `To wszystko, właśnie ukończyłeś/aś pierwszy moduł. Jeśli otrzymałeś/aś co najmniej trzy gwiazdki, możesz przejść do następnego modułu. Jeśli nie, musisz ponownie przejść moduł.`,
			de: `Das wars, du hast jetzt das erste Modul abgeschlossen. Wenn du mindestens drei Sterne erhalten hast, kannst du mit dem nächsten Modul fortfahren. Wenn nicht, musst du das Modul wiederholen.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 'personal-hygiene-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};