// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const cookiesUiTexts = {
	title: {
		da: 'Vi bruger cookies',
		en: 'We use cookies',
		pl: 'Używamy plików cookie',
		de: 'Wir verwenden Cookies'
	},
	text: {
		da: `Vi bruger cookies til at forbedre din spiloplevelse. De håndterer din adgang spillet, og spillet fungerer derfor ikke uden.
			<br /><br />Tryk "OK" for at give os samtykke til at bruge cookies.
			<br /><br />Dit samtykke er gyldigt i et år. Du kan til hver en tid trække dit samtykke tilbage ved at klikke på ikonet nede i venstre hjørne.`,
		en: `We use cookies to improve your game experience. They handle your access to the game, and the game will not work without them. 
			<br /><br />Press "OK" to give us your consent to using cookies. 
			<br /><br />Your consent is valid for a year. You can at any time withdraw your consent by clicking on the icon in the bottom left corner. `,
		pl: `Używamy plików cookie, aby poprawić wrażenia z gry. Obsługują Twój dostęp do gry, dlatego bez nich gra nie działa.
			<br /><br />Naciśnij „OK”, aby wyrazić zgodę na używanie plików cookie.                                         
			<br /><br />Twoja zgoda jest ważna przez rok. W każdej chwili możesz wycofać swoją zgodę, klikając ikonę w lewym dolnym rogu.`,
		de: `Wir verwenden Cookies, um Ihr Spielerlebnis zu verbessern. Sie verwalten Ihren Zugriff auf das Spiel und das Spiel wird ohne sie nicht funktionieren.
			<br /><br />Klicken Sie auf „OK“, um uns Ihr Einverständnis zur Verwendung von Cookies zu erteilen.
			<br /><br />Ihre Einwilligung ist ein Jahr gültig. Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie auf das Symbol unten links klicken.`
	},
	okBtn: {
		da: 'OK',
		en: 'OK',
		pl: 'OK',
		de: 'OK'
	},
	rejectBtn: {
		da: 'Afvis',
		en: 'Reject',
		pl: 'Odrzuć',
		de: 'Ablehnen'
	}
};

module.exports = {
	cookiesUiTexts
};