import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import { getText } from 'helpers/language-helper';
import Character from 'components/ui/character/character';
import Audio from 'components/ui/audio/audio';
import './instructions.scss';
import { useEffect } from 'react';
import resetAnimation from 'helpers/animation-helper';

const Instructions = ({languageId, moduleData, taskData, shouldFadeSpeech = false}) => {
	useEffect(() => {
		resetAnimation('speech');
	}, [taskData]);

	return (
		<div className={'Instructions ' + (taskData.background ? taskData.background : moduleData.background)}>
			{(taskData.title || taskData.text) && 
				<div 
					id={taskData.textType} 
					className={'Instructions-content ' + taskData.textType + (shouldFadeSpeech ? ' fade' : '')}>
					{taskData.title && <div className="Instructions-title">
						{getText(taskData.title, languageId)}
					</div>}
					{taskData.text && <div className="Instructions-text">
						{renderMarkdown(getText(taskData.text, languageId))}
					</div>}
					<div className="Instructions-audio">
						<Audio 
							type='task-intro'
							languageId={languageId}
							moduleId={moduleData.id}
							audioData={{taskType: taskData.type, taskId: taskData.taskId}}
						/>
					</div>
				</div>
			}
			
			{taskData.images.map((imageId) => {
				return (<div key={imageId} className={'Instructions-character ' + imageId}>
					<Character 
						page="instructions" 
						characterId={imageId}
					/>
				</div>);
			})}
		</div>
	);
};

Instructions.propTypes = {
	languageId: PropTypes.string.isRequired,
	moduleData: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired,
	shouldFadeSpeech: PropTypes.bool,
};

export default Instructions;
