/* Always loaded */
const basicImagesData = [
	'backgrounds/dc.svg',
	'icons/icon-cookies.svg',
	'icons/icon-loading.svg',
	'icons/icon-loading-white.svg',
	'icons/icon-language.svg',
	'icons/icon-logout.svg',
	'languages/da.svg',
	'languages/en.svg',
	'languages/pl.svg',
	'languages/de.svg',
	'logo-cgl.svg',
	'logo-danish-crown.svg',
];

/* Loaded if logged in as player */
const basicGameImagesData = [
	'backgrounds/dc.svg',
	'backgrounds/dressing-room-bench.svg',
	'backgrounds/dressing-room-floor-grid.svg',
	'backgrounds/dressing-room-items.svg',
	'backgrounds/dressing-room-items2-hangings.svg',
	'backgrounds/dressing-room-items2.svg',
	'backgrounds/frame-shade-square-sides.svg',
	'backgrounds/frame-shade-square-top-bottom.svg',
	'backgrounds/frame-shade.svg',
	'backgrounds/hygiene-lock.svg',
	'backgrounds/production-floor-pattern.svg',
	'backgrounds/production-room-a.svg',
	'backgrounds/production-room-b.svg',
	'backgrounds/slaughterhouse-floor-grid.svg',
	'backgrounds/slaughterhouse-floor-vents.svg',
	'backgrounds/slaughterhouse-meat.svg',
	'backgrounds/slaughterhouse-tables.svg',
	'backgrounds/slaughterhouse-wall-grid.svg',
	'backgrounds/slaughterhouse-wall-hangings.svg',
	'characters/manager-1.svg',
	'characters/manager-1-blinking.svg',
	'icons/icon-audio-pause-green.svg',
	'icons/icon-audio-pause-red.svg',
	'icons/icon-audio-pause.svg',
	'icons/icon-audio-play-green.svg',
	'icons/icon-audio-play-red.svg',
	'icons/icon-audio-play.svg',
	'icons/icon-checkmark.svg',
	'icons/icon-corrrect.svg',
	'icons/icon-drag.svg',
	'icons/icon-home.svg',
	'icons/icon-next.svg',
	'icons/icon-star-filled.svg',
	'icons/icon-star.svg',
	'icons/icon-wrong.svg',
	'speech-bubble-feedback.svg',
	'speech-bubble-instructions.svg',
	'text/private-da.svg',
	'text/private-de.svg',
	'text/private-en.svg',
	'text/private-pl.svg',
	'text/work-da.svg',
	'text/work-de.svg',
	'text/work-en.svg',
	'text/work-pl.svg',
];

/* Loaded if logged in as facilitator/admin */
const facilitatorImagesData = [
	'icons/icon-arrow-down.svg',
	'icons/icon-cross-white.svg',
	'icons/icon-sort-asc.svg',
	'icons/icon-sort-desc.svg',
	'logo-danish-crown-white.svg'
];

const imagesData = {
	'basic': basicImagesData,
	'basic-game': basicGameImagesData,
	'facilitator': facilitatorImagesData,
	'module-personal-hygiene': require('data/images/module-personal-hygiene').modulePersonalHygiene,
	'module-production': require('data/images/module-production').moduleProduction,
	'module-repetition-1-a': require('data/images/module-repetition-1-a').moduleRepetition1A,
	'module-repetition-1-b': require('data/images/module-repetition-1-b').moduleRepetition1B,
	'module-repetition-2-a': require('data/images/module-repetition-2-a').moduleRepetition2A,
	'module-repetition-2-b': require('data/images/module-repetition-2-b').moduleRepetition2B,
	'module-personal-hygiene-2': require('data/images/module-personal-hygiene-2').modulePersonalHygiene2,
	'module-production-2': require('data/images/module-production-2').moduleProduction2,
	'module-repetition-2-1a': require('data/images/module-repetition-2-1a').moduleRepetition21A,
	'module-repetition-2-1b': require('data/images/module-repetition-2-1b').moduleRepetition21B,
	'module-repetition-2-2a': require('data/images/module-repetition-2-2a').moduleRepetition22A,
	'module-repetition-2-2b': require('data/images/module-repetition-2-2b').moduleRepetition22B,
};

export {
	imagesData
};
