const moduleProduction = [
	'modules/tasks/multiple-choice/production-allergies/option-1.svg',
	'modules/tasks/multiple-choice/production-allergies/option-2.svg',
	'modules/tasks/multiple-choice/production-allergies/option-3.svg',
	'modules/tasks/multiple-choice/production-allergies/option-4.svg',
	'modules/tasks/multiple-choice/production-clothes/option-1.svg',
	'modules/tasks/multiple-choice/production-clothes/option-2.svg',
	'modules/tasks/multiple-choice/production-clothes/option-3.svg',
	'modules/tasks/multiple-choice/production-clothes/option-4.svg',
	'modules/tasks/multiple-choice/production-clothes/option-5.svg',
	'modules/tasks/multiple-choice/production-clothes/option-6.svg',

	'modules/tasks/spot-errors/production-slaughter-hall/background.svg',
	'modules/tasks/spot-errors/production-slaughter-hall-2/background.svg',
];

export {
	moduleProduction
};