import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import { getText } from 'helpers/language-helper';
import Audio from 'components/ui/audio/audio';
import './feedback.scss';


const Feedback = ({languageId, feedbackData, onClick}) => {
	const feedbackText = (feedbackData.text 
		? getText(feedbackData.text, languageId)
		: ''
	);

	return (
		<div 
			className={'Feedback' + (feedbackData.type ? ' ' + feedbackData.type : '')}
			onClick={onClick}
		>
			<div className="Feedback-content">
				<div className="Feedback-text">
					{renderMarkdown(feedbackText ? feedbackText : '')}
				</div>
				{feedbackData.audioId && <div className="Feedback-audio">
					<Audio 
						type='feedback'
						languageId={languageId}
						moduleId={feedbackData.moduleId}
						audioData={{audioId: feedbackData.audioId}}
					/>
				</div>}
			</div>
		</div>
	);
};

Feedback.propTypes = {
	languageId: PropTypes.string.isRequired,
	feedbackData: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default Feedback;
